import React from "react";
import Header from "../../layouts/Header";
import { Card, Row, Col, Table} from "react-bootstrap";
import { Bar } from "react-chartjs-2";

import { Doughnut } from "react-chartjs-2";

export default function TLDashboard() {

    const production_summary_bar = {
      labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      datasets: [{
        data: [1850, 2540, 2210, 3100, 2405, 2804, 2590],
        backgroundColor: "#506fd9", // Will be filled with gradients
        barPercentage: 0.5
      }]
    };
  
    const production_summary_optionBar = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            max: 3500,
            ticks: {
              color: '#a1aab3',
              font: {
                size: 10
              }
            }
          }
        }
      };
    
   

    // PRODUCTION SUMMARY BAR CHART DATA ENDED

    // TOTAT CAPACITY CHART DATA BEGIN
    const total_capacity_bar = {
        labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        datasets: [{
            data: [22, 21, 25, 20, 25, 27, 19],
            backgroundColor: "#506fd9",
            barPercentage: 0.5
        }]
    };

    const total_capacity_optionBar = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            max: 35,
            ticks: {
              color: '#a1aab3',
              font: {
                size: 10
              }
            }
          }
        }
    };


    // TOTAT CAPACITY CHART DATA ENDED


    // TOTAL INFLOW BAR CHART DATA BEGIN
    const total_inflow_bar = {
        labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        datasets: [{
            data: [1850, 2540, 2210, 3100, 2405, 2804, 2590],
            backgroundColor: "#506fd9",
            barPercentage: 0.5
        }]
    };

    const total_inflow_optionBar = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            max: 3500,
            ticks: {
              color: '#a1aab3',
              font: {
                size: 10
              }
            },
          }
        }
    };

    // TOTAL INFLOW BAR CHART DATA ENDED

    // DAILY PRODUCTION BAR CHART DATA BEGIN 
    const daily_production_bar = {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
        datasets: [
            {
                label: 'Nikhil G(CGS)',
                data: [485, 444, 496, 478, 412, 550, 478, 420, 466, 490, 478, 400, 410, 469, 300, 298, 540, 578, 357, 254, 566, 255, 211, 159, 568, 265, 555, 578, 598, 547, 492],
                backgroundColor: "#506fd9",

            },
            {
                label: 'Lily(Natera)',
                data: [487, 685, 598, 678, 789, 810, 875, 789, 735, 764, 710, 700, 798, 820, 899, 910, 970, 960, 978, 910, 974, 789, 655, 795, 749, 862, 875, 879, 895, 840, 725],
                backgroundColor: '#354784',

            },
            {
                label: 'Hemant B(CGS)',
                data: [978, 1002, 954, 1200, 1204, 1154, 1169, 1208, 1250, 1278, 1298, 1257, 1058, 1114, 1205, 895, 990, 1000, 1125, 1257, 1298, 1245, 960, 798, 895, 1244, 1299, 1024, 1235, 1255, 1246],
                backgroundColor: '#1e294f',
            },

        ],
    };

    const daily_production_optionBar = {
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                beginAtZero: true,
            },
        },

        maintainAspectRatio: false,
        responsive: true,
        width: '100%',
        height: '400px',
    };

    // DAILY PRODUCTION BAR CHART DATA ENDED 

    // NON WORKABLE DASHBOARD PIE CHART DATA BEGIN
    var dataPie = {
        labels: ['Denial', 'Denial Touched', 'Denial Rebilled', 'AR Follow-Up', 'Fresh AR'],
        datasets: [{
            data: [25, 15, 10, 12, 9],
            backgroundColor: ['#506fd9', '#85b6ff', '#33d685', '#0dcaf0', '#1c96e9']
        }]
    };

    var optionPie = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            }
        },
        animation: {
            animateScale: true,
            animateRotate: true
        }
    };
    // NON WORKABLE DASHBOARD PIE CHART DATA ENDED

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app tl-dashboard-main-app p-3 p-lg-4 overflow-y">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Overall Summary</h4>
                    </div>
                </div>

                <Row className="g-3">
                    <Col xl="5">
                        <Row className="g-3">
                            {[
                                {
                                    "icon": "ri-task-fill",
                                    "percent": {
                                        "color": "success",
                                        //"amount": "+28.5%"
                                    },
                                    "value": "2,560",
                                    "label": "Total Number of Cases",
                                    "des": "Total cases with Ins balance > $ 0",
                                    "last": {
                                        "color": "danger",
                                        "amount": "2.3%"
                                    }
                                }, {
                                    "icon": "ri-money-dollar-box-fill",
                                    "percent": {
                                        "color": "danger",
                                        //"amount": "-3.8%"
                                    },
                                    "value": "$ 1.2 M",
                                    "label": "Total Insurance Balance",
                                    "des": "Sum of Total Insurance Balance",
                                    "last": {
                                        "color": "danger",
                                        "amount": "0.5%"
                                    }
                                }, {
                                    "icon": "ri-task-fill",
                                    "percent": {
                                        "color": "danger",
                                        //"amount": "-8.4%"
                                    },
                                    "value": "3,411",
                                    "label": "Total Open Cases",
                                    "des": "Total number of cases currently open for action.",
                                    "last": {
                                        "color": "danger",
                                        "amount": "0.2%"
                                    }
                                }, {
                                    "icon": "ri-task-fill",
                                    "percent": {
                                        "color": "success",
                                        //"amount": "+20.9%"
                                    },
                                    "value": "8,060",
                                    "label": "Total Worked Cases",
                                    "des": "Total number of worked cases in last 7 days.",
                                    "last": {
                                        "color": "success",
                                        "amount": "5.8%"
                                    }
                                }
                            ].map((item, index) => (
                                <Col xs="6" md="3" xl="6" key={index}>
                                    <Card className="card-one card-product">
                                        <Card.Body className="p-3">
                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                <div className="card-icon"><i className={item.icon}></i></div>
                                                <h6 className={"fw-normal ff-numerals mb-0 text-" + item.percent.color}>{item.percent.amount}</h6>
                                            </div>
                                            <h2 className="card-value ls--1">{item.value}</h2>
                                            <label className="card-label fw-medium text-dark">{item.label}</label>
                                            <label className="card-label fw-medium text-dark fs-12 text-black-50">{item.des}</label>
                                            <span className="d-flex gap-1 fs-xs">
                                                <span className={"d-flex align-items-center text-" + item.last.color}>
                                                    <span className="ff-numerals">{item.last.amount}</span><i className={(item.last.color === 'success') ? "ri-arrow-up-line" : "ri-arrow-down-line"}></i>
                                                </span>
                                                <span className="text-secondary">than last week</span>
                                            </span>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>

                    {/* PRODUCTION SUMMARY CHART */}
                    <Col xl="7">
                        <Card className="card-one card-product-inventory">
                            <Card.Header>
                                <Card.Title as="h6">Production Summary (Last Week)</Card.Title>
                            </Card.Header>
                            <Card.Body className="p-3">
                                <Bar className="ht-300" data={production_summary_bar} options={production_summary_optionBar} />
                            </Card.Body>
                        </Card>
                    </Col>
                     
                     {/* TL WISE PRODUCTION SUMMARY CHART */}
                    <Col xl="12">
                        <Card className="card-one">
                            <Card.Header>
                                <Card.Title as="h6">TL Wise Production Summary (Last 30 Days)</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Bar className="ht-450" data={daily_production_bar} options={daily_production_optionBar} />
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* TOAL CAPACITY CHART */}
                    <Col xl="4">
                        <Card className="card-one card-product-inventory">
                            <Card.Header>
                                <Card.Title as="h6">Total Capacity (Last Week)</Card.Title>
                            </Card.Header>
                            <Card.Body className="p-3">
                                <Bar className="ht-350" data={total_capacity_bar} options={total_capacity_optionBar} />
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* TOTAL INFLOW CHART */}
                    <Col xl="4">
                        <Card className="card-one card-product-inventory">
                            <Card.Header>
                                <Card.Title as="h6">Total Inflow (Last Week)</Card.Title>
                            </Card.Header>
                            <Card.Body className="p-3">
                                <Bar className="ht-350" data={total_inflow_bar} options={total_inflow_optionBar} />
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* NON WORKABLE CASES CHART */}
                    <Col xl="4">
                        <Card className="card-one">
                            <Card.Header>
                                <Card.Title as="h6">Non Workable Cases</Card.Title>

                            </Card.Header>
                            <Card.Body>
                                <div className="chart-donut-one mb-3">
                                    <Doughnut data={dataPie} options={optionPie} />
                                </div>

                                <Table className="table-five">
                                    <tbody>
                                        {[
                                            {
                                                "type": "Denial",
                                                "claims": "2154"
                                            }, {
                                                "type": "Denial Touched",
                                                "claims": "1547"
                                            }, {
                                                "type": "Denial Rebilled",
                                                "claims": "1987"
                                            }, {
                                                "type": "AR Follow-Up",
                                                "claims": "2557"
                                            }, {
                                                "type": "Fresh AR",
                                                "claims": "3024"
                                            }
                                        ].map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div>
                                                        {/* <span className={"badge-dot bg-" + item.dot}></span>  */}
                                                        <span className="fw-medium">{item.type}</span>
                                                    </div>
                                                </td>
                                                <td>{item.claims}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}