import React, { useEffect, useState } from "react";
import { Button, Form, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import CreateNewUser from "./create-new-user";
import UpdateUser from "./update-user";
import ShimmerLoader from "../../_common/ShimmerLoader";
import Commonfield from "../../../infrastructure/core/Commonfield";
import LoadingSpinner from "../../_common/LoadingSpinner";
import ARWorkflowProductivityBridge from "../../../assets/excel/ARWorkflowProductivityBridge.xlsx";
import ToastifyService from "../../_common/ToastifyService";

export default function UserAccounts() {
  const axiosPrivate = useAxiosPrivate();
  const [showNewUser, setShowNewUser] = useState(false);
  const [updateUser, setUpdateUser] = useState({
    showBool: false,
    userId: "",
  });
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const [userAccountList, setUserAccountList] = useState([]);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    getUserAccountList();
  }, []);
  const getUserAccountList = async () => {
    try {
      setLoading({ isLoading: true, value: "getUserAccountList" });
      const userAccountListData = await axiosPrivate.get(`Users/users`);
      setUserAccountList(userAccountListData?.data?.result);
    } catch (err) {
      setLoading({ isLoading: false, value: "getUserAccountList" });
    } finally {
      setLoading({ isLoading: false, value: "getUserAccountList" });
    }
  };
  const columns = [
    {
      name: "Full Name",
      selector: (row) => row.fullName,
      sortable: true,
      cell: (row) => <div>{row.fullName}</div>,
    },
    {
      name: "Status",
      selector: (row) => row.statusDesc,
      sortable: true,
      cell: (row) =>  <div>
      <span
        class={`badge badge-pill fs-12 ${
          row.status === Commonfield.status.active
            ? "bg-success"
            : "bg-danger"
        }`}
      >
        {row.statusDesc}
      </span>
    </div>
    },
    {
      name: "Role",
      selector: (row) => row.roleDesc,
      sortable: true,
      cell: (row) => <div>{row.roleDesc}</div>,
    },
    {
      name: "InActive Date",
      selector: (row) => row.inActiveDate,
      sortable: true,
      cell: (row) => (
        <div>
          {row.inActiveDate === "0001-01-01T00:00:00" ? "-" : row.inActiveDate}
        </div>
      ),
    },
    {
      name: "Created By",

      selector: (row) => row.createdByName,
      sortable: true,
      cell: (row) => (
        <div>{row.createdByName !== null ? row.createdByName : "-"}</div>
      ),
    },
    {
      name: "Action",
      sortable: true,
      cell: (row) => (
        <div className="w-1">
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="button"
              className="btn-icon"
              onClick={() => setUpdateUser({ showBool: true, userId: row?.id })}
            >
              <i className="ri-pencil-line"></i>
            </Button>
          </div>
        </div>
      ),
    },
  ];
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  const filteredData = userAccountList?.filter((item) => {
    return (
      item?.fullName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.statusDesc?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.roleDesc?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.inActiveDate?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.createdByName?.toLowerCase().includes(searchText.toLowerCase())
    );
  });
  const uploadProductivityBridge = async (e) => {
    const fileInput = e.target; // Reference the input element
    const file = fileInput.files[0];

    setLoading({
      isLoading: true,
      value: "uploadProductivityBridge",
    });

    if (
      file &&
      (file.type === "text/csv" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel")
    ) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const uploadProductivityBridgeRes = await axiosPrivate.post(
          `Users/bulk-create-users`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const {
          allUsers,
          duplicateUsersCount,
          recordsInsertedCount,
          totalRecordsProcessed,
        } = uploadProductivityBridgeRes.data.result;

        setUserAccountList(allUsers);
        const message = `Total Records Processed: ${totalRecordsProcessed}, Records Inserted: ${recordsInsertedCount}, Records Already Existing: ${
          duplicateUsersCount || 0
        }`;
        ToastifyService.success(message);
      } catch (error) {
        console.error("Error during upload:", error);
        ToastifyService.error("Oops! Something Went Wrong");
      } finally {
        setLoading({
          isLoading: false,
          value: "uploadProductivityBridge",
        });
        fileInput.value = ""; // Reset the file input field
      }
    }
  };

  return (
    <React.Fragment>
      <CreateNewUser
        show={showNewUser}
        userAccountList={userAccountList?.filter(
          (ual) => ual.role === Commonfield.userRoles.teamLead
        )}
        setUserAccountList={setUserAccountList}
        closeFunction={setShowNewUser}
      />
      <UpdateUser
        show={updateUser?.showBool}
        setUpdateUser={setUpdateUser}
        userAccountList={userAccountList?.filter(
          (ual) => ual.role === Commonfield.userRoles.teamLead
        )}
        setUserAccountList={setUserAccountList}
        userId={updateUser?.userId}
      />
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-0">User Accounts</h4>
        </div>

        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-1"
            onClick={() => setShowNewUser(true)}
          >
            <i className="ri-add-line fs-18 lh-1"></i>
            <span className="d-sm-inline">Add User Account</span>
          </Button>
        </div>
      </div>

      <Card className="card-one">
        <Card.Header className="justify-content-between align-items-center">
          <div>
            <Form.Control
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
              className="wt-250"
            />
          </div>

          <div className="d-flex gap-2">
            <div className="dropdown d-grid ">
              <label
                htmlFor="upload-input"
                className="btn btn-primary d-flex align-items-center gap-1 justify-content-center"
                disabled={
                  loading.isLoading &&
                  loading?.value === "uploadProductivityBridge"
                }
              >
                {loading.isLoading &&
                loading?.value === "uploadProductivityBridge" ? (
                  <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
                ) : (
                  <>
                    <i className="ri-upload-line fs-16 lh-1"></i>
                    <span className="align-middle ms-2">
                      Upload Productivity Bridge
                    </span>
                    <input
                      id="upload-input"
                      type="file"
                      accept=".xlsx, .xls, .csv"
                      style={{ display: "none" }}
                      onChange={(e) => uploadProductivityBridge(e)}
                    />
                  </>
                )}
              </label>
            </div>
            <a
              href={ARWorkflowProductivityBridge}
              download="Template_Productivity_Bridge.xlsx"
            >
              <Button variant="outline-primary">
                {" "}
                <i className="ri-download-line fs-16 lh-1"></i> Download
                Template
              </Button>
            </a>
          </div>
        </Card.Header>
        <Card.Body className="common-data-table">
          {loading.isLoading && loading.value === "getUserAccountList" ? (
            <ShimmerLoader
              colNames={columns?.map((column) => column.name)}
              colsCount={1}
            />
          ) : (
            <DataTable columns={columns} data={filteredData} pagination />
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
