import React from "react";
import { Handle } from "reactflow";

const DropdownNode = ({ data, isConnectable, selected }) => {
  const borderColor = selected ? "black" : "rgb(221, 221, 221)";

  return (
    <div
      style={{
        width: "200px",
        padding: "10px",
        border: `1px solid ${borderColor}`,
        borderRadius: 5,
        background: "#fff",
      }}
    >
      <div style={{ marginBottom: "10px" }}>
        <label>{data.label}</label>
        <Handle
          type="target"
          position="left"
          style={{
            background: "#555",
            top: "50%",
            width: "10px",
            height: "10px",
          }}
          isConnectable={isConnectable}
        />
      </div>
      {data.componentValues.map((option, index) => (
        <div key={index} style={{ marginBottom: "5px", position: "relative" }}>
          <label>{option.name}</label>
          <Handle
            type="source"
            position="right"
            id={`output-handle-${option.name}-${data.nodeId}`}
            style={{
              background: "#555",
              top: "50%",
              marginLeft: "10px",
              width: "10px",
              height: "10px",
            }}
            isConnectable={isConnectable}
          />
        </div>
      ))}
    </div>
  );
};

export default DropdownNode;
