import React, { useState } from "react";
import { Button, Badge, Form, Card } from "react-bootstrap";
import UpdateRule from "../Update/Edit-rule";
import DataTable from "react-data-table-component";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Commonfield from "../../../infrastructure/core/Commonfield";
import ToastifyService from "../../_common/ToastifyService";
import ShimmerLoader from "../../_common/ShimmerLoader";

export default function PublishedComponent(props) {
  const axiosPrivate = useAxiosPrivate();
  const [showUpdateRule, SetShowUpdateRule] = useState({
    showBool: false,
    taskRuleId: "",
  });
  const [searchText, setSearchText] = useState("");

  const handleSearch = (event) => {
    setSearchText(event?.target?.value);
  };
  const deleteTaskRule = async (taskId) => {
    try {
      const taskRuleData = await axiosPrivate.post(
        `TaskEngine/delete-task-bucket/${taskId}`
      );
      props?.setTaskEnginePublishList(
        taskRuleData?.data?.result?.filter(
          (pubrule) => pubrule.status === Commonfield.taskRuleStatus.published
        )
      );
      props?.setTaskEngineDraftList(
        taskRuleData?.data?.result?.filter(
          (pubrule) => pubrule.status === Commonfield.taskRuleStatus.draft
        )
      );
      ToastifyService.success("Rule Deleted Successfully");
    } catch (err) {
      console.error(err);
      ToastifyService.error("Oops! Something Went Wrong");
    }
  };
  const filteredData = props?.taskEnginPublishList?.filter((item) => {
    return (
      item?.taskBucketName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.groupName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.taskBucketInstructions
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      item?.modifiedByName?.toLowerCase().includes(searchText.toLowerCase()) ||
      new Date(item?.createdOn)
        .toLocaleString()
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      item?.createdByName?.toLowerCase().includes(searchText.toLowerCase()) ||
      new Date(item?.modifiedOn)
        .toLocaleString()
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
  });
  const columns = [
    {
      name: "Task Bucket Name",
      selector: (row) => row,
      sortable: true,
      cell: (row) => (
        <div>
          <h5 className="fs-14 fw-semibold mb-1">{row?.taskBucketName}</h5>
          <h6 className="fs-12 text-dark opacity-75">{row?.taskBucketInstructions}</h6>
        </div>
      ),
    },
    {
      name: "Task Group",
      selector: (row) => row?.groupName,
      sortable: true,
      cell: (row) => (
        <div>
          <Badge bg="primary">{row.groupName}</Badge>
        </div>
      ),
    },
    {
      name: "Created On",
      selector: (row) => new Date(row.createdOn).toLocaleString(),
      sortable: true,
      cell: (row) => (
        <div>
          <h5 className="fs-14 fw-semibold mb-1">{row?.createdByName}</h5>
          <h6 className="fs-12 text-dark opacity-75">
            {new Date(row.createdOn).toLocaleString()}
          </h6>
        </div>
      ),
    },
    {
      name: "Last Updated",
      selector: (row) => new Date(row.modifiedOn).toLocaleString(),
      sortable: true,
      cell: (row) => (
        <div>
          <h5 className="fs-14 fw-semibold mb-1">{row?.modifiedByName}</h5>
          <h6 className="fs-12 text-dark opacity-75">
            {new Date(row.modifiedOn).toLocaleString()}
          </h6>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: true,
      cell: (row) => (
        <div className="w-1">
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="button"
              className="btn-icon me-2"
              onClick={() =>
                SetShowUpdateRule({
                  showBool: true,
                  taskRuleId: row?.id,
                })
              }
            >
              <i className="ri-pencil-line"></i>
            </Button>
            <Button
              variant="danger"
              type="button"
              className="btn-icon"
              onClick={() => deleteTaskRule(row?.id)}
            >
              <i className="ri-delete-bin-line"></i>
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      {showUpdateRule?.showBool && (
        <UpdateRule
          show={showUpdateRule?.showBool}
          SetShowUpdateRule={SetShowUpdateRule}
          taskRuleId={showUpdateRule?.taskRuleId}
          setTaskEnginePublishList={props?.setTaskEnginePublishList}
          setTaskEngineDraftList={props?.setTaskEngineDraftList}
        />
      )}

      <div className="top-header d-md-flex align-items-center justify-content-between mb-2 position-static">
        <div>
          <h4 className="fs-16 main-title mb-3">Published</h4>
        </div>
      </div>

      <Card className="card-one">
        <Card.Header>
          <div className="d-flex">
            <Form.Control
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
              className="wt-250"
            />
          </div>
        </Card.Header>
        <Card.Body className="common-data-table">
          {props?.loading.isLoading &&
          props?.loading.value === "getTaskEnginePublishList" ? (
            <ShimmerLoader
              colNames={columns?.map((column) => column.name)}
              colsCount={1}
            />
          ) : (
            <DataTable columns={columns} data={filteredData} pagination />
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
