import React from "react";
import { Handle } from "reactflow";

function StatusNode({ data, isConnectable }) {
  return (
    <div
      style={{
        width: "200px",
        padding: 10,
        border: `1px solid rgb(221, 221, 221)`,
        borderRadius: 5,
        position: "relative",
        background: "#ddd",
      }}
    >
      <label>{data.label}</label>
      <Handle
        type="source"
        id={`output-handle-${data.componentValues[0].name}`}
        position="right"
        style={{
          background: "#555",
          top: "50%",
          marginLeft: "10px",
          width: "10px",
          height: "10px",
        }}
        isConnectable={isConnectable}
      />
    </div>
  );
}

export default StatusNode;
