import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Form, Row, Col } from "react-bootstrap";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ToastifyService from "../../_common/ToastifyService";
import Commonfield from "../../../infrastructure/core/Commonfield";
import useAuth from "../../../hooks/useAuth";
import LoadingSpinner from "../../_common/LoadingSpinner";

export default function UpdateUser(props) {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [userRoleList, setUserRoleList] = useState([]);
  const [teamLeadList, setTeamLeadList] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  useEffect(() => {
    getUserRoleList();
  }, []);
  useEffect(() => {
    if (props?.show) {
      if (props?.userAccountList) {
        setTeamLeadList(props?.userAccountList);
      }
    }
  }, [props?.show]);
  useEffect(() => {
    if (props?.userId) {
      getuserDetails(props?.userId);
    }
  }, [props?.userId]);
  const getUserRoleList = async () => {
    try {
      const userRollListData = await axiosPrivate.get(`Users/user-roles`);
      setUserRoleList(userRollListData?.data);
    } catch (err) {
      console.error(err);
    }
  };
  const getuserDetails = async (Id) => {
    try {
      const userData = await axiosPrivate.get(`Users/user-details/${Id}`);
      setFormData((prevData) => ({
        ...prevData,
        ...userData?.data?.result,
        status:
          userData?.data?.result?.status === Commonfield.status.active ? true : false,
      }));
    } catch (err) {
      console.error(err);
    }
  };
  console.log("formData",formData)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const UpdateUserDetails = async () => {
    try {
      setLoading({
        isLoading: true,
        value: "UpdateUser",
      });
      const userListData = await axiosPrivate.post(`Users/update-user`, {
        ...formData,
        status: formData.status
          ? Commonfield.status.active
          : Commonfield.status.inActive,
        modifiedBy: auth?.id,
        teamLeadId:
          formData.role === Commonfield.userRoles.teamLead
            ? Commonfield.defaultValue
            : formData.teamLeadId,
      });
      props?.setUserAccountList(userListData?.data?.result);
      props?.setUpdateUser({ showBool: false, userId: "" });
      ToastifyService.success("User Details Updated Successfully");
    } catch (err) {
      ToastifyService.error("Oops! Something Went Wrong");
      setLoading({
        isLoading: false,
        value: "UpdateUser",
      });
    } finally {
      setLoading({
        isLoading: false,
        value: "UpdateUser",
      });
    }
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        placement="end"
        className="w-40"
        onHide={() => props?.setUpdateUser({ showBool: false, userId: "" })}
      >
        <Offcanvas.Header>
          <Offcanvas.Title className="fs-16 text-dark">
            Update User Details
          </Offcanvas.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={() =>
              props?.setUpdateUser({ showBool: false, userId: "" })
            }
          ></button>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>Status</Form.Label>
                <div className="d-flex align-items-center">
                  <span className="me-2 fs-sm">InActive</span>

                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      checked={formData.status}
                      name="status"
                      style={{ width: "3em", height: "1.5em" }}
                      onChange={(e) => {
                        const { name, checked } = e.target;
                        setFormData((prevData) => ({
                          ...prevData,
                          [name]: checked,
                        }));
                      }}
                    />
                  </div>

                  <span className="ms-2 fs-sm">Active</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>
                  Full Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Full Name"
                  name="fullName"
                  onChange={handleChange}
                  value={formData.fullName}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>
                  Email<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>
                  Role <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  name="role"
                  onChange={handleChange}
                  value={formData.role}
                >
                  <option selected disabled value={Commonfield.defaultValue}>
                    Select Role
                  </option>
                  {userRoleList?.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>
          {formData.role !== Commonfield.userRoles.teamLead && (
            <Row>
              <Col xl={6}>
                <div className="mb-4">
                  <Form.Label>
                    Team Leader <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    name="teamLeadId"
                    onChange={handleChange}
                    value={formData.teamLeadId}
                  >
                    <option selected disabled value={Commonfield.defaultValue}>
                      Select Team Leader
                    </option>
                    {teamLeadList?.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.fullName}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
            </Row>
          )}
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="button"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            disabled={loading.isLoading && loading?.value === "UpdateUser"}
            onClick={UpdateUserDetails}
          >
            {loading.isLoading && loading?.value === "UpdateUser" ? (
              <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
            ) : (
              <>
                <span className="align-middle">Update</span>
              </>
            )}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
