import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Form, Row, Col } from "react-bootstrap";
import {
  defaultValidator,
  QueryBuilder,
  formatQuery,
} from "react-querybuilder";

import "react-querybuilder/dist/query-builder.css";
import Commonfield from "../../infrastructure/core/Commonfield";
import TestQueryTable from "./test-query.table";

import Select from "react-select";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import ToastifyService from "../_common/ToastifyService";
import LoadingSpinner from "../_common/LoadingSpinner";
export const validator = (RuleType) => !!RuleType.value;
export default function CreateNewRule(props) {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [userGroupList, setUserGroupList] = useState([]);
  const [formData, setFormData] = useState([]);
  const [testQuery, setTestQuery] = useState("");
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const [showTestQueryTable, setShowTestQueryTable] = useState({
    showBool: false,
    queryResponse: [],
  });
  const [query, setQuery] = useState({
    combinator: "and",
    rules: [],
  });
  const taskRuleStatus = JSON.parse(
    sessionStorage.getItem("commonField")
  )?.find((wqty) => wqty.typeGroupCode === "TRSTTS")?.fieldValues;
  const operators = [
    { name: "=", label: "=" },
    { name: "<", label: "<" },
    { name: ">", label: ">" },
    { name: "<=", label: "<=" },
    { name: ">=", label: ">=" },
    { name: "<>", label: "<>" },
    { name: "contains", label: "Contains" },
    { name: "beginsWith", label: "Begins with" },
    { name: "endsWith", label: "Ends with" },
    // { name: "doesNotContain", label: "does not contain" },
    // { name: "doesNotBeginWith", label: "does not beginWith" },
    // { name: "doesNotEndWith", label: "does not end with" },
    // { name: "null", label: "null" },
    // { name: "notNull", label: "not null" },
    // { name: "in", label: "in" },
    // { name: "notIn", label: "not in" },
    // { name: "between", label: "between" },
    // { name: "notBetween", label: "not between" },
    // { name: "!=", label: "!=" },
  ];
  const fields = [
    {
      name: "LastBilledPayer",
      label: "Last Billed payer",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "LastBilledPayerGovtComm",
      label: "Last Billed payer Govt/Comm",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "LastBilledPayerFC",
      label: "Last Billed payer FC",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "TestType",
      label: "Test Type",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "TestSubType",
      label: "Test Sub Type",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "InsuranceBalance",
      label: "Insurance Balance",
      inputType: "number",
      operators: operators.filter((op) =>
        ["<", ">", "=", "<=", ">="].includes(op.name)
      ),
    },
    {
      name: "DenialCategory",
      label: "Denial Category",
      operators: operators.filter((op) =>
        [
          "contains",
          "beginsWith",
          "<>",
          "<",
          ">",
          "=",
          "<=",
          ">=",
          "endsWith",
        ].includes(op.name)
      ),
    },
    {
      name: "LastStatus",
      label: "Last Status",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "LastAction",
      label: "Last Action",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "LastActionMode",
      label: "Last Action Mode",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "LastActionTaskQue",
      label: "Last Action Task Que",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "FollowUpDateBucket",
      label: "F-Up Date Bucket",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "WorkQueueType",
      label: "Work Queue Type",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
    {
      name: "DOSBucket",
      label: "DOS Bucket",
      operators: operators.filter((op) =>
        ["contains", "beginsWith", "="].includes(op.name)
      ),
    },
  ];
  useEffect(() => {
    getUserGroupList();
    setFormData((prevData) => ({
      ...prevData,
      status: Commonfield.defaultValue,
    }));
  }, []);
  useEffect(() => {
    if (props) {
      setQuery({
        combinator: "and",
        rules: [],
      });
    }
  }, [props]);
  const getUserGroupList = async () => {
    try {
      const userGroupListData = await axiosPrivate.get(`Users/user-groups`);
      setUserGroupList(
        userGroupListData?.data?.result?.map((usrgrp) => ({
          label: usrgrp.groupName,
          value: usrgrp.id,
        }))
      );
    } catch (err) {
      console.error(err);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleTestQuery = async () => {
    setLoading({
      isLoading: true,
      value: "testQuery",
    });
    const generatedSqlQuery = formatQuery(query, "sql");
    try {
      const queryResponse = await axiosPrivate.post(
        `TaskEngine/validate-task-bucket-query`,
        {
          taskBucketQuery:
            "select * from inventories where" + generatedSqlQuery,
        }
      );
      setShowTestQueryTable({
        showBool: true,
        queryResponse: queryResponse?.data?.result,
      });
    } catch (err) {
      ToastifyService.error("Oops! Something Went Wrong");
      setLoading({
        isLoading: false,
        value: "testQuery",
      });
    } finally {
      setLoading({
        isLoading: false,
        value: "testQuery",
      });
    }
  };
  const handleSubmit = async () => {
    try {
      setLoading({
        isLoading: true,
        value: "createRule",
      });
      const queryResponse = await axiosPrivate.post(
        `TaskEngine/create-task-bucket`,
        {
          ...formData,
          createdBy: auth?.id,
          taskBucketQuery:
            "select * from inventories where" + formatQuery(query, "sql"),
          isQueryVerified: true,
        }
      );
      props?.setTaskEnginePublishList(
        queryResponse?.data?.result?.filter(
          (pubrule) => pubrule.status === Commonfield.taskRuleStatus.published
        )
      );
      props?.setTaskEngineDraftList(
        queryResponse?.data?.result?.filter(
          (pubrule) => pubrule.status === Commonfield.taskRuleStatus.draft
        )
      );
      ToastifyService.success("Rule Created Successfully");
      props?.closeFunction();
    } catch (err) {
      setLoading({
        isLoading: false,
        value: "createRule",
      });
      ToastifyService.error("Oops! Something Went Wrong");
    } finally {
      setLoading({
        isLoading: false,
        value: "createRule",
      });
    }
  };
  const handleQueryChange = (query) => {
    const generatedSqlQuery = formatQuery(query, "sql");
    setTestQuery(`select * from example where ${generatedSqlQuery}`);
    setQuery(query);
  };

  return (
    <React.Fragment>
      <TestQueryTable
        show={showTestQueryTable.showBool}
        setShowTestQueryTable={setShowTestQueryTable}
        queryResponse={showTestQueryTable?.queryResponse}
        sqlQueryData={formatQuery(query, "sql")}
      />
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-60"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Create New Rule
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl={4}>
              <div className="mb-4">
                <Form.Label>
                  Task Bucket Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Task Name"
                  onChange={handleChange}
                  name="taskBucketName"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={5}>
              <div className="mb-4">
                <Form.Label>
                  Task Bucket Instruction <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Task Bucket Instruction"
                  rows="3"
                  onChange={handleChange}
                  name="taskBucketInstructions"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={4}>
              <div className="mb-4">
                <Form.Label>Task Group</Form.Label>
                <Select
                  options={userGroupList}
                  isSearchable={true}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      userGroup: e.value,
                    }))
                  }
                  name="userGroup"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <div>
                <div className="mb-3 bg-gray-800 p-2 rounded w-100">
                  <h6 className="text-white fs-14 lh-5">{testQuery}</h6>
                </div>
                <h3 className="fs-14 mb-3">Rule Condition</h3>
                <QueryBuilder
                  fields={fields}
                  operators={operators}
                  query={query}
                  onQueryChange={handleQueryChange}
                  showCloneButtons
                  validator={defaultValidator}
                  controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
                />
                <Button
                  variant="dark"
                  className="mt-3"
                  onClick={handleTestQuery}
                  disabled={loading.isLoading && loading?.value === "testQuery"}
                >
                  {loading.isLoading && loading?.value === "testQuery" ? (
                    <LoadingSpinner
                      color="#ffffff"
                      size={30}
                      type={"TailSpin"}
                    />
                  ) : (
                    "Test Query"
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Form.Select
            className="wt-150 me-2"
            onChange={handleChange}
            name="status"
          >
            <option value={Commonfield.defaultValue} selected disabled>
              Choose Status
            </option>
            {taskRuleStatus?.map((trstts) => (
              <option value={trstts.id}>{trstts.value}</option>
            ))}
          </Form.Select>
          <Button
            type="button"
            variant="primary"
            className="fs-14 d-flex align-items-center justify-content-center wt-150"
            disabled={loading.isLoading && loading?.value === "createRule"}
            onClick={handleSubmit}
          >
            {loading.isLoading && loading?.value === "createRule" ? (
              <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
            ) : (
              <>
                <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                <span className="align-middle">Save</span>
              </>
            )}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
