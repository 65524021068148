import React from "react";
import { Handle } from "reactflow";

const CheckboxNode = ({ data, isConnectable, selected }) => {
  const borderColor = selected ? "black" : "rgb(221, 221, 221)";

  return (
    <div
      style={{
        width: "200px",
        padding: "10px",
        border: `1px solid ${borderColor}`,
        borderRadius: 5,
        background: "#fff",
      }}
    >
      <div>
        <label>{data.label}</label>
        <Handle
          type="target"
          position="left"
          style={{
            background: "#555",
            top: "50%",
            width: "10px",
            height: "10px",
          }}
          isConnectable={isConnectable}
        />
      </div>
    </div>
  );
};

export default CheckboxNode;
