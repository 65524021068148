import React, { useEffect, useState } from "react";
import Header from "../../../layouts/Header";
import { Table, Card } from "react-bootstrap";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";

export default function WeeklyProductionDashboard() {
  const axiosPrivate = useAxiosPrivate();
  const [weeklyDashobard, setWeeklyDashobardData] = useState([]);
  const { auth } = useAuth();
  useEffect(() => {
    getWeeklyDashobardData();
  }, []);
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const getWeeklyDashobardData = async () => {
    try {
      setLoading({ isLoading: true, value: "getWeeklyDashobardData" });
      const weeklyDashobardData = await axiosPrivate.get(
        `Dashboard/weekly-production`
      );
      setWeeklyDashobardData(weeklyDashobardData?.data?.result);
    } catch (err) {
      console.error(err);
      setLoading({ isLoading: false, value: "getWeeklyDashobardData" });
    } finally {
      setLoading({ isLoading: false, value: "getWeeklyDashobardData" });
    }
  };
  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Weekly Production Dashboard</h4>
          </div>
        </div>

        <Card className="card-one">
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>User Name (Age days in system)</th>
                  <th>TL</th>
                  <th>Vendor Name</th>
                  <th>Last week 20</th>
                  <th>Last week 21</th>
                  <th>Last week 22</th>
                  <th>Last week 23</th>
                </tr>
              </thead>

              <tbody>
                {weeklyDashobard.map((weekly, index) => (
                  <tr key={index}>
                    <td>
                      {weekly?.username}
                      <span> ({weekly?.age}) </span>
                    </td>
                    <td>{weekly?.teamLeadName}</td>
                    <td>{weekly?.vendorName}</td>
                    <td>{weekly?.last3WeekCount}</td>
                    <td>{weekly?.last2WeekCount}</td>
                    <td>{weekly?.last1WeekCount}</td>
                    <td>{weekly?.lastWeekCount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
