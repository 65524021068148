import React, { useState } from "react";
import { Card, Badge, Button, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ShimmerLoader from "../../_common/ShimmerLoader";
import ToastifyService from "../../_common/ToastifyService";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ProductionLogList from "./production-log-list";

export default function ProductionLog(props) {
  const axiosPrivate = useAxiosPrivate();
  const [searchText, setSearchText] = useState("");
  const [showLogList, setShowLogList] = useState({
    showLogListBool: false,
    logList: [],
    exportId:""
  });

  const handleLog = async (exportId) => {
    try {
      const logList = await axiosPrivate.post(
        `Production/exported-cases/${exportId}`
      );
      setShowLogList({ showLogListBool: true, logList: logList?.data?.result ,exportId:exportId});
    } catch (err) {
      console.log(err);
      ToastifyService.error("Oops! Something Went Wrong");
    }
  };
  const columns = [
    {
      name: "Export ID",
      selector: (row) => row?.exportId,
      sortable: true,
      cell: (row) => <div>{row?.exportId}</div>,
    },
    {
      name: "Total Cases",
      selector: (row) => row?.totalCases,
      sortable: true,
      cell: (row) => (
        <div>
          <Badge bg="primary" className="fs-14">
            {row?.totalCases}
          </Badge>
        </div>
      ),
    },
    {
      name: "Generated On",
      selector: (row) => row?.createdOn,
      sortable: true,
      cell: (row) => <div>{new Date(row?.createdOn).toLocaleString()}</div>,
    },
    {
      name: "Generated By",
      selector: (row) => row?.createdByName,
      sortable: true,
      cell: (row) => <div>{row?.createdByName}</div>,
    },
    {
      name: "Status",
      selector: (row) => row?.statusDesc,
      sortable: true,
      cell: (row) => (
        <div>
          <div className="d-flex align-items-center gap-2">
            <span>{row?.statusDesc}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Log",
      selector: (row) => row?.statusDesc,
      sortable: true,
      cell: (row) => (
        <div className="">
          <Button
            variant="outline-primary"
            type="button"
            className="btn-icon me-2"
            onClick={() => handleLog(row.exportId)}
          >
            <i className="ri-file-line"></i>
          </Button>
        </div>
      ),
    },
  ];
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  const filteredData = props?.ProductionLogs?.filter((item) => {
    return (
      item?.exportId?.toString().includes(searchText.toLowerCase()) ||
      item?.totalCases?.toString().includes(searchText.toLowerCase()) ||
      item?.statusDesc?.toLowerCase().includes(searchText.toLowerCase()) ||
      new Date(item?.createdOn)
        .toLocaleString()
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      item?.createdByName?.toLowerCase().includes(searchText.toLowerCase()) ||
      new Date(item?.modifiedOn)
        .toLocaleString()
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
  });

  return (
    <React.Fragment>
      <ProductionLogList
        show={showLogList.showLogListBool}
        closeFunction={setShowLogList}
        logList={showLogList.logList}
        exportId={showLogList.exportId}
      />
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-0">Production Log</h4>
        </div>

        {/* <div>
          <Form.Control
            type="text"
            className="search-filter-input wt-200"
            placeholder="Filter..."
          />
        </div> */}
      </div>

      <Card className="card-one">
        <Card.Header>
          <div className="d-flex">
            <Form.Control
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
              className="wt-250"
            />
          </div>
        </Card.Header>
        <Card.Body className="common-data-table">
          {props?.loading?.isLoading &&
          props?.loading?.value === "getProductionLogs" ? (
            <ShimmerLoader
              colNames={columns?.map((column) => column.name)}
              colsCount={1}
            />
          ) : (
            <DataTable columns={columns} data={filteredData} pagination />
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
