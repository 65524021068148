import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
    const { setAuth,auth } = useAuth();
    
    const refresh = async () => {
        const response = await axios.post('/Auth/refresh-token', {
            'accessToken': auth.accessToken,
            'refreshToken':auth.refreshToken
            //withCredentials: true
        });
        var data = sessionStorage.getItem('auth');
        var userDetails = JSON.parse(data);
        sessionStorage.setItem("auth",JSON.stringify({ ...userDetails, accessToken: response.data.accessToken, refreshToken: response.data.refreshToken }));
        setAuth(prev => {     
            return { ...prev, accessToken: response.data.accessToken, refreshToken: response.data.refreshToken }
        });
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;