import React, {useState } from "react";
import { Button } from "react-bootstrap";
import AddReasonCode from "./create-reason-codes";

export default function ReasonCodes() {
    const [showReasonCode, setShowReasonCode] = useState(false);
    const handleReasonCode = (state) => {
        setShowReasonCode(state)
    }
    return (
        <React.Fragment>
            <AddReasonCode show={showReasonCode} closeFunction={handleReasonCode} />
            <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
                <div>
                    <h4 className="fs-16 main-title mb-1">Reason Codes</h4>
                </div>

                <div className="d-flex gap-2 mt-3 mt-md-0">
                    <Button variant="primary" className="d-flex align-items-center gap-1" onClick={()=> handleReasonCode(true)}>
                        <i className="ri-add-line fs-18 lh-1"></i>
                        <span className="d-sm-inline">Add Reason Codes</span>
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}