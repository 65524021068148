import React, { useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import AddInsurance from "./add-insurance";

export default function Insurance() {
    const [showInsurance, setShowInsurance] = useState(false);
    const handleInsurance = (state) => {
        setShowInsurance(state)
    }
    return (
        <React.Fragment>
            <AddInsurance show={showInsurance} closeFunction={handleInsurance} />
            <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
                <div>
                    <h4 className="fs-16 main-title mb-1">Insurance</h4>
                </div>

                <div className="d-flex gap-2 mt-3 mt-md-0">
                    <Button variant="primary" className="d-flex align-items-center gap-1" onClick={()=> handleInsurance(true)}>
                        <i className="ri-add-line fs-18 lh-1"></i>
                        <span className="d-sm-inline">Add Insurance</span>
                    </Button>
                </div>
            </div>

            <Card className="card-one">
                <Card.Body>
                <Table className="mb-0" responsive>
                    <thead>
                        <tr>
                            <th>Last Billed payer</th>
                            <th>Last Billed payer FC</th>
                            <th>Policy ID</th>
                        </tr>
                    </thead>
                </Table>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}