import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Nav, Tab, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import WorkedCases from "./WorkedCases/worked-cases";
import ProductionLog from "./ProductionLog/production-log";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";

export default function Placements() {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const [isSidebarShow, setSidebarShow] = useState(false);
  const [workedCasesCount, setWorkedCasesCounts] = useState(0);
  const [ProductionLogs, setProductionLogs] = useState([]);
  useEffect(() => {
    getProductionLogs();
  }, []);
  const getProductionLogs = async () => {
    try {
      setLoading({ isLoading: true, value: "getProductionLogs" });
      const ProductionLogsList = await axiosPrivate.get(
        `Production/production-log/${auth?.id}`
      );
      setProductionLogs(ProductionLogsList?.data?.result);
    } catch (err) {
      setLoading({ isLoading: false, value: "getProductionLogs" });
      console.error(err);
    } finally {
      setLoading({ isLoading: false, value: "getProductionLogs" });
    }
  };
  return (
    <React.Fragment>
      <Header />
      <Tab.Container defaultActiveKey="worked_cases_tab">
        <div
          className={"main main-file-manager" + (isSidebarShow ? " show" : "")}
        >
          <PerfectScrollbar className="file-sidebar page-in-tabs p-0">
            <Card className="card-one">
              <Card.Body>
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link
                      className="position-relative"
                      eventKey="worked_cases_tab"
                    >
                      <i className="ri-chat-upload-line"></i> Worked Cases{" "}
                      <span className="badge bg-primary badge-pill position-absolute right-10">
                        {workedCasesCount > 100 ? "100+" : workedCasesCount}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="production_log_tab">
                      <i className="ri-timer-line"></i> Production Log
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Body>
            </Card>
          </PerfectScrollbar>

          <PerfectScrollbar className="file-content p-3 p-lg-4">
            <Link
              href=""
              className="menu-file-manager"
              onClick={() => setSidebarShow(!isSidebarShow)}
            >
              <i className="ri-arrow-left-line"></i>
            </Link>
            <Tab.Content>
              {/* WORKED CASES TAB */}
              <Tab.Pane eventKey="worked_cases_tab">
                <WorkedCases
                  setWorkedCasesCounts={setWorkedCasesCounts}
                  setProductionLogs={setProductionLogs}
                />
              </Tab.Pane>
              {/* PRODUCTION LOG TAB */}
              <Tab.Pane eventKey="production_log_tab">
                <ProductionLog
                  ProductionLogs={ProductionLogs}
                  loading={loading}
                />
              </Tab.Pane>
            </Tab.Content>
          </PerfectScrollbar>
        </div>
      </Tab.Container>
    </React.Fragment>
  );
}
