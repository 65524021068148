// WebsiteNode.js
import React from "react";
import { Handle } from "reactflow";

const WebsiteNode = ({ data, selected }) => {
  const borderColor = selected ? "black" : "rgb(221, 221, 221)";
  return (
    <div
      style={{
        width: "200px",
        padding: 10,
        border: `1px solid ${borderColor}`,
        borderRadius: 5,
        position: "relative",
        background: "#fff",
      }}
    >
      <label>{data.label}</label>
      <Handle
        type="target"
        position="left"
        style={{ background: "#555", width: "10px", height: "10px" }}
      />
    </div>
  );
};

export default WebsiteNode;
