import React, { useEffect, useState } from "react";
import { Button, Offcanvas, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { axiosPrivate } from "../../../api/axios";
import ToastifyService from "../../_common/ToastifyService";
import LoadingSpinner from "../../_common/LoadingSpinner";
import useAuth from "../../../hooks/useAuth";
import Commonfield from "../../../infrastructure/core/Commonfield";

export default function ManuallyAssignComponent(props) {
  const [selectManualluAssignOptions, setselectManualluAssignOptions] =
    useState([]);
  const { auth } = useAuth();
  const [selectedTask, setSelectedTask] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const handleChange = (selected) => {
    setSelectedUser(selected);
  };
  useEffect(() => {
    getUserList();
    setSelectedTask(props?.selectedTask);
  }, [props?.selectedTask]);
  const getUserList = async () => {
    try {
      //   setLoading({ isLoading: true, value: "getUserGroupList" });
      const userGroupListData = await axiosPrivate.get(`Users/users`);
      const data = userGroupListData?.data?.result
        ?.filter((value) => value.role === Commonfield.userRoles.employee)
        ?.map((data) => ({
          value: data.id,
          label: data.fullName,
        }));
      setselectManualluAssignOptions(data);
    } catch (err) {
      console.error(err);
    }
  };
  const AssisgnManually = async () => {
    setLoading({
      isLoading: true,
      value: "AssisgnManually",
    });
    const task = selectedTask.map((value) => ({
      caseId: value.caseId,
      visitfid: value.visitfid,
    }));

    const formObj = {
      assignTo: selectedUser.value,
      assignedBy: auth.id,
      cases: task,
    };

    if (selectedTask.length === 0 || !selectedUser?.value) {
      ToastifyService.error("Please select a task and user to assign.");
      setLoading({ isLoading: false, value: "AssisgnManually" });
      return;
    }
    try {
      const response = await axiosPrivate.post(
        "Task/request-new-task",
        formObj
      );
      props?.setPendingInventory(response?.data?.result?.unAssignInventory);
      props?.setAssignedInventory(response?.data?.result?.assignInventory);
      setSelectedTask([]);
      props?.setSelectedTask([]);
      setSelectedUser("");
      props.closeFunction();
      ToastifyService.success("Task Assigned Successfully");
    } catch (error) {
      console.error("Error during task assignment:", error);
      ToastifyService.error("Failed to assign task. Please try again.");
    } finally {
      setLoading({
        isLoading: false,
        value: "AssisgnManually",
      });
    }
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-30"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Manually Assign
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl={10}>
              <div className="mb-4">
                <Form.Label>
                  Choose Agent <span className="text-danger">*</span>
                </Form.Label>
                <Select
                  options={selectManualluAssignOptions}
                  isSearchable={true}
                  value={selectedUser}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
          <Button
            type="button"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            disabled={loading.isLoading && loading?.value === "AssisgnManually"}
            onClick={AssisgnManually}
          >
            {loading.isLoading && loading?.value === "AssisgnManually" ? (
              <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
            ) : (
              <>
                <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                <span className="align-middle">Assign</span>
              </>
            )}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
