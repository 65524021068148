import React, { useState } from "react";
import { Button, Badge, Form, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import UpdateRule from "../Update/Edit-rule";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Commonfield from "../../../infrastructure/core/Commonfield";
import useAuth from "../../../hooks/useAuth";
import ToastifyService from "../../_common/ToastifyService";
import ShimmerLoader from "../../_common/ShimmerLoader";

export default function DraftComponent(props) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [searchText, setSearchText] = useState("");
  const [showUpdateRule, SetShowUpdateRule] = useState({
    showBool: false,
    taskRuleId: "",
  });
  const handleSearch = (event) => {
    setSearchText(event?.target?.value);
  };
  const deleteTaskRule = async (taskId) => {
    try {
      const taskRuleData = await axiosPrivate.post(
        `TaskEngine/delete-task-bucket/${taskId}`
      );
      props?.setTaskEnginePublishList(
        taskRuleData?.data?.result?.filter(
          (pubrule) => pubrule.status === Commonfield.taskRuleStatus.published
        )
      );
      props?.setTaskEngineDraftList(
        taskRuleData?.data?.result?.filter(
          (pubrule) => pubrule.status === Commonfield.taskRuleStatus.draft
        )
      );
      ToastifyService.success("Rule Deleted Successfully");
    } catch (err) {
      console.error(err);
      ToastifyService.error("Oops! Something Went Wrong");
    }
  };
  const handlePublishRule = async (taskId) => {
    try {
      const formData = {
        id: taskId,
        status: Commonfield.taskRuleStatus.published,
        modifiedBy: auth?.id,
      };
      const taskRuleData = await axiosPrivate.post(
        `TaskEngine/update-task-bucket-state`,
        formData
      );
      props?.setTaskEnginePublishList(
        taskRuleData?.data?.result?.filter(
          (pubrule) => pubrule.status === Commonfield.taskRuleStatus.published
        )
      );
      props?.setTaskEngineDraftList(
        taskRuleData?.data?.result?.filter(
          (pubrule) => pubrule.status === Commonfield.taskRuleStatus.draft
        )
      );
      ToastifyService.success("Rule Published Successfully");
    } catch (err) {
      console.error(err);
      ToastifyService.error("Oops! Something Went Wrong");
    }
  };
  const filteredData = props?.taskEnginDraftList?.filter((item) => {
    return (
      item?.taskBucketName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.groupName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.taskBucketInstructions
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      item?.modifiedByName?.toLowerCase().includes(searchText.toLowerCase()) ||
      new Date(item?.createdOn)
        .toLocaleString()
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      item?.createdByName?.toLowerCase().includes(searchText.toLowerCase()) ||
      new Date(item?.modifiedOn)
        .toLocaleString()
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
  });
  const columns = [
    {
      name: "Task Bucket Name",
      selector: (row) => row?.taskBucketName,
      sortable: true,
      cell: (row) => (
        <div>
          <h5 className="fs-14 fw-semibold mb-1">{row?.taskBucketName}</h5>
          <h6 className="fs-12 text-black-50">{row?.taskBucketInstructions}</h6>
        </div>
      ),
    },
    {
      name: "Task Group",
      selector: (row) => row?.groupName,
      sortable: true,
      cell: (row) => (
        <div>
          <Badge bg="primary">{row.groupName}</Badge>
        </div>
      ),
    },
    {
      name: "Created On",
      selector: (row) => row?.createdOn,
      sortable: true,
      cell: (row) => (
        <div>
          <h5 className="fs-14 fw-semibold mb-1">{row?.createdByName}</h5>
          <h6 className="fs-12 text-black-50">
            {new Date(row.createdOn).toLocaleString()}
          </h6>
        </div>
      ),
    },
    {
      name: "Last Updated",
      selector: (row) => row?.modifiedByName,
      sortable: true,
      cell: (row) => (
        <div>
          <h5 className="fs-14 fw-semibold mb-1">{row?.modifiedByName}</h5>
          <h6 className="fs-12 text-black-50">
            {new Date(row.modifiedOn).toLocaleString()}
          </h6>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      sortable: true,
      cell: (row) => (
        <div className="d-flex justify-content-end gap-2">
          <Button
            variant="success"
            type="button"
            className="py-1"
            onClick={() => handlePublishRule(row?.id)}
          >
            Publish
          </Button>
          <Button
            variant="primary"
            type="button"
            className="btn-icon w-1"
            onClick={() =>
              SetShowUpdateRule({
                showBool: true,
                taskRuleId: row?.id,
              })
            }
          >
            <i className="ri-pencil-line"></i>
          </Button>
          <Button
            variant="danger"
            type="button"
            className="btn-icon w-1"
            onClick={() => deleteTaskRule(row?.id)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      {showUpdateRule?.showBool && (
        <UpdateRule
          show={showUpdateRule?.showBool}
          SetShowUpdateRule={SetShowUpdateRule}
          taskRuleId={showUpdateRule?.taskRuleId}
          setTaskEnginePublishList={props?.setTaskEnginePublishList}
          setTaskEngineDraftList={props?.setTaskEngineDraftList}
        />
      )}

      <div className="top-header d-md-flex align-items-center justify-content-between mb-2 position-static">
        <div>
          <h4 className="fs-16 main-title mb-3">Draft</h4>
        </div>
      </div>
      <Card className="card-one">
        <Card.Header>
          <div className="d-flex ">
            <Form.Control
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
              className="wt-250"
            />
          </div>
        </Card.Header>
        <Card.Body className="common-data-table">
        {props?.loading.isLoading &&
          props?.loading.value === "getTaskEngineDraftList" ? (
            <ShimmerLoader
              colNames={columns?.map((column) => column.name)}
              colsCount={1}
            />
          ) : (
          <DataTable columns={columns} data={filteredData} pagination />
        )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
