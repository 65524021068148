import React from "react";
import { Button, Offcanvas } from "react-bootstrap";

export default function AddReasonCode(props) {
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Add Reason Code</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    ADD Reason Code
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="button" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Create Reason Code</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}