import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const NodeSidebar = ({onUpdate}) => {
  const [loading, setLoading] = useState({ isLoading: false, value: "" });
  const [workflowComponentList, setWorkflowComponentList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const onDragStart = (event, node) => {
    event.dataTransfer.setData("application/reactflow", JSON.stringify(node));
    event.dataTransfer.effectAllowed = "move";
  };

  useEffect(() => {
    getComponentList();
  }, []);

  const getComponentList = async () => {
    try {
      setLoading({ isLoading: true, value: "getWorkflowComponentList" });
      const workflowListData = await axiosPrivate.get(`Setting/get-workflow-component-List`);
      const filteredNodes = workflowListData?.data;
      setWorkflowComponentList(filteredNodes);
      if (typeof onUpdate === 'function') {
        onUpdate(filteredNodes);
      } else {
        console.warn('onUpdate is not a function');
      }
    } catch (err) {
      console.error(err);
      setLoading({ isLoading: false, value: "getWorkflowComponentList" });
    } finally {
      setLoading({ isLoading: false, value: "getWorkflowComponentList" });
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredNodes = workflowComponentList.filter((node) =>
    node.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const clearSearch = () => {
    setSearchTerm("");
  };

  return (
    <div
      style={{
        padding: "10px",
        width: "250px",
        background: "#fff",
        borderRight: "1px solid #ddd",
        overflowY: "auto",
        position: "relative",
      }}
    >
      {" "}
      <div className="d-md-flex align-items-center justify-content-between mb-2">
        <div>
          <h4 className="main-title mb-0">Nodes</h4>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <input
          type="text"
          placeholder="Search nodes..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{
            width: "100%",
            padding: "5px",
            marginBottom: "5px",
            border: "none",
            borderBottom: "2px solid #ddd",
            borderRadius: "0",
            outline: "none",
          }}
        />
        {searchTerm && (
          <button
            onClick={clearSearch}
            style={{
              position: "absolute",
              right: "10px",
              top: "40%",
              transform: "translateY(-50%)",
              background: "none",
              border: "none",
              cursor: "pointer",
              color: "#0d6efd",
              fontSize: "20px",
            }}
          >
            &times;
          </button>
        )}
      </div>
      <hr />
      {loading.isLoading && loading.value === "getWorkflowComponentList" ? (
        <div className="spinner-loader-sidenav">
          <TailSpin color="#0d6efd" height={30} />
        </div>
      ) : (
        <>
          {filteredNodes.map((node) => (
            <div
              key={node.id}
              onDragStart={(event) => onDragStart(event, node)}
              draggable
              style={{
                padding: "10px",
                marginBottom: "10px",
                background: "#fff",
                border: "1px solid #ddd",
                borderRadius: "5px",
                cursor: "move",
              }}
            >
              <div>{node.label}</div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default NodeSidebar;
