import React, { useEffect, useRef, useState } from "react";
import "./details.scss";
import { Offcanvas, Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import DocumentUpload from "./document-upload";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ClaimInformation from "./claim-information";
import PriorAuthorization from "./prior-authorization";
import InsuranceInformation from "./insurance-information";
import PayerClosedModal from "../payer-closed-modal";
import WorkLaterModal from "../work-later-modal";
import TLQueryModal from "../TL-query-modal";
import { Link } from "react-router-dom";
import LabReferringProviderInfo from "./lab-referring-pro-info";
import DebialCorerespondence from "./debial-corerespondence";
import { useEdgesState, useNodesState } from "reactflow";
import CaseDispositionDynamic from "./CaseDispositionDynamic/CaseDispositionDynamic";
import LoadingSpinner from "../../_common/LoadingSpinner";

export default function MyTasksDetails(props) {
  const workflowComponentList = JSON.parse(
    sessionStorage.getItem("WorkflowComponentList")
  );
  const taskId = props?.taskId;
  const taskDetailsRef = useRef(null);
  const axiosPrivate = useAxiosPrivate();
  const [showUploadDocModal, setShowUploadDocModal] = useState(false);

  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [toggleStates, setToggleStates] = useState({});
  const [formData, setFormData] = useState({});
  const [dropdownSelections, setDropdownSelections] = useState({});
  const [multiDropdownSelections, setMultiDropdownSelections] = useState({});
  const [checkboxes, setCheckboxes] = useState({});
  const [nodeData, setNodeData] = useState([]);
  const [amdFormSubmittedData, setAMDFormSubmittedData] = useState({});
  const caseSubmittedDataToBeSetRef = useRef({});
  const [dynamicMapping, setDynamicMapping] = useState({});

  const [showPayerClosed, setShowPayerClosed] = useState(false);
  const [showWorkLater, setShowWorkLater] = useState(false);
  const [showTlQuery, setShowTlQuery] = useState(false);
  const [loading, setLoading] = useState({ isLoading: false, value: "" });

  useEffect(() => {
    if (taskId) {
      getTaskDetails(taskId);
    }
  }, [taskId]);
  const getTaskDetails = async () => {
    setLoading({ isLoading: true, value: "getDispositionDetails" });
    try {
      const taskDetail = await axiosPrivate.get(`Task/case-details/${taskId}`);
      const updatedTaskDetails = lowercaseTaskDetails(taskDetail?.data?.result);
      taskDetailsRef.current = updatedTaskDetails;
    } catch (err) {
      console.error("Error fetching task details:", err);
    } finally {
      const { dispositionid } = taskDetailsRef.current || {};
      if (
        dispositionid &&
        dispositionid !== "00000000-0000-0000-0000-000000000000"
      ) {
        getDispositionDetails(dispositionid);
      }
    }
  };
  const lowercaseTaskDetails = (taskDetails) => {
    const lowered = {};
    Object.keys(taskDetails).forEach((key) => {
      lowered[key.toLowerCase()] = taskDetails[key];
    });
    return lowered;
  };
  const getDispositionDetails = async (id) => {
    setLoading({ isLoading: true, value: "getDispositionDetails" });
    const updatedDispositionId = props?.decisionTreeList
      ?.map((dispositionData) => ({
        id: dispositionData.id,
        label: dispositionData.dispositionName,
      }))
      ?.find((data) => data.id === id);
    try {
      if (
        taskDetailsRef?.current?.status ===
        "48f67ff4-6152-4a15-9294-476f09a73e76"
      ) {
        if (taskDetailsRef?.current?.casesubmittedformdata) {
          const casesubmittedformdata = JSON.parse(
            taskDetailsRef?.current?.casesubmittedformdata
          );
          const extractData = (source, keyType = "nodeId") =>
            Object.entries(source || {}).reduce((acc, [nodeKey, nodeValue]) => {
              const key =
                keyType === "componentId" ? nodeValue?.componentId : nodeKey;
              const value =
                keyType === "componentId"
                  ? nodeValue?.data?.label || nodeValue?.data
                  : nodeValue?.data;
              if (key && value) {
                acc[key] = value;
              }
              return acc;
            }, {});

          const formDataWithComponentId = extractData(
            casesubmittedformdata?.formData,
            "componentId"
          );
          const dropdownWithComponentId = extractData(
            casesubmittedformdata?.dropdownSelections,
            "componentId"
          );
          const dropdownWithNodeId = extractData(
            casesubmittedformdata?.dropdownSelections
          );
          const formDataWithNodeId = extractData(
            casesubmittedformdata?.formData
          );
          const checkBoxWithNodeId = extractData(
            casesubmittedformdata?.checkboxes
          );
          const toggleStatesWithNodeId = extractData(
            casesubmittedformdata?.toggleStates
          );
          const multiSelectWithNodeId = extractData(
            casesubmittedformdata?.multiDropdownSelections
          );
          const checkBoxWithComponentId = extractData(
            casesubmittedformdata?.checkboxes,
            "componentId"
          );
          const toggleStatesWithComponentId = extractData(
            casesubmittedformdata?.toggleStates,
            "componentId"
          );
          const multiSelectWithComponentId = extractData(
            casesubmittedformdata?.multiDropdownSelections,
            "componentId"
          );

          const updatedDropdownSelections = {
            ...dropdownWithNodeId,
            node_0: {
              label: updatedDispositionId?.label,
              value: updatedDispositionId?.id,
            },
          };
          const updatedFormData = {
            ...formDataWithComponentId,
            ...dropdownWithComponentId,
            ...checkBoxWithComponentId,
            ...toggleStatesWithComponentId,
            ...multiSelectWithComponentId,
          };

          caseSubmittedDataToBeSetRef.current = updatedFormData;

          setFormData(formDataWithNodeId);
          setDropdownSelections(updatedDropdownSelections);
          setCheckboxes(checkBoxWithNodeId);
          setToggleStates(toggleStatesWithNodeId);
          setMultiDropdownSelections(multiSelectWithNodeId);
          //-----------------------need to change code for below states-------------------------
          setAMDFormSubmittedData(casesubmittedformdata?.amdFormSubmittedData);
          // -------------------------------------------------------------------------------
        }
      }
      const dispositionDetailsResponse = await axiosPrivate.get(
        `Setting/get-disposition-details/${id}`
      );
      const dispositionDetails = dispositionDetailsResponse.data;
      const dispositionNameNode = {
        id: "node_0",
        type: "00000000-0000-0000-0000-000000000001",
        position: { x: 100, y: 100 },
        data: {
          id: dispositionDetails.disposition.id,
          label: dispositionDetails.disposition.dispositionName,
          componentValues: [
            {
              id: dispositionDetails.disposition.id,
              name: dispositionDetails.disposition.dispositionName,
            },
          ],
          componentTypeId: "00000000-0000-0000-0000-000000000001",
        },
        width: 200,
        height: 50,
      };
      setNodes((prevNodes) => [...prevNodes, dispositionNameNode]);
      const updatedNodes = dispositionDetails?.dispositionNodes?.reduce(
        (acc, detail) => {
          const componentData = workflowComponentList?.find(
            (component) => component.id === detail.componentId
          );
          if (!componentData) {
            return acc;
          }
          const nodeData = {
            id: detail.nodeId,
            type:
              componentData.componentTypeId ===
              "4e0073a0-2338-4acf-bbd7-6b265bed7f01"
                ? componentData.datatypeId
                : componentData.componentTypeId,
            position: {
              x: detail.position_X,
              y: detail.position_Y,
            },
            data: {
              label: componentData.label,
              options: componentData.options || [],
              componentTypeId: componentData.componentTypeId,
              visitId: taskDetailsRef?.current?.visitfid,
              datatypeId:
                componentData.datatypeId ||
                "00000000-0000-0000-0000-000000000000",
              excelHeader: componentData?.excelHeader?.toLowerCase() || "",
              notes: componentData.notes || "",
              componentTypeName: componentData.componentTypeName || "",
              datatypeName: componentData.datatypeName || null,
              statusName: componentData.statusName || "Active",
              createdByName: componentData.createdByName || "Unknown",
              modifiedByName: componentData.modifiedByName || "Unknown",
              componentValues: componentData.componentValues || [],
              id: componentData.id,
              status: componentData.status || "",
              createdBy: componentData.createdBy || "",
              createdOn: componentData.createdOn || new Date().toISOString(),
              modifiedBy: componentData.modifiedBy || "",
              modifiedOn: componentData.modifiedOn || new Date().toISOString(),
            },
            width: componentData.width || 200,
            height: detail.height,
          };
          acc.push(nodeData);
          return acc;
        },
        []
      );
      setNodes((prevNodes) => [...prevNodes, ...updatedNodes]);
      setDynamicMapping(dispositionDetails?.dispositionConnections);
      const edgeSet = new Set();
      const formattedEdges = dispositionDetails?.dispositionEdges?.reduce(
        (acc, edge) => {
          const edgeData = {
            id: edge.edgeId,
            source: edge.source,
            target: edge.target,
            sourceHandle: edge.sourceHandle,
            label: edge.label,
          };
          edgeSet.add(edgeData.id);
          acc.push(edgeData);
          return acc;
        },
        []
      );

      setEdges(formattedEdges);
      const updatedNodesData = Object.values(
        dispositionDetails?.dispositionNodes?.reduce((acc, detail) => {
          const componentData = workflowComponentList.find(
            (component) => component.id === detail.componentId
          );
          if (!componentData) {
            return acc;
          }
          acc[detail.nodeId] = {
            nodeId: detail.nodeId,
            label: componentData.label || "Default Label",
            isRequired: detail.isRequired,
            addStatus: detail.addStatus,
            isSOPFile: detail.isSOPFile,
            amdStatus: detail.amdStatusId,
            SOPFile: null,
            excelHeader: detail.excelHeader.toLowerCase() || "",
            compValue: null,
            notes: componentData.notes || "",
            visitId: taskDetailsRef?.current?.visitfid,
            type: componentData.componentTypeId,
            componentId: detail.componentId,
          };
          const updateSelectionState = (
            typeId,
            nodeId,
            value,
            componentValues
          ) => {
            const data = componentValues
              ?.map((option) => ({
                value: option.id,
                label: option.name,
              }))
              .find(
                (cmpval) =>
                  cmpval?.label?.toLowerCase() === value?.toLowerCase()
              );

            const stateUpdaters = {
              "4e0073a0-2338-4acf-bbd7-6b265bed7f01": (data) =>
                setFormData((prev) => ({ ...prev, [nodeId]: data })),
              "4e0073a0-2338-4acf-bbd7-6b265bed7f02": (data) =>
                setDropdownSelections((prev) => ({ ...prev, [nodeId]: data })),
              "4e0073a0-2338-4acf-bbd7-6b265bed7f05": (data) =>
                setToggleStates((prev) => ({ ...prev, [nodeId]: data })),
              "4e0073a0-2338-4acf-bbd7-6b265bed7f03": (data) =>
                setMultiDropdownSelections((prev) => ({
                  ...prev,
                  [nodeId]: data,
                })),
            };

            if (stateUpdaters[typeId]) {
              stateUpdaters[typeId](data);
            }
          };

          const processComponentData = (
            nodeId,
            value,
            typeId,
            componentValues
          ) => {
            if (typeId === "4e0073a0-2338-4acf-bbd7-6b265bed7f01") {
              setFormData((prev) => ({ ...prev, [nodeId]: value }));
            } else {
              updateSelectionState(typeId, nodeId, value, componentValues);
            }
          };

          const getValueFromSource = (source, key) =>
            source?.[key?.toLowerCase()];

          if (
            taskDetailsRef?.current?.status !==
            "48f67ff4-6152-4a15-9294-476f09a73e76"
          ) {
            const normalizedExcelHeader = detail.excelHeader?.toLowerCase();
            const matchedValue = getValueFromSource(
              taskDetailsRef.current,
              normalizedExcelHeader
            );

            if (matchedValue !== undefined) {
              acc[detail.nodeId].compValue = matchedValue;
              processComponentData(
                detail.nodeId,
                matchedValue,
                componentData.componentTypeId,
                componentData?.componentValues
              );
            }
          } else {
            const caseSubmittedValue =
              caseSubmittedDataToBeSetRef?.current[detail.componentId];
            if (caseSubmittedValue) {
              if (
                ![
                  "4e0073a0-2338-4acf-bbd7-6b265bed7f03",
                  "4e0073a0-2338-4acf-bbd7-6b265bed7f04",
                ].includes(componentData.componentTypeId)
              ) {
                acc[detail.nodeId].compValue = caseSubmittedValue;
                if (acc[detail.nodeId]?.compValue) {
                  processComponentData(
                    detail.nodeId,
                    caseSubmittedValue,
                    componentData.componentTypeId,
                    componentData?.componentValues
                  );
                }
              }
            } else {
              const normalizedExcelHeader = detail.excelHeader?.toLowerCase();
              const matchedValue = getValueFromSource(
                taskDetailsRef.current,
                normalizedExcelHeader
              );

              if (matchedValue !== undefined) {
                acc[detail.nodeId].compValue = matchedValue;
                processComponentData(
                  detail.nodeId,
                  matchedValue,
                  componentData.componentTypeId,
                  componentData?.componentValues
                );
              }
            }
          }

          return acc;
        }, {})
      );
      setNodeData(updatedNodesData);
    } catch (err) {
      console.error(err);
      setLoading({ isLoading: false, value: "getDispositionDetails" });
    } finally {
      setLoading({ isLoading: false, value: "getDispositionDetails" });
    }
  };
  const resetForm = () => {
    props?.setShowTasksDetails({ showTaskDetails: false, taskId: "" });
    taskDetailsRef.current = {};
    caseSubmittedDataToBeSetRef.current = {};
    setDynamicMapping([]);
    setEdges([]);
    setNodes([]);
    setNodeData([]);
    setToggleStates([]);
    setFormData([]);
    setDropdownSelections([]);
    setMultiDropdownSelections([]);
    setCheckboxes({});
    setAMDFormSubmittedData({});
  };
  return (
    <React.Fragment>
      <PayerClosedModal
        show={showPayerClosed?.showBoolPayerClosed}
        closeFunction={setShowPayerClosed}
        taskId={taskId}
        visitId={taskDetailsRef.current?.visitfid}
        setTeamLeadQueries={props?.setTeamLeadQueries}
        // setTaskSummary={props?.setTaskSummary}
        setPendingTasks={props?.setPendingTasks}
        setParkedTasks={props?.setParkedTasks}
        setMyTask={props?.setMyTask}
        setShowTasksDetails={props?.setShowTasksDetails}
      />
      <WorkLaterModal
        show={showWorkLater?.showBoolWorkLater}
        closeFunction={setShowWorkLater}
        taskId={taskId}
        visitId={taskDetailsRef.current?.visitfid}
        setTeamLeadQueries={props?.setTeamLeadQueries}
        // setTaskSummary={props?.setTaskSummary}
        setPendingTasks={props?.setPendingTasks}
        setParkedTasks={props?.setParkedTasks}
        setMyTask={props?.setMyTask}
        setShowTasksDetails={props?.setShowTasksDetails}
      />
      <TLQueryModal
        show={showTlQuery?.showBoolTLQuery}
        closeFunction={setShowTlQuery}
        taskId={taskId}
        visitId={taskDetailsRef.current?.visitfid}
        setTeamLeadQueries={props?.setTeamLeadQueries}
        // setTaskSummary={props?.setTaskSummary}
        setPendingTasks={props?.setPendingTasks}
        setParkedTasks={props?.setParkedTasks}
        setMyTask={props?.setMyTask}
        setShowTasksDetails={props?.setShowTasksDetails}
      />
      <Offcanvas
        show={props.show}
        onHide={() => resetForm()}
        placement="end"
        className="w-100 case-disposition-modal"
      >
        <Offcanvas.Header className="justify-content-between">
          <Offcanvas.Title className="fs-16 text-dark">
            <Link
              onClick={() => resetForm()}
              style={{ zIndex: 5 }}
              className="align-middle text-dark me-2 position-relative"
            >
              <i className="ri-arrow-left-s-line fs-28"></i>
            </Link>
            <span className="align-middle">Case Details</span>
          </Offcanvas.Title>

          <div className="d-flex align-items-center gap-2 park-case-wrapper">
            <Button
              variant="primary"
              className="d-flex align-items-center gap-1"
              onClick={() =>
                setShowUploadDocModal({
                  showUploadModelBool: true,
                  caseId: taskDetailsRef.current?.id,
                  taskBucketQueueId: taskId,
                  visitId: taskDetailsRef.current?.visitfid,
                })
              }
            >
              <i className="ri-add-line fs-18 lh-1"></i>
              <span className="d-sm-inline">Upload Document</span>
            </Button>

            {props.setTeamLeadQueries.length + props.setParkedTasks.length <=
            10 ? (
              <div className="d-flex justify-content-end">
                <Dropdown className="me-2">
                  <Dropdown.Toggle
                    variant="danger"
                    className="custom-dropdown-action ht-30 pe-2 ps-2 pt-0 pb-0 ht-35"
                  >
                    Park Case
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() =>
                        setShowPayerClosed({
                          showBoolPayerClosed: true,
                          taskId: props?.id,
                          visitId: props?.visitfid,
                        })
                      }
                    >
                      Payer closed
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        setShowWorkLater({
                          showBoolWorkLater: true,
                          taskId: props?.id,
                          visitId: props?.visitfid,
                        })
                      }
                    >
                      Work later
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        setShowTlQuery({
                          showBoolTLQuery: true,
                          taskId: props.id,
                          visitId: props.visitfid,
                        })
                      }
                    >
                      TL Query
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Button variant="default" onClick={() => resetForm()}>
                  <i className="ri-close-line"></i>
                </Button>
              </div>
            ) : (
              <div className="d-flex justify-content-end">
                <Button variant="default" onClick={() => resetForm()}>
                  <i className="ri-close-line"></i>
                </Button>
              </div>
            )}
          </div>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Row className="g-3">
              <Col md={7} sm={12}>
                <Card className="card-one case-details-card">
                  <div className="mb-4 position-sticky bg-white top-0 z-index4 p-2 pb-3 overflow-y custom-scroll">
                    <h2 className="fs-18 fw-semibold text-dark">
                      {taskDetailsRef.current?.patient_first_name}{" "}
                      {taskDetailsRef.current?.patient_last_name}
                    </h2>
                    <div className="d-flex align-items-center gap-2 mb-3">
                      <span className="fs-12 text-dark">
                        {/* {taskDetails?.gender} */}
                        {/* {taskDetails?.gender === "F" && (<b>Fmale</b>)}{" "}
                        {taskDetails?.gender === "M" && <b>Male</b>}{" "}
                        {taskDetails?.gender === "" && (<b>{taskDetails?.gender}</b>)} */}
                        {taskDetailsRef.current?.gender === null ||
                        taskDetailsRef.current?.gender === ""
                          ? "-"
                          : taskDetailsRef.current?.gender}
                      </span>
                      <span className="text-black-50">|</span>
                      <span className="fs-12 text-dark">
                        <b>Age:</b>{" "}
                        {taskDetailsRef.current?.age === null ||
                        taskDetailsRef.current?.age === ""
                          ? "-"
                          : taskDetailsRef.current?.age}
                      </span>
                      {/* <span className="text-black-50">|</span>
                      <span className="fs-12 text-dark">{taskDetails?.mobileNo}</span> */}
                    </div>

                    {/* <Badge bg="danger" className="rounded fs-14 mb-3 me-2">{taskDetails?.taskPriority}</Badge>
                    <Badge bg="primary" className="rounded fs-14 mb-3">{taskDetails?.taskBucketName}</Badge> */}

                    <Card className="mb-3 card-one h-auto">
                      <Card.Body>
                        <Row className="flex-wrap g-3">
                          <Col xl={3} md={4} xs={6}>
                            <h5 className="fs-14 mb-1">Visit ID</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetailsRef.current?.visitfid === null ||
                              taskDetailsRef.current?.visitfid === ""
                                ? "-"
                                : taskDetailsRef.current?.visitfid}
                            </h4>
                          </Col>

                          <Col xl={3} md={4} xs={6}>
                            <h5 className="fs-14 mb-1">LIMS Casefile ID</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetailsRef.current?.lims_casefile_id ===
                                null ||
                              taskDetailsRef.current?.lims_casefile_id === ""
                                ? "-"
                                : taskDetailsRef.current?.lims_casefile_id}
                            </h4>
                          </Col>

                          <Col xl={3} md={4} xs={6}>
                            <h5 className="fs-14 mb-1">LIMS DOS Formal</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetailsRef.current?.lims_date_of_service ===
                              "0001-01-01T00:00:00"
                                ? "-"
                                : new Date(
                                    taskDetailsRef.current?.lims_date_of_service
                                  ).toLocaleString()}
                            </h4>
                          </Col>

                          <Col xl={3} md={4} xs={6}>
                            <h5 className="fs-14 mb-1">Test Name</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetailsRef.current?.test_name === null ||
                              taskDetailsRef.current?.test_name === ""
                                ? "-"
                                : taskDetailsRef.current?.test_name}
                            </h4>
                          </Col>

                          <Col xl={3} md={4} xs={6}>
                            <h5 className="fs-14 mb-1">Panel</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetailsRef.current?.currentils?.panels ===
                                null || taskDetailsRef.current?.panels === ""
                                ? "-"
                                : taskDetailsRef.current?.panels}
                            </h4>
                          </Col>

                          <Col xl={3} md={4} xs={6}>
                            <h5 className="fs-14 mb-1">Panel Type</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetailsRef.current?.panel_type === null ||
                              taskDetailsRef.current?.panel_type === ""
                                ? "-"
                                : `$ ${taskDetailsRef.current?.panel_type}`}
                            </h4>
                          </Col>

                          <Col xl={3} md={4} xs={6}>
                            <h5 className="fs-14 mb-1">All Procedure Codes</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetailsRef.current?.allproccodes === null ||
                              taskDetailsRef.current?.allproccodes === ""
                                ? "-"
                                : taskDetailsRef.current?.allproccodes}{" "}
                            </h4>
                          </Col>

                          <Col xl={3} md={4} xs={6}>
                            <h5 className="fs-14 mb-1">Modifier</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetailsRef.current?.modifier === null ||
                              taskDetailsRef.current?.modifier === ""
                                ? "-"
                                : taskDetailsRef.current?.modifier}{" "}
                            </h4>
                          </Col>

                          <Col xl={3} md={4} xs={6}>
                            <h5 className="fs-14 mb-1">BDE Risk Category</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetailsRef.current?.bde_risk_category ===
                                null ||
                              taskDetailsRef.current?.bde_risk_category === ""
                                ? "-"
                                : taskDetailsRef.current
                                    ?.bde_risk_category}{" "}
                            </h4>
                          </Col>

                          <Col xl={3} md={4} xs={6}>
                            <h5 className="fs-14 mb-1">
                              Empower Risk Combined
                            </h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetailsRef.current?.empower_risk === null ||
                              taskDetailsRef.current?.empower_risk === ""
                                ? "-"
                                : taskDetailsRef.current?.empower_risk}{" "}
                            </h4>
                          </Col>

                          <Col xl={3} md={4} xs={6}>
                            <h5 className="fs-14 mb-1">Cancer Type</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetailsRef.current?.cancertype === null ||
                              taskDetailsRef.current?.cancertype === ""
                                ? "-"
                                : taskDetailsRef.current?.cancertype}{" "}
                            </h4>
                          </Col>

                          <Col xl={3} md={4} xs={6}>
                            <h5 className="fs-14 mb-1">Cancer Stage</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetailsRef.current?.stage_cancerstage ===
                                null ||
                              taskDetailsRef.current?.stage_cancerstage === ""
                                ? "-"
                                : taskDetailsRef.current
                                    ?.stage_cancerstage}{" "}
                            </h4>
                          </Col>

                          <Col xl={3} md={4} xs={6}>
                            <h5 className="fs-14 mb-1">Organt Type</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetailsRef.current?.organ_type === null ||
                              taskDetailsRef.current?.organ_type === ""
                                ? "-"
                                : taskDetailsRef.current?.organ_type}{" "}
                            </h4>
                          </Col>

                          <Col xl={3} md={4} xs={6}>
                            <h5 className="fs-14 mb-1">Positive Policy</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetailsRef.current?.positive_policy ===
                                null ||
                              taskDetailsRef.current?.positive_policy === ""
                                ? "-"
                                : taskDetailsRef.current?.positive_policy}{" "}
                            </h4>
                          </Col>

                          <Col xl={3} md={4} xs={6}>
                            <h5 className="fs-14 mb-1">AMD ICD Codes</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetailsRef.current?.amd_icd_codes === null ||
                              taskDetailsRef.current?.amd_icd_codes === ""
                                ? "-"
                                : taskDetailsRef.current?.amd_icd_codes}{" "}
                            </h4>
                          </Col>

                          <Col xl={3} md={4} xs={6}>
                            <h5 className="fs-14 mb-1">ZCode</h5>
                            <h4 className="fw-bold text-dark fs-14">
                              {taskDetailsRef.current?.zcode === null ||
                              taskDetailsRef.current?.zcode === ""
                                ? "-"
                                : taskDetailsRef.current?.zcode}{" "}
                            </h4>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>

                    <Card className="card-one h-auto">
                      <Card.Body>
                        {/* PRIOR AUTHORIZATION COMPONENT */}
                        <div>
                          <h3 className="fs-14 fw-semibold mb-4">
                            Prior Authorization
                          </h3>
                          <PriorAuthorization
                            taskDetails={taskDetailsRef.current}
                          />
                        </div>

                        {/* INSURANCE INFORMATION COMPONENT */}
                        <div>
                          <h3 className="fs-14 fw-semibold mb-4">
                            Insurance Information
                          </h3>
                          <InsuranceInformation
                            taskDetails={taskDetailsRef.current}
                          />
                        </div>

                        {/* LAB/REFERRING PROVIDER INFORMATION */}
                        <div>
                          <h3 className="fs-14 fw-semibold mb-4">
                            LAB/Referring Provider Information
                          </h3>
                          <LabReferringProviderInfo
                            taskDetails={taskDetailsRef.current}
                          />
                        </div>

                        {/* DENIAL/CORERESPONDENCE */}
                        <div>
                          <h3 className="fs-14 fw-semibold mb-4">
                            Denial/Corerespondence
                          </h3>
                          <DebialCorerespondence
                            taskDetails={taskDetailsRef.current}
                          />
                        </div>

                        {/* CLAIM INFORMATION COMPONENT */}
                        <div>
                          <h3 className="fs-14 fw-semibold mb-4">
                            Claim Information
                          </h3>
                          <ClaimInformation
                            taskDetails={taskDetailsRef.current}
                          />
                        </div>

                        <hr />
                        <DocumentUpload
                          setShowUploadDocModal={setShowUploadDocModal}
                          showUploadDocModal={showUploadDocModal}
                          taskDetails={taskDetailsRef.current}
                        />
                      </Card.Body>
                    </Card>
                  </div>
                </Card>
              </Col>
              <Col md={5} sm={12} className="position-relative">
                {loading.isLoading &&
                loading.value === "getDispositionDetails" ? (
                  <div className="middle-loader">
                    <LoadingSpinner
                      color="#506fd9"
                      size={30}
                      type={"TailSpin"}
                    />
                  </div>
                ) : (
                  <>
                    <Card className="card-one case-details-card">
                      <Card.Body className="h-100 p-0">
                        {/* <CaseDisposition
                      setShowTasksDetails={props.setShowTasksDetails}
                      setPendingTasks={props?.setPendingTasks}
                      taskDetails={taskDetails}
                      setTeamLeadQueries={props?.setTeamLeadQueries}
                      setTaskSummary={props?.setTaskSummary}
                      setParkedTasks={props?.setParkedTasks}
                      setMyTask={props?.setMyTask}
                    /> */}
                        <CaseDispositionDynamic
                          nodes={nodes}
                          edges={edges}
                          dynamicMapping={dynamicMapping}
                          nodeData={nodeData}
                          toggleStates={toggleStates}
                          setToggleStates={setToggleStates}
                          formData={formData}
                          setFormData={setFormData}
                          dropdownSelections={dropdownSelections}
                          setDropdownSelections={setDropdownSelections}
                          multiDropdownSelections={multiDropdownSelections}
                          setMultiDropdownSelections={
                            setMultiDropdownSelections
                          }
                          decisionTreeList={props?.decisionTreeList}
                          taskDetailsRef={taskDetailsRef}
                          setPendingTasks={props?.setPendingTasks}
                          setTeamLeadQueries={props?.setTeamLeadQueries}
                          // setTaskSummary={props?.setTaskSummary}
                          setParkedTasks={props?.setParkedTasks}
                          setMyTask={props?.setMyTask}
                          resetForm={resetForm}
                          checkboxes={checkboxes}
                          setCheckboxes={setCheckboxes}
                          caseSubmittedDataToBeSetRef={
                            caseSubmittedDataToBeSetRef
                          }
                          amdFormSubmittedData={amdFormSubmittedData}
                          setAMDFormSubmittedData={setAMDFormSubmittedData}
                          getDispositionDetails={getDispositionDetails}
                          setDynamicMapping={setDynamicMapping}
                          setEdges={setEdges}
                          setNodes={setNodes}
                          setNodeData={setNodeData}
                          loading={loading}
                          setLoading={setLoading}
                        />
                      </Card.Body>
                    </Card>
                  </>
                )}
              </Col>
            </Row>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}
