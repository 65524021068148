import React, { useContext, useEffect, useMemo, useState } from "react";
import ToastifyService from "../../../_common/ToastifyService";
import AMDStatusFormModalDynamic from "./AMDStatusFormModalDynamic";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import SOPFileViewModalDynamic from "./SOPFileViewModalDynamic";
import AuthContext from "../../../../context/AuthProvider";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import LoadingSpinner from "../../../_common/LoadingSpinner";
import Commonfield from "../../../../infrastructure/core/Commonfield";

function CaseDispositionDynamic({
  nodes,
  edges,
  dynamicMapping,
  nodeData,

  formData,
  toggleStates,
  dropdownSelections,
  multiDropdownSelections,
  checkboxes,

  setCheckboxes,
  setFormData,
  setToggleStates,
  setDropdownSelections,
  setMultiDropdownSelections,

  loading,
  setLoading,

  decisionTreeList,
  taskDetailsRef,
  setPendingTasks,
  setTeamLeadQueries,
  setParkedTasks,
  setMyTask,
  resetForm,
  amdFormSubmittedData,
  setAMDFormSubmittedData,
  setNodes,
  setEdges,
  setNodeData,
  setDynamicMapping,
  caseSubmittedDataToBeSetRef,
  getDispositionDetails,
}) {
  const [dynamicNotesParagraph, setDynamicNotesParagraph] = useState("");
  const [showAMDStatusFormModal, setShowAMDStatusFormModal] = useState(false);
  // const [showSOPFileViewModal, setSOPFileViewModal] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const { auth } = useContext(AuthContext);
  const axiosPrivate = useAxiosPrivate();
  const [updatedformData, setUpdatedformData] = useState({});
  const [updatedToggleStates, setUpdatedToggleStates] = useState({});
  const [updatedDropdownSelections, setUpdatedDropdownSelections] = useState(
    {}
  );
  const [updatedMultiDropdownSelections, setUpdatedMultiDropdownSelections] =
    useState({});
  const [updatedCheckboxes, setUpdatedCheckboxes] = useState({});
  const [AMDFormData, setAMDFormData] = useState({});

  console.log("updatedformData", updatedformData);
  console.log("updatedToggleStates", updatedToggleStates);
  console.log("updatedDropdownSelections", updatedDropdownSelections);
  console.log("updatedMultiDropdownSelections", updatedMultiDropdownSelections);
  console.log("updatedCheckboxes", updatedCheckboxes);

  useEffect(() => {
    const initialToggleStates = [];
    const initialFormData = {};
    const initialDropdownSelections = {};
    const initialMultiSelections = {};
    nodes?.forEach((node) => {
      const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
      if (node.type === "00000000-0000-0000-0000-000000000001") {
        if (node.id === "node_0") {
          const statusDropdownData = dropdownSelections["node_0"];
          if (statusDropdownData) {
            setDropdownSelections((prev) => ({
              ...prev,
              node_0: {
                value: statusDropdownData.value,
                label: statusDropdownData.label,
              },
            }));
          } else {
            const initialData = node?.data?.componentValues?.[0];
            if (initialData) {
              initialDropdownSelections[node.id] = {
                value: initialData.id,
                label: initialData.name,
              };
              setDropdownSelections((prev) => ({
                ...prev,
                node_0: initialDropdownSelections[node.id],
              }));
            } else {
              console.warn("No component values available for node_0");
            }
          }
        }
      }

      if (nodeInData) {
        if (
          (node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f01" ||
            node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f02" ||
            node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f03" ||
            node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f04" ||
            node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f05" ||
            node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f06") &&
          nodeInData?.compValue !== null &&
          nodeInData?.compValue !== undefined
        ) {
          initialFormData[node.id] = nodeInData?.compValue;
          setFormData(initialFormData);
        } else if (node.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f02") {
          if (nodeInData?.compValue) {
            initialDropdownSelections[node.id] = node?.data?.componentValues
              ?.map((option) => ({
                value: option.id,
                label: option.name,
              }))
              .find(
                (cmpval) =>
                  cmpval.label.toLowerCase() ===
                  nodeInData?.compValue.toLowerCase()
              );
            setDropdownSelections((prev) => ({
              ...prev,
              ...initialDropdownSelections,
            }));
          }
        } else if (node.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f03") {
          if (nodeInData?.compValue) {
            const selectedValues = nodeInData?.compValue
              ?.split(",")
              ?.map((val) => val.trim().toLowerCase());
            initialMultiSelections[node.id] = node?.data?.componentValues
              ?.filter((option) =>
                selectedValues.includes(option.name.toLowerCase())
              )
              .map((option) => ({
                value: option.id,
                label: option.name,
              }));

            setMultiDropdownSelections((prev) => ({
              ...prev,
              ...initialMultiSelections,
            }));
          }
        } else if (node.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f05") {
          if (nodeInData?.compValue) {
            const componentOptions = node?.data?.componentValues?.map(
              (option) => ({
                value: option.id,
                label: option.name,
              })
            );
            const matchedOption = componentOptions?.find(
              (cmpval) =>
                cmpval?.label?.toLowerCase() ===
                nodeInData?.compValue?.toLowerCase()
            );
            if (matchedOption) {
              const index = componentOptions?.indexOf(matchedOption);
              initialToggleStates[node.id] = {
                ...matchedOption,
                boolVal: index === 0 ? true : false,
              };
              setToggleStates(initialToggleStates);
            }
          }
        } else if (node.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f04") {
          if (nodeInData?.compValue) {
            const selectedValues = nodeInData?.compValue
              ?.split(",")
              ?.map((val) => val.trim().toLowerCase());

            const selectedValuesJson = selectedValues.reduce((acc, value) => {
              acc[value] = true;
              return acc;
            }, {});

            const updatedSelection = {
              [node.id]: selectedValuesJson,
            };
            setCheckboxes(updatedSelection);
          }
        }
      }
    });
    setIsDataUpdated(true);
  }, [nodes, nodeData, edges, dynamicMapping]);

  const handleInputChange = (nodeId, value) => {
    const updatedFormData = { ...formData, [nodeId]: value };
    const childConnections = dynamicMapping[nodeId]?.connections || [];
    childConnections.forEach(({ target }) => {
      updatedFormData[target] = "";
    });
    setFormData(updatedFormData);
  };
  const resetStates = (callback) => {
    caseSubmittedDataToBeSetRef.current = {};
    setDynamicMapping([]);
    setEdges([]);
    setNodes([]);
    setNodeData([]);
    setToggleStates([]);
    setFormData([]);
    setDropdownSelections([]);
    setMultiDropdownSelections([]);
    setCheckboxes({});
    setAMDFormSubmittedData({});

    setTimeout(() => {
      callback();
    }, 0);
  };

  const handleStatusDropdownChange = (nodeId, selected) => {
    resetStates(() => {
      setDropdownSelections((prev) => ({
        ...prev,
        [nodeId]: selected,
      }));
      setTimeout(() => {
        getDispositionDetails(selected.value);
      }, 0);
    });
  };

  const handleDropdownChange = (nodeId, value) => {
    setDropdownSelections((prev) => ({
      ...prev,
      [nodeId]: value,
    }));
  };
  const handleMultiDropdownChange = (nodeId, value) => {
    setMultiDropdownSelections((prev) => ({
      ...prev,
      [nodeId]: value,
    }));
  };
  const handleToggleChange = (nodeId, isChecked) => {
    setToggleStates((prev) => ({
      ...prev,
      [nodeId]: isChecked,
    }));
  };
  const handleCheckboxChange = (event, label, nodeId) => {
    const { checked } = event.target;
    setCheckboxes((prev) => ({
      ...prev,
      [nodeId]: {
        ...prev[nodeId],
        [label]: checked,
      },
    }));
  };
  const renderNode = (node) => {
    const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
    const showAddButton = nodeInData && nodeInData?.addStatus;
    const addButton = (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            {amdFormSubmittedData?.nodeId === node.id
              ? amdFormSubmittedData?.fieldsWithValues ===
                amdFormSubmittedData?.totalFields
                ? "Saved"
                : amdFormSubmittedData?.fieldsWithValues === 0
                ? "Not Saved"
                : "Partially Saved"
              : "Not Saved"}
          </Tooltip>
        }
      >
        <Button
          variant="outline-primary"
          className="btn-sm"
          onClick={() => {
            setAMDFormData({ ...nodeInData, node });
            setShowAMDStatusFormModal(true);
          }}
        >
          Manage
        </Button>
      </OverlayTrigger>
    );
    const showInformation = nodeInData && nodeInData?.isSOPFile;
    const infoIcon = (
      <Button
        variant="outline-primary"
        className="btn-sm mx-3"
        onClick={() => {
          setAMDFormData({ ...nodeInData, node });
          // setSOPFileViewModal(true);
        }}
      >
        <i
          className="ri-information-line"
          style={{ cursor: "pointer", height: "70px" }}
        ></i>{" "}
        <span>Open SOP</span>
      </Button>
    );
    switch (node?.data?.componentTypeId) {
      case "4e0073a0-2338-4acf-bbd7-6b265bed7f05":
        return (
          <Row className="mb-3 align-items-center">
            <Col md={12}>
              <Row className="align-items-center mb-1">
                <Col md={10}>
                  <Form.Label className="mb-0">
                    {node?.data?.label}
                    {showInformation && infoIcon}
                  </Form.Label>
                </Col>
                <Col md={2}>{showAddButton && addButton}</Col>
              </Row>
            </Col>
            <Col>
              <ButtonGroup>
                {node?.data?.componentValues?.map((option, index) => (
                  <Button
                    key={`${option?.id}-${node?.id}`}
                    variant={
                      option?.name?.toLowerCase() ===
                      toggleStates[node.id]?.label?.toLowerCase()
                        ? "primary"
                        : "outline-primary"
                    }
                    onClick={(e) => {
                      const isFirstButtonClicked = index === 0;
                      handleToggleChange(node?.id, {
                        id: option?.id,
                        label: option.name,
                        boolVal: isFirstButtonClicked,
                      });
                    }}
                  >
                    <input
                      type="radio"
                      name={`toggle-${node.id}`}
                      className="btn-check"
                      autoComplete="off"
                      checked={
                        option?.name?.toLowerCase() ===
                        toggleStates[node.id]?.label?.toLowerCase()
                      }
                      readOnly
                    />
                    {option?.name}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
          </Row>
        );
      case "4e0073a0-2338-4acf-bbd7-6b265bed7f02":
        return (
          <Row className="mb-3 align-items-center">
            <Col md={12}>
              <Row className="align-items-center mb-1">
                <Col md={10}>
                  <Form.Label className="mb-0">
                    {node?.data?.label} {showInformation && infoIcon}
                  </Form.Label>
                </Col>
                <Col md={2}>{showAddButton && addButton}</Col>
              </Row>
            </Col>
            <Col>
              <Select
                onChange={(selected) => handleDropdownChange(node.id, selected)}
                isSearchable={true}
                options={node?.data?.componentValues?.map((option) => ({
                  value: option.id,
                  label: option.name,
                }))}
                name={`dropdown-${node.id}`}
                value={dropdownSelections[node.id] || null}
              />
            </Col>
          </Row>
        );
      case "00000000-0000-0000-0000-000000000001":
        return (
          <Row className="mb-3 align-items-center">
            <Col md={12}>
              <Row className="align-items-center mb-1">
                <Col md={10}>
                  <Form.Label className="mb-0">
                    Status {showInformation && infoIcon}
                  </Form.Label>
                </Col>
                <Col md={2}>{showAddButton && addButton}</Col>
              </Row>
            </Col>
            <Col>
              <Select
                isSearchable={true}
                options={decisionTreeList?.map((option) => ({
                  value: option.id,
                  label: option.dispositionName,
                }))}
                disabled={true}
                name={`dropdown-${node.id}`}
                value={dropdownSelections[node.id] || null}
                onChange={(selected) => {
                  handleStatusDropdownChange(node.id, selected);
                }}
              />
            </Col>
          </Row>
        );
      case "4e0073a0-2338-4acf-bbd7-6b265bed7f03":
        return (
          <Row className="mb-3 align-items-center">
            <Col md={12}>
              <Row className="align-items-center mb-1">
                <Col md={10}>
                  <Form.Label className="mb-0">
                    {node?.data?.label} {showInformation && infoIcon}
                  </Form.Label>
                </Col>
                <Col md={2}>{showAddButton && addButton}</Col>
              </Row>
            </Col>
            <Col>
              <Select
                options={node?.data?.componentValues?.map((option) => ({
                  value: option.id,
                  label: option.name,
                }))}
                isSearchable={true}
                isMulti={true}
                name={`multiselect-dropdown-${node.id}`}
                value={multiDropdownSelections[node.id] || []}
                onChange={(selected) =>
                  handleMultiDropdownChange(node.id, selected)
                }
              />
            </Col>
          </Row>
        );
      case "4e0073a0-2338-4acf-bbd7-6b265bed7f04":
        return (
          <Row className="mb-3 align-items-center">
            <Form>
              <Col md={12}>
                <Row className="align-items-center mb-2">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
              </Col>
              <Col>
                {node?.data?.componentValues?.map((type) => (
                  <div className="mb-" key={`${type.id}-${node.id}`}>
                    <Form.Check
                      name={`multiselect-checkbox-${node.id}`}
                      label={type.name}
                      checked={checkboxes[node.id]?.[type.name] || false}
                      onChange={(e) =>
                        handleCheckboxChange(e, type.name, node.id)
                      }
                      type="checkbox"
                    />
                  </div>
                ))}
              </Col>
            </Form>
          </Row>
        );
      case "4e0073a0-2338-4acf-bbd7-6b265bed7f01":
        switch (node?.data?.datatypeId) {
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f01":
            return (
              <Form.Group key={`${node?.data?.id}-${node.id}`} className="mb-3">
                <Row className="align-items-center mb-1">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="text"
                  value={formData[node.id] || ""}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                  placeholder={`Enter ${node?.data?.label}`}
                />
              </Form.Group>
            );
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f02":
            return (
              <Form.Group key={`${node?.data?.id}-${node.id}`} className="mb-3">
                <Row className="align-items-center mb-1">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="number"
                  value={formData[node.id] || 0}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                  placeholder={`Enter ${node?.data?.label}`}
                />
              </Form.Group>
            );
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f03":
            return (
              <Form.Group
                key={`${node?.data?.id}-${node?.id}`}
                className="mb-3"
              >
                <Row className="align-items-center mb-1">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="date"
                  value={formData[node.id] || ""}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                />
              </Form.Group>
            );
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f04":
            return (
              <Form.Group
                key={`${node?.data?.id}-${node?.id}`}
                className="mb-3"
              >
                <Row className="align-items-center mb-1">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="url"
                  value={formData[node.id] || ""}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                  placeholder={`Enter ${node?.data?.label}`}
                />
              </Form.Group>
            );
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f05":
            return (
              <Form.Group key={`${node?.data?.id}-${node.id}`} className="mb-3">
                <Row className="align-items-center mb-1">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="email"
                  value={formData[node.id] || ""}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                  placeholder={`Enter ${node?.data?.label}`}
                />
              </Form.Group>
            );
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f06":
            return (
              <Form.Group
                key={`${node?.data?.id}-${node?.id}`}
                className="mb-3"
              >
                <Row className="align-items-center mb-1">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="tel"
                  value={formData[node.id] || ""}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                  placeholder={`Enter ${node?.data?.label}`}
                />
              </Form.Group>
            );
          default:
            return null;
        }
      default:
        return null;
    }
  };
  const generateForm = useMemo(() => {
    let dynamicNotes = "";
    const renderedFields = [];
    const jsonBody = {};

    if (isDataUpdated) {
      const renderNodeWithChildren = (node) => {
        renderedFields.push(renderNode(node));

        const connectedEdges = edges?.filter((edge) => edge.source === node.id);
        connectedEdges?.forEach((edge) => {
          let shouldRenderChild = false;

          if (node.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f02") {
            if (dropdownSelections[node.id]?.label === edge?.label) {
              shouldRenderChild = true;
              const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
              if (nodeInData) {
                nodeInData.compValue = dropdownSelections[node.id]?.label;
              }
            }
          } else if (node.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f03") {
            if (multiDropdownSelections[node.id]?.[edge.label]) {
              shouldRenderChild = true;
            }
            const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
            if (nodeInData) {
              nodeInData.compValue = Object.keys(
                multiDropdownSelections[node.id] || {}
              )
                .filter((key) => multiDropdownSelections[node.id][key])
                .join(", ");
            }
          } else if (node?.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f05") {
            if (toggleStates[node.id]?.boolVal) {
              if (edge?.sourceHandle === "checked") {
                shouldRenderChild = true;
              }
            } else {
              if (edge?.sourceHandle === "unchecked") {
                shouldRenderChild = true;
              }
            }
            const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
            if (nodeInData) {
              nodeInData.compValue = toggleStates[node.id]?.label;
            }
          } else if (node?.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f04") {
            if (checkboxes[node.id]?.[edge.label]) {
              shouldRenderChild = true;
            }
            const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
            if (nodeInData) {
              nodeInData.compValue = Object.keys(checkboxes[node.id] || {})
                .filter((key) => checkboxes[node.id][key])
                .join(", ");
            }
          } else if (node?.type === "00000000-0000-0000-0000-000000000001") {
            if (node?.id === "node_0") {
              shouldRenderChild = true;
            }
            const nodeInData = nodeData?.find((n) => n.nodeId === node.id);
            if (nodeInData) {
              nodeInData.isParent = true;
            }
          }
          if (node.id === "node_0") {
            const targetNode = nodes?.find((n) => n.id === node.id);
            if (targetNode) {
              jsonBody["Status"] = targetNode?.data?.label;
            }
          }
          if (shouldRenderChild) {
            const targetNode = nodes?.find((n) => n.id === edge.target);
            if (targetNode) {
              const nodeInData = nodeData?.find(
                (n) => n.nodeId === targetNode.id
              );
              let shouldRenderChild1 = false;
              if (targetNode.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f03") {
                shouldRenderChild1 = true;
                if (nodeInData) {
                  nodeInData.compValue = multiDropdownSelections[targetNode.id]
                    .map((item) => item.label)
                    .join(", ");
                }
              } else if (
                [
                  "4e0073a0-2339-4acf-bbd7-6b265bed7f01",
                  "4e0073a0-2339-4acf-bbd7-6b265bed7f02",
                  "4e0073a0-2339-4acf-bbd7-6b265bed7f03",
                  "4e0073a0-2339-4acf-bbd7-6b265bed7f04",
                  "4e0073a0-2339-4acf-bbd7-6b265bed7f05",
                  "4e0073a0-2339-4acf-bbd7-6b265bed7f06",
                ].includes(targetNode.type)
              ) {
                shouldRenderChild1 = true;
                if (nodeInData) {
                  nodeInData.compValue = formData[targetNode.id];
                }
              } else if (
                targetNode.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f02"
              ) {
                shouldRenderChild1 = true;
                if (nodeInData) {
                  nodeInData.compValue =
                    dropdownSelections[targetNode.id]?.label;
                }
              } else if (
                targetNode?.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f05"
              ) {
                shouldRenderChild1 = true;
                if (nodeInData) {
                  nodeInData.compValue = toggleStates[targetNode.id]?.label;
                }
              } else if (
                targetNode?.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f04"
              ) {
                shouldRenderChild1 = true;
                if (nodeInData) {
                  nodeInData.compValue = Object.keys(
                    checkboxes[targetNode.id] || {}
                  )
                    .filter((key) => checkboxes[targetNode.id][key])
                    .join(", ");
                }
              }

              if (shouldRenderChild1) {
                if (
                  nodeInData &&
                  nodeInData.notes &&
                  nodeInData.compValue &&
                  typeof nodeInData.notes === "string" &&
                  nodeInData.notes.includes("{data}")
                ) {
                  if (
                    nodeInData?.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f04"
                  ) {
                    const checkboxStateForNode = checkboxes[nodeInData.nodeId];
                    if (checkboxStateForNode) {
                      setUpdatedCheckboxes((prev) => ({
                        ...prev,
                        [nodeInData.nodeId]: {
                          data: checkboxStateForNode,
                          componentId: nodeInData?.componentId,
                          excelHeader: nodeInData?.excelHeader,
                        },
                      }));
                    }
                  } else if (
                    nodeInData?.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f05"
                  ) {
                    const toggleStateForNode = toggleStates[nodeInData.nodeId];
                    if (toggleStateForNode) {
                      setUpdatedToggleStates((prev) => ({
                        ...prev,
                        [nodeInData.nodeId]: {
                          data: toggleStateForNode,
                          componentId: nodeInData?.componentId,
                          excelHeader: nodeInData?.excelHeader,
                        },
                      }));
                    }
                  } else if (
                    nodeInData?.type ===
                      "4e0073a0-2338-4acf-bbd7-6b265bed7f02" ||
                    nodeInData?.type === "00000000-0000-0000-0000-000000000001"
                  ) {
                    const dropdownStateForNode =
                      dropdownSelections[nodeInData.nodeId];
                    if (dropdownStateForNode) {
                      setUpdatedDropdownSelections((prev) => ({
                        ...prev,
                        [nodeInData.nodeId]: {
                          data: dropdownStateForNode,
                          componentId:
                            nodeInData?.type ===
                            "00000000-0000-0000-0000-000000000001"
                              ? "00000000-0000-0000-0000-000000000001"
                              : nodeInData?.componentId,
                          excelHeader: nodeInData?.excelHeader,
                        },
                      }));
                    }
                  } else if (
                    nodeInData?.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f03"
                  ) {
                    const multiDropdownStateForNode =
                      multiDropdownSelections[nodeInData.nodeId];
                    if (multiDropdownStateForNode) {
                      setUpdatedMultiDropdownSelections((prev) => ({
                        ...prev,
                        [nodeInData.nodeId]: {
                          data: multiDropdownStateForNode,
                          componentId: nodeInData.componentId,
                          excelHeader: nodeInData?.excelHeader,
                        },
                      }));
                    }
                  } else if (
                    nodeInData?.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f01"
                  ) {
                    const formDataStateForNode = formData[nodeInData.nodeId];
                    if (formDataStateForNode) {
                      setUpdatedformData((prev) => ({
                        ...prev,
                        [nodeInData.nodeId]: {
                          data: formDataStateForNode,
                          componentId: nodeInData.componentId,
                          excelHeader: nodeInData?.excelHeader,
                        },
                      }));
                    }
                  }
                  let updatedNote = nodeInData.notes.replace(
                    "{data}",
                    nodeInData.compValue || ""
                  );
                  jsonBody[targetNode?.data?.label] = nodeInData.compValue;
                  dynamicNotes += `${updatedNote} `;
                }
              } else {
                const nodeInData = nodeData?.find(
                  (n) => n.nodeId === targetNode.id
                );
                if (
                  nodeInData &&
                  nodeInData.notes &&
                  nodeInData.compValue &&
                  typeof nodeInData.notes === "string" &&
                  nodeInData.notes.includes("{data}")
                ) {
                  let updatedNote = nodeInData.notes.replace(
                    "{data}",
                    nodeInData.compValue || ""
                  );
                  jsonBody[targetNode?.data?.label] = nodeInData.compValue;
                  dynamicNotes += `${updatedNote} `;
                }
              }
              renderNodeWithChildren(targetNode);
            }
          }
        });
      };
      nodes.forEach((node) => {
        const isOrphanedNode = edges?.every(
          (edge) => edge.target !== node.id && edge.source !== node.id
        );
        const isParentNode = edges?.every((edge) => edge.target !== node.id);

        if (isParentNode || isOrphanedNode) {
          renderNodeWithChildren(node);
        }
      });
      return { renderedFields, dynamicNotes, jsonBody };
    }
  }, [
    edges,
    nodes,
    nodeData,
    dropdownSelections,
    toggleStates,
    checkboxes,
    multiDropdownSelections,
    formData,
    isDataUpdated,
    amdFormSubmittedData,
  ]);

  useEffect(() => {
    if (isDataUpdated) {
      setDynamicNotesParagraph(generateForm.dynamicNotes.trim());
    }
  }, [isDataUpdated, generateForm?.dynamicNotes]);

  const handleSubmitDisposition = async (status) => {
    setLoading({
      loading: true,
      value:
        status === Commonfield.inventoryStatus.worked
          ? "handleSubmitDisposition"
          : "handleSubmitDraft",
    });

    const { jsonBody } = generateForm;
    const { amdStatus, data } = amdFormSubmittedData;
    const statusDropdownData = dropdownSelections["node_0"];

    const formDataObj = {
      toggleStates: updatedToggleStates,
      formData: updatedformData,
      dropdownSelections: updatedDropdownSelections,
      multiDropdownSelections: updatedMultiDropdownSelections,
      checkboxes: updatedCheckboxes,
      amdFormSubmittedData: amdFormSubmittedData,
    };

    const formObj = {
      caseId: taskDetailsRef?.current?.id,
      visitId: taskDetailsRef?.current?.visitfid,
      dispositionBody: JSON.stringify(jsonBody),
      dispositionRemark: dynamicNotesParagraph,
      amdDispositionBody: JSON.stringify({ amdStatus, data }),
      modifiedBy: auth.id,
      caseDisposedBy: auth.id,
      caseSubmittedFormData: JSON.stringify(formDataObj),
      status: status,
      category: statusDropdownData.label,
    };
    try {
      const response = await axiosPrivate.post("Task/case-disposition", formObj);
      setPendingTasks(response.data.result.pendingTasks);
      setTeamLeadQueries(response?.data?.result?.tlQueries);
      setParkedTasks(response?.data?.result?.parkedTasks);
      setMyTask(response?.data?.result);
      ToastifyService.success("Case Disposed Successfully");
    } catch (error) {
      console.error(error);
      ToastifyService.error("Error submitting disposition");
      setLoading({
        loading: false,
        value:
          status === Commonfield.inventoryStatus.worked
            ? "handleSubmitDisposition"
            : "handleSubmitDraft",
      });
    } finally {
      setLoading({
        loading: false,
        value:
          status === Commonfield.inventoryStatus.worked
            ? "handleSubmitDisposition"
            : "handleSubmitDraft",
      });
      resetForm();
    }};
  return (
    <React.Fragment>
      {showAMDStatusFormModal && (
        <AMDStatusFormModalDynamic
          show={showAMDStatusFormModal}
          closeFunction={setShowAMDStatusFormModal}
          AMDFormData={AMDFormData}
          onSubmit={setAMDFormSubmittedData}
          amdFormSubmittedData={amdFormSubmittedData}
        />
      )}
      {/* {showSOPFileViewModal && (
        <SOPFileViewModalDynamic
          show={showSOPFileViewModal}
          closeFunction={setSOPFileViewModal}
          AMDFormData={AMDFormData}
        />
      )} */}
      <div
        className="overflow-y custom-scroll p-3"
        style={{ height: "calc(100vh - 330px)" }}
      >
        {generateForm?.renderedFields}
      </div>

      <div
        className="offcanvas-footer d-block position-absolute botton-0 h-auto"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="mb-4">
          <label for="exampleFormControlTextarea1" class="form-label">
            Automated Notes
          </label>
          <textarea
            style={{ borderColor: "#000" }}
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
            placeholder="Enter message here..."
            value={dynamicNotesParagraph}
            onChange={(e) => setDynamicNotesParagraph(e.target.value)}
          ></textarea>
        </div>
        <div className="text-right">
          {/* <Button variant="primary" type="submit" onClick={handleSubmitDisposition}>
            Submit
          </Button> */}

          <Button
            variant="success"
            type="submit"
            className="me-2"
            disabled={
              loading.loading && loading?.value === "handleSubmitDisposition"
            }
            onClick={() =>
              handleSubmitDisposition(Commonfield.inventoryStatus.worked)
            }
          >
            {loading.loading && loading?.value === "handleSubmitDisposition" ? (
              <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            variant="dark"
            type="submit"
            disabled={loading.loading && loading?.value === "handleSubmitDraft"}
            onClick={() =>
              handleSubmitDisposition(Commonfield.taskRuleStatus.draft)
            }
          >
            {loading.loading && loading?.value === "handleSubmitDraft" ? (
              <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
            ) : (
              "Save As Draft"
            )}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CaseDispositionDynamic;
