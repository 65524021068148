import React, { useState } from "react";
import { Button } from "react-bootstrap";
import AddCPTCode from "./add-cpt-code";

export default function CPTCode() {
    const [showCPTCode, setShowCPTCode] = useState(false);
    const handleCPTCode = (state) => {
        setShowCPTCode(state)
    }
    return (
        <React.Fragment>
            <AddCPTCode show={showCPTCode} closeFunction={handleCPTCode} />
            <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
                <div>
                    <h4 className="fs-16 main-title mb-1">CPT Code</h4>
                </div>

                <div className="d-flex gap-2 mt-3 mt-md-0">
                    <Button variant="primary" className="d-flex align-items-center gap-1" onClick={()=> handleCPTCode(true)}>
                        <i className="ri-add-line fs-18 lh-1"></i>
                        <span className="d-sm-inline">Add CPT Code</span>
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}