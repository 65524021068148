import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import CreateNewWorkflowField from "./CreateNewWorkflowField";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import ShimmerLoader from "../../../_common/ShimmerLoader";
import DataTable from "react-data-table-component";
import UpdateWorkflowField from "./UpdateWorkflowField";
import LoadingSpinner from "../../../_common/LoadingSpinner";

function WorkflowFields() {
  const axiosPrivate = useAxiosPrivate();
  const [addNewWorkflowField, setAddNewWorkflowField] = useState(false);
  const [workflowFieldList, setWorkflowFieldList] = useState([]);
  const [updateWorkflowField, setUpdateWorkflowField] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState({ isLoading: false, value: "" });
  const [workflowFieldDetail, setWorkflowFieldDetail] = useState({});

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      cell: (row) => <div>{row.title}</div>,
    },
    {
      name: "Created By",
      selector: (row) => row.createdByName,
      sortable: true,
      cell: (row) => <div>{row.createdByName}</div>,
    },
    {
      name: "Created On",
      selector: (row) => row.createdOn,
      sortable: true,
      cell: (row) => <div>{new Date(row?.createdOn).toLocaleString()}</div>,
    },
    {
      name: "Action",
      sortable: true,
      cell: (row) => (
        <div className="w-1">
          <div className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="button"
              className="btn-icon"
              onClick={() => {
                getFieldDetails(row.id);
              }}
              disabled={
                loading.isLoading &&
                loading.value === `getFieldDetails${row.id}`
              }
            >
              {loading.isLoading &&
              loading.value === `getFieldDetails${row.id}` ? (
                <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
              ) : (
                <i className="ri-pencil-line"></i>
              )}
            </Button>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getWorkflowFieldList();
  }, []);

  const filteredData = workflowFieldList?.filter((item) => {
    const createdOnFormatted = new Date(item?.createdOn).toLocaleString();
    return (
      item?.title?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.createdByName?.toLowerCase().includes(searchText.toLowerCase()) ||
      createdOnFormatted.includes(searchText)
    );
  });

  const getWorkflowFieldList = async () => {
    try {
      setLoading({ isLoading: true, value: "getWorkflowFieldList" });
      const workflowFieldListData = await axiosPrivate.get(
        `Setting/get-workflow-field-List`
      );
      setWorkflowFieldList(workflowFieldListData?.data);
    } catch (err) {
      setLoading({ isLoading: false, value: "getWorkflowFieldList" });
      console.error(err);
    } finally {
      setLoading({ isLoading: false, value: "getWorkflowFieldList" });
    }
  };
  const getFieldDetails = async (id) => {
    try {
      setLoading({ isLoading: true, value: `getFieldDetails${id}` });
      const workflowFieldDetail = await axiosPrivate.get(
        `Setting/get-workflow-field-details/${id}`
      );
      setWorkflowFieldDetail(workflowFieldDetail.data);
    } catch (err) {
      setLoading({ isLoading: false, value: `getFieldDetails${id}` });
      console.error(err);
    } finally {
      setLoading({ isLoading: false, value: `getFieldDetails${id}` });
      setUpdateWorkflowField(true);
    }
  };
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  return (
    <>
      <CreateNewWorkflowField
        show={addNewWorkflowField}
        setWorkflowFieldList={setWorkflowFieldList}
        closeFunction={setAddNewWorkflowField}
      />
      <UpdateWorkflowField
        show={updateWorkflowField}
        setWorkflowFieldList={setWorkflowFieldList}
        closeFunction={setUpdateWorkflowField}
        workflowFieldDetail={workflowFieldDetail}
      />
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-0">Workflow Fields</h4>
        </div>

        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-1"
            onClick={() => setAddNewWorkflowField(true)}
          >
            <i className="ri-add-line fs-18 lh-1"></i>
            <span className="d-sm-inline">Add Fields</span>
          </Button>
        </div>
      </div>

      <Card className="card-one">
        <Card.Header>
          <div className="d-flex">
            <Form.Control
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
              className="wt-250"
            />
          </div>
        </Card.Header>
        <Card.Body className="common-data-table">
          {loading.isLoading && loading.value === "getWorkflowFieldList" ? (
            <ShimmerLoader
              colNames={columns?.map((column) => column.name)}
              colsCount={1}
            />
          ) : (
            <DataTable columns={columns} data={filteredData} pagination />
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default WorkflowFields;
