import React, { useEffect, useState } from "react";
import Header from "../../../layouts/Header";
import { Table, Card } from "react-bootstrap";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";

export default function NonWorkableDashboard() {
  const axiosPrivate = useAxiosPrivate();
  const [nonWorkableDashobard, setnonWorkableDashobard] = useState([]);
  const { auth } = useAuth();
  useEffect(() => {
    getNonWorkableDashobard();
  }, []);
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const getNonWorkableDashobard = async () => {
    try {
      setLoading({ isLoading: true, value: "getNonWorkableDashobard" });
      const weeklyDashobardData = await axiosPrivate.get(
        `Dashboard/non-workable`
      );
      setnonWorkableDashobard(weeklyDashobardData?.data?.result);
    } catch (err) {
      console.error(err);
      setLoading({ isLoading: false, value: "getDailyDashobardData" });
    } finally {
      setLoading({ isLoading: false, value: "getDailyDashobardData" });
    }
  };
  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Non Workable Dashboard</h4>
          </div>
        </div>

        <Card className="card-one">
          {/* <Card.Header className="justify-content-end">
                        <Row>
                            <Col>
                                <Form.Label>Follow up date Bucket</Form.Label>
                                <Form.Select>
                                    <option>Choose Follow up date Bucket</option>
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label>Insurance balance From</Form.Label>
                                <Form.Control type="number" placeholder="Insurance balance From" />
                            </Col>
                            <Col>
                                <Form.Label>Insurance balance To</Form.Label>
                                <Form.Control type="number" placeholder="Insurance balance To" />
                            </Col>
                            <Col>
                                <Form.Label>LBD Bucket</Form.Label>
                                <Form.Control type="number" placeholder="LBD Bucket" />
                            </Col>
                            <Col>
                                <Form.Label>DOS Bucket</Form.Label>
                                <Form.Control type="number" placeholder="DOS Bucket" />
                            </Col>

                            <Col md={1}>
                               <Button variant="dark">Filter</Button>
                            </Col>
                        </Row>
                    </Card.Header> */}
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>Work Queue Type</th>
                  <th># Of claims</th>
                </tr>
              </thead>

              <tbody>
                {nonWorkableDashobard.map((nonWorkable, index) => (
                  <tr key={index}>
                    <td>{nonWorkable?.workQueueType}</td>
                    <td>{nonWorkable?.claimCount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
