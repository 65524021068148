import React, { useContext, useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Card, Table, Button, Badge, Form } from "react-bootstrap";
import RespondComponent from "./Respond/respond";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import DataTable from "react-data-table-component";
import ShimmerLoader from "../_common/ShimmerLoader";
import { TaskContext } from "../../layouts/TaskContext";
import { Link } from "react-router-dom";
import TasksDetails from "./TaskDetails";

export default function PendingQueries() {
  const axiosPrivate = useAxiosPrivate();
  
  const { auth } = useAuth();
  const [showRespondModal, setShowRespondModal] = useState(false);
  const [pendingQueries, setPendingQueries] = useState([]);
  const { setPendingQueriesLength } = useContext(TaskContext); 
  const [searchText, setSearchText] = useState("");
  const [pendingQuieresClosed, setPendingQuieresClosed] = useState({
    showPendingQueriesClosed: false,
    id: "",
    visitId: "",
    agentQuery: "",
  });
  const handleRespondModal = (state) => {
    setShowRespondModal(state);
  };
  useEffect(() => {
    getpendingQueriesl();
  }, []);
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "getpendingQueriesl",
  });
  useEffect(() => {
    setPendingQueriesLength(pendingQueries.length); // Update context value
  }, [pendingQueries, setPendingQueriesLength]);
  const getpendingQueriesl = async () => {
    try {
      setLoading({ isLoading: true, value: "getpendingQueriesl" });
      const pendingQueriesList = await axiosPrivate.get(
        `Task/pending-tl-query/${auth?.id}`
      );
      setPendingQueries(pendingQueriesList?.data?.result);
    } catch (err) {
      console.error(err);
      setLoading({ isLoading: false, value: "getpendingQueriesl" });
    } finally {
      setLoading({ isLoading: false, value: "getpendingQueriesl" });
    }
  };
  const [showTasksDetails, setShowTasksDetails] = useState({
    showTaskDetails: false,
    taskId: "",
  });
  const columns = [
    {
      name: "Visit ID",
      selector: (row) => row?.visitId,
      sortable: true,
      cell: (row) => (
        <Link
          className="fw-semibold"
          onClick={() =>
            setShowTasksDetails({
              showTaskDetails: true,
              taskId: row.taskId,
            })
          }
        >
          {row?.visitId}
        </Link>
      )
    },
    {
      name: "Patient Name",
      selector: (row) => row?.patientName,
      sortable: true,
      cell: (row) => (
        <div>
          {" "}
          <h5 className="fs-14">{row?.patientName}</h5>
        </div>
      ),
    },
    {
      name: "Insurance Balance",
      selector: (row) => row?.insuranceBalance,
      sortable: true,
      cell: (row) => <div>{row?.insuranceBalance}</div>,
    },
    {
      name: "Agent Query",
      selector: (row) => row?.agentQuery,
      sortable: true,
      cell: (row) => <div>{row?.agentQuery}</div>,
    },
    // {
    //   name: "Task Bucket",
    //   selector: (row) => row?.taskBucketName,
    //   sortable: true,
    //   cell: (row) => (
    //     <div>
    //       {" "}
    //       <Badge className="badge-pill" bg="primary">
    //         {row?.taskBucketName}
    //       </Badge>
    //     </div>
    //   ),
    // },
    // {
    //   name: "Priority",
    //   selector: (row) => row?.taskPriority,
    //   sortable: true,
    //   cell: (row) => <div className="fw-bold">{row?.taskPriority}</div>,
    // },
    {
      name: "Action",
      sortable: true,
      cell: (row) => (
        <div className="w-1">
          <div className="d-flex justify-content-end">
            <Button
              variant="danger"
              className="d-flex align-items-center justify-content-center gap-1"
              onClick={() =>
                setPendingQuieresClosed({
                  showPendingQueriesClosed: true,
                  id: row.id,
                  visitId: row.visitId,
                  agentQuery: row.agentQuery,
                })
              }
            >
              <i className="ri-edit-line"></i>
              <span className="d-sm-inline">Respond</span>
            </Button>
          </div>
        </div>
      ),
    },
  ];
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  const filteredData = pendingQueries.filter((item) => {
    return (
      item?.visitId?.toString().includes(searchText.toLowerCase()) ||
      item?.patientName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.insuranceBalance?.toString().includes(searchText.toLowerCase()) ||
      item?.agentQuery?.toLowerCase().includes(searchText.toLowerCase()) 
      // item?.taskBucketName?.toLowerCase().includes(searchText.toLowerCase()) ||
      // item?.taskPriority?.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  return (
    <React.Fragment>
      <Header />
      <TasksDetails
        show={showTasksDetails.showTaskDetails}
        setShowTasksDetails={setShowTasksDetails}
        taskId={showTasksDetails.taskId}
      />
      <RespondComponent
        show={pendingQuieresClosed.showPendingQueriesClosed}
        closeFunction={setPendingQuieresClosed}
        id={pendingQuieresClosed?.id}
        visitId={pendingQuieresClosed?.visitId}
        agentQuery={pendingQuieresClosed?.agentQuery}
        setPendingQueries={setPendingQueries}
      />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Pending Queries</h4>
          </div>
        </div>

        <Card className="card-one">
          <Card.Header>
            <div className="d-flex my-3">
              <Form.Control
                value={searchText}
                onChange={handleSearch}
                placeholder="Search..."
                className="wt-250"
              />
            </div>
          </Card.Header>
          <Card.Body className="common-data-table">
            {loading.isLoading && loading.value === "getpendingQueriesl" ? (
              <ShimmerLoader
                colNames={columns?.map((column) => column.name)}
                colsCount={1}
              />
            ) : (
              <DataTable columns={columns} data={filteredData} pagination />
            )}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
