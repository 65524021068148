import React, {
  useCallback,
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
  useRef,
} from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from "reactflow";
import "reactflow/dist/style.css";
import TextBoxNode from "./Nodes/TextBoxNode";
import DatePickerNode from "./Nodes/DatePickerNode";
import DropdownNode from "./Nodes/DropdownNode";
import ToggleNode from "./Nodes/ToggleNode";
import { Button } from "react-bootstrap";
import NumberNode from "./Nodes/NumberNode";
import WebsiteNode from "./Nodes/WebsiteNode";
import EmailNode from "./Nodes/EmailNode";
import TelNode from "./Nodes/TelNode";
import MultiSelectDropdownNode from "./Nodes/MultiSelectDropdownNode";
import CheckboxNode from "./Nodes/CheckboxNode";
import Header from "../../layouts/Header";
import ToastifyService from "../_common/ToastifyService";
import NodeForm from "./DecisionTreeComponents/NodeForm";
import PreviewForm from "./DecisionTreeComponents/PreviewForm";
import NodeSidebar from "./DecisionTreeComponents/NodeSidebar";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../_common/LoadingSpinner";
import { TailSpin } from "react-loader-spinner";
import StatusNode from "./Nodes/StatusNode";
import axios from "axios";
import { COMMON_SERVICE } from "../../api/axios";

const CreateDecisionTree = () => {
  const nodeTypes = useMemo(
    () => ({
      "4e0073a0-2339-4acf-bbd7-6b265bed7f01": TextBoxNode,
      "4e0073a0-2339-4acf-bbd7-6b265bed7f02": NumberNode,
      "4e0073a0-2338-4acf-bbd7-6b265bed7f02": DropdownNode,
      "4e0073a0-2339-4acf-bbd7-6b265bed7f03": DatePickerNode,
      "4e0073a0-2339-4acf-bbd7-6b265bed7f04": WebsiteNode,
      "4e0073a0-2339-4acf-bbd7-6b265bed7f05": EmailNode,
      "4e0073a0-2338-4acf-bbd7-6b265bed7f05": ToggleNode,
      "4e0073a0-2339-4acf-bbd7-6b265bed7f06": TelNode,
      "4e0073a0-2338-4acf-bbd7-6b265bed7f03": MultiSelectDropdownNode,
      "4e0073a0-2338-4acf-bbd7-6b265bed7f04": CheckboxNode,
      "00000000-0000-0000-0000-000000000001": StatusNode,
    }),
    []
  );
  const axiosPrivate = useAxiosPrivate();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const getId = () => {
    let id;
    let exists = true;
    let counter = nodes.length;
    while (exists) {
      id = `node_${counter}`;
      exists = nodes.some((node) => node.id === id);
      counter++;
    }
    return id;
  };
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [nodeData, setNodeData] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [dropdownSelections] = useState({});
  const [toggleStates] = useState({});
  const [dynamicMapping, setDynamicMapping] = useState({});
  const previewModalRef = useRef(null);
  const [showPreviewForm, setShowPreviewForm] = useState(false);
  const [deleteSOPFilesArr, setDeleteSOPFilesArr] = useState([]);
  const [loading, setLoading] = useState({ isLoading: false, value: "" });
  const navigate = useNavigate();
  const { id } = useParams();
  const [workflowComponentList, setWorkflowComponentList] = useState([]);
  useEffect(() => {
    if (id && workflowComponentList.length !== 0) {
      getDispositionDetails(id);
    }
  }, [workflowComponentList.length !== 0]);
  const getDispositionDetails = async (id) => {
    try {
      setLoading({ isLoading: true, value: "getDispositionDetails" });
      const dispositionDetailsResponse = await axiosPrivate.get(
        `Setting/get-disposition-details/${id}`
      );
      const dispositionDetails = dispositionDetailsResponse.data;
      const nodeSet = new Set(nodes.map((node) => node.id));
      if (!nodeSet.has("node_0")) {
        const dispositionNameNode = {
          id: "node_0",
          type: "00000000-0000-0000-0000-000000000001",
          position: { x: 100, y: 100 },
          data: {
            id: dispositionDetails.disposition.id,
            label: dispositionDetails.disposition.dispositionName,
            componentValues: [
              {
                id: dispositionDetails.disposition.id,
                name: dispositionDetails.disposition.dispositionName,
              },
            ],
            componentTypeId: "00000000-0000-0000-0000-000000000001",
          },
          width: 200,
          height: 50,
        };
        setNodes((prevNodes) => [...prevNodes, dispositionNameNode]);
        nodeSet.add("node_0");
      }
      const updatedNodes = dispositionDetails?.dispositionNodes?.reduce(
        (acc, detail) => {
          const componentData = workflowComponentList.find(
            (component) => component.id === detail.componentId
          );
          if (!componentData) {
            return acc;
          }
          const nodeData = {
            id: detail.nodeId,
            type:
              componentData.componentTypeId ===
              "4e0073a0-2338-4acf-bbd7-6b265bed7f01"
                ? componentData.datatypeId
                : componentData.componentTypeId,
            position: {
              x: detail.position_X,
              y: detail.position_Y,
            },
            data: {
              label: componentData.label,
              options: componentData.options || [],
              componentTypeId: componentData.componentTypeId,
              datatypeId:
                componentData.datatypeId ||
                "00000000-0000-0000-0000-000000000000",
              excelHeader: componentData.excelHeader || "",
              notes: componentData.notes || "",
              componentTypeName: componentData.componentTypeName || "",
              datatypeName: componentData.datatypeName || null,
              statusName: componentData.statusName || "Active",
              createdByName: componentData.createdByName || "Unknown",
              modifiedByName: componentData.modifiedByName || "Unknown",
              componentValues: componentData.componentValues || [],
              id: componentData.id,
              status: componentData.status || "",
              createdBy: componentData.createdBy || "",
              createdOn: componentData.createdOn || new Date().toISOString(),
              modifiedBy: componentData.modifiedBy || "",
              modifiedOn: componentData.modifiedOn || new Date().toISOString(),
            },
            width: componentData.width || 200,
            height: detail.height,
          };

          if (!nodeSet.has(nodeData.id)) {
            nodeSet.add(nodeData.id);
            acc.push(nodeData);
          }

          return acc;
        },
        []
      );
      setNodes((prevNodes) => [...prevNodes, ...updatedNodes]);
      setDynamicMapping(dispositionDetails?.dispositionConnections);
      const edgeSet = new Set();
      const formattedEdges = dispositionDetails?.dispositionEdges?.reduce(
        (acc, edge) => {
          const edgeData = {
            id: edge.edgeId,
            source: edge.source,
            target: edge.target,
            sourceHandle: edge.sourceHandle,
            label: edge.label,
          };
          edgeSet.add(edgeData.id);
          acc.push(edgeData);
          return acc;
        },
        []
      );

      setEdges(formattedEdges);
      const updatedNodesData = Object.values(
        dispositionDetails?.dispositionNodes?.reduce((acc, detail) => {
          const componentData = workflowComponentList.find(
            (component) => component.id === detail.componentId
          );
          if (!componentData) {
            return acc;
          }
          if (!acc[detail.nodeId]) {
            acc[detail.nodeId] = {
              nodeId: detail.nodeId,
              label: componentData.label || "Default Label",
              isRequired: detail.isRequired,
              addStatus: detail.addStatus,
              isSOPFile: detail.isSOPFile,
              IsFileUploaded: detail.isFileUploaded,
              FileName: detail.fileName,
              amdStatus: detail.amdStatusId,
              SOPFile: null,
            };
          } else {
            acc[detail.nodeId].isRequired =
              acc[detail.nodeId].isRequired || detail.isRequired;
            acc[detail.nodeId].addStatus =
              acc[detail.nodeId].addStatus || detail.addStatus;
            acc[detail.nodeId].isSOPFile =
              acc[detail.nodeId].isSOPFile || detail.isSOPFile;
            acc[detail.nodeId].IsFileUploaded =
              acc[detail.nodeId].isFileUploaded || detail.isFileUploaded;
            acc[detail.nodeId].FileName =
              acc[detail.nodeId].fileName || detail.fileName;
          }
          return acc;
        }, {})
      );

      setNodeData(updatedNodesData);
    } catch (err) {
      console.error(err);
      setLoading({ isLoading: false, value: "getDispositionDetails" });
    } finally {
      setLoading({ isLoading: false, value: "getDispositionDetails" });
    }
  };
  const onSelectionChange = useCallback(({ nodes }) => {
    if (nodes.length > 0) {
      setSelectedNode(nodes[0]);
    } else {
      setSelectedNode(null);
    }
  }, []);
  const handleFormSubmit = async (nodeId, formDataToSubmit) => {
    try {
      const formDataObj = {};
      formDataToSubmit.forEach((value, key) => {
        if (value === "true") {
          formDataObj[key] = true;
        } else if (value === "false") {
          formDataObj[key] = false;
        } else if (value === "null") {
          formDataObj[key] = null;
        } else {
          formDataObj[key] = value;
        }
      });
      setNodeData((prevData) => {
        const existingNode = prevData.find((node) => node.nodeId === nodeId);
        const updatedData = existingNode
          ? prevData.map((node) =>
              node.nodeId === nodeId ? { ...node, ...formDataObj } : node
            )
          : [...prevData, { nodeId, ...formDataObj }];
        return updatedData;
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      ToastifyService.error("Error saving the node details.");
    }
  };
  const createEdge = (params, label) => ({
    id: `e${params.source}-${params.target}`,
    source: params.source,
    target: params.target,
    sourceHandle: params.sourceHandle,
    label,
  });
  const onConnect = useCallback(
    (params) => {
      const sourceNode = nodes.find((node) => node.id === params.source);
      if (!sourceNode) {
        return;
      }
      let newEdge;
      if (sourceNode.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f02") {
        const selectedOption = params.sourceHandle.split("-")[2];
        newEdge = createEdge(params, selectedOption);
      } else if (sourceNode.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f05") {
        const isChecked = params.sourceHandle === "checked";
        const toggleValue = isChecked
          ? sourceNode.data.componentValues[0].name
          : sourceNode.data.componentValues[1].name;
        newEdge = createEdge(params, toggleValue);
      } else if (sourceNode.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f04") {
        const checkboxLabel = params.sourceHandle.split("-")[2];
        newEdge = createEdge(params, checkboxLabel);
      } else if (sourceNode.type === "00000000-0000-0000-0000-000000000001") {
        const textNodeLabel = params.sourceHandle.split("-")[2];
        newEdge = createEdge(params, textNodeLabel);
      }
      if (newEdge) {
        setEdges((eds) => addEdge(newEdge, eds));
      }
    },
    [setEdges, nodes, edges]
  );
  useEffect(() => {
    updateDynamicMapping();
  }, [nodes, edges, dropdownSelections, toggleStates]);
  const updateDynamicMapping = () => {
    try {
      if (!Array.isArray(nodes) || !Array.isArray(edges)) {
        return;
      }
      const mapping = nodes.reduce((acc, node) => {
        acc[node.id] = {
          componentId: node?.data?.id,
          type: node.type,
          connections: edges
            .filter((edge) => edge.source === node.id)
            .map((edge) => ({
              target: edge.target,
              condition: edge.label,
              componentValueId: node?.data?.componentValues?.find(
                (cv) => cv.name === edge.label
              )?.id,
            }))
            .filter((connection) =>
              nodes.some((node) => node.id === connection.target)
            ),
        };

        return acc;
      }, {});

      setDynamicMapping({ ...mapping });
    } catch (error) {
      console.error("Error updating dynamic mapping:", error);
    }
  };
  const onDrop = (event) => {
    event.preventDefault();
    const reactFlowBounds = event.currentTarget.getBoundingClientRect();
    let typeData;
    try {
      typeData = JSON.parse(
        event.dataTransfer.getData("application/reactflow")
      );
    } catch (error) {
      return;
    }
    const { label, datatypeId, componentTypeId, ...rest } = typeData;
    let nodeType =
      componentTypeId === "4e0073a0-2338-4acf-bbd7-6b265bed7f01"
        ? datatypeId
        : componentTypeId;
    if (!nodeType || !nodeTypes[nodeType]) {
      return;
    }
    const position = {
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    };
    const newNode = {
      id: getId(),
      type: nodeType,
      position,
      data: {
        label,
        options: [],
        componentTypeId,
        datatypeId,
        ...rest,
      },
    };
    setNodes((nds) => {
      const updatedNodes = nds.concat(newNode);
      updateDynamicMapping();
      return updatedNodes;
    });
    handlePreviewForm();
  };
  const handleSubmit = async () => {
    const dtoBody = {
      dispositionId: id,
      DispositionNodes: nodes.map((node) => {
        const nodeDataMatch = nodeData.find((data) => data.nodeId === node.id);
        return {
          NodeId: node.id,
          Position: node.position
            ? {
                x: Math.floor(node.position.x),
                y: Math.floor(node.position.y),
              }
            : {},
          PositionAbs: node.positionAbsolute
            ? {
                x: Math.floor(node.positionAbsolute.x),
                y: Math.floor(node.positionAbsolute.y),
              }
            : {},
          IsSOPFile: nodeDataMatch ? nodeDataMatch.isSOPFile : false,
          IsFileUploaded: nodeDataMatch
            ? nodeDataMatch.SOPFile instanceof File
              ? true
              : nodeDataMatch.isFileUploaded
            : false,
          FileName: nodeDataMatch
            ? nodeDataMatch.SOPFile instanceof File
              ? nodeDataMatch?.SOPFile?.name
              : nodeDataMatch?.fileName
              ? nodeDataMatch?.fileName
              : ""
            : "",
          AddStatus: nodeDataMatch ? nodeDataMatch.addStatus : false,
          IsRequired: nodeDataMatch ? nodeDataMatch.isRequired : false,
          Height: node.height || 0,
          ComponentId: node.data.id,
          AMDStatusId:
            nodeDataMatch !== undefined &&
            nodeDataMatch !== null &&
            nodeDataMatch !== "null"
              ? nodeDataMatch?.amdStatus !== "null" &&
                nodeDataMatch?.amdStatus !== null
                ? nodeDataMatch?.amdStatus
                : null
              : null,
        };
      }),
      DispositionNodesMapping: Object.keys(dynamicMapping).flatMap((nodeId) => {
        const connections = dynamicMapping[nodeId].connections;
        return connections.map((connection) => ({
          NodeId: nodeId,
          TargetId: connection.target,
          Condition: connection.componentValueId,
        }));
      }),
      DispositionEdges: edges.map((edge) => ({
        edgeId: edge.id,
        source: edge.source,
        target: edge.target,
        sourceHandle: edge.sourceHandle,
        label: edge.label,
      })),
    };
    try {
      setLoading({ isLoading: true, value: "createDispositionForm" });
      const dispositionCreated = await axiosPrivate.post(
        `Setting/edit-decision-tree`,
        dtoBody
      );

      // if (dispositionCreated.status === 200) {
      //   const createFilesArray = nodeData
      //     .filter(
      //       (noded) => noded.SOPFile instanceof File && !noded.IsFileUploaded
      //     )
      //     .map((fileData) => ({
      //       file: fileData.SOPFile,
      //       nodeId: fileData.nodeId,
      //       fileExtension: fileData.SOPFile.name.split(".").pop(),
      //     }));
      //   debugger;
      //   if (createFilesArray && createFilesArray.length > 0) {
      //     const formData = new FormData();
      //     createFilesArray.forEach((item) => {
      //       formData.append("Files", item.file);
      //       formData.append(
      //         "FileNames",
      //         `${item.nodeId}.${item.fileExtension}`
      //       );
      //     });
      //     formData.append("Location", `DecisionTree/SOP/${id}`);

      //     try {
      //       const response = await axios.post(
      //         `${COMMON_SERVICE}File/Upload/ARWorkflow`,
      //         formData,
      //         {
      //           headers: {
      //             "Content-Type": "multipart/form-data",
      //           },
      //         }
      //       );
      //     } catch (error) {
      //       console.error("Error uploading files:", error);
      //     }
      //   }
      //   const updateFilesArray = nodeData
      //     .filter(
      //       (noded) => noded.SOPFile instanceof File && noded.IsFileUploaded
      //     )
      //     .map((fileData) => ({
      //       file: fileData.SOPFile,
      //       nodeId: fileData.nodeId,
      //       fileExtension: fileData?.SOPFile?.name?.split(".").pop(),
      //       oldFileNameExt: fileData?.FileName?.split(".").pop(),
      //     }));
      //   debugger;
      //   if (updateFilesArray && updateFilesArray.length > 0) {
      //     const formData = new FormData();
      //     updateFilesArray.forEach((item) => {
      //       formData.append("Files", item.file);
      //       formData.append(
      //         "FileNames",
      //         `${item.nodeId}.${item.fileExtension}`
      //       );
      //       formData.append("OldFileNamesExt", `${item.oldFileNameExt}`);
      //     });
      //     formData.append("Location", `DecisionTree/SOP/${id}`);

      //     try {
      //       await axios.post(
      //         `${COMMON_SERVICE}File/update/ARWorkflow`,
      //         formData,
      //         {
      //           headers: {
      //             "Content-Type": "multipart/form-data",
      //           },
      //         }
      //       );
      //     } catch (error) {
      //       console.error("Error updated files:", error);
      //     }
      //     const updateFilesStringArray = updateFilesArray?.map(
      //       (fileData) => `${fileData.nodeId}.${fileData.oldFileNameExt}`
      //     );
      //     const unmatchedFiles = updateFilesStringArray?.filter(
      //       (file) => !deleteSOPFilesArr.includes(file)
      //     );
      //     debugger;
      //     if (unmatchedFiles && unmatchedFiles.length > 0) {
      //       const newformData = new FormData();
      //       newformData.append("Location", `DecisionTree/SOP/${id}`);
      //       newformData.append("FileNames", JSON.stringify(unmatchedFiles));

      //       await axios.post(
      //         `${COMMON_SERVICE}File/Delete/ARWorkflow`,
      //         newformData,
      //         {
      //           headers: {
      //             "Content-Type": "multipart/form-data",
      //           },
      //         }
      //       );
      //     }
      //   } else if (
      //     updateFilesArray &&
      //     updateFilesArray.length === 0 &&
      //     deleteSOPFilesArr &&
      //     deleteSOPFilesArr.length > 0
      //   ) {
      //     debugger;
      //     const formData = new FormData();
      //     formData.append("Location", `DecisionTree/SOP/${id}`);
      //     formData.append("FileNames", JSON.stringify(deleteSOPFilesArr));

      //     await axios.post(
      //       `${COMMON_SERVICE}File/Delete/ARWorkflow`,
      //       formData,
      //       {
      //         headers: {
      //           "Content-Type": "multipart/form-data",
      //         },
      //       }
      //     );
      //   }

      //   ToastifyService.success("Saved!");
      //   navigate("/decision-tree-creator/manage");
      // }
    } catch (err) {
      setLoading({ isLoading: false, value: "createDispositionForm" });
      console.error(err);
    } finally {
      setLoading({ isLoading: false, value: "createDispositionForm" });
    }
  };
  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };
  const deleteNode = (nodeId) => {
    setNodes((prevNodes) => {
      const updatedNodes = prevNodes.filter((node) => node.id !== nodeId);
      return updatedNodes;
    });
    setEdges((prevEdges) => {
      const updatedEdges = prevEdges.filter(
        (edge) => edge.source !== nodeId && edge.target !== nodeId
      );
      return updatedEdges;
    });

    setNodeData((prevData) => {
      const updatedData = prevData.filter((node) => node.nodeId !== nodeId);
      return updatedData;
    });
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Delete") {
        const selectedNode = document.querySelector(".selected");
        if (selectedNode) {
          const nodeId = selectedNode.getAttribute("data-id");
          deleteNode(nodeId);
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  useEffect(() => {
    if (dynamicMapping) {
      Object.keys(dynamicMapping)?.forEach((nodeId) => {
        const node = dynamicMapping[nodeId];
        if (nodeId !== "node_0") {
          const nodeElement = document.querySelector(`[data-id="${nodeId}"]`);
          if (nodeElement) {
            const isSelected = nodeElement.classList.contains("selected");
            if (isSelected) {
              nodeElement.style.borderColor = "black";
              return;
            }
            const hasConnections = node.connections.length > 0;
            const isTargetForOthers = Object.values(dynamicMapping).some(
              (otherNode) =>
                otherNode.connections.some((conn) => conn.target === nodeId)
            );
            const nodeElement1 = document.querySelector(
              `[data-id="${nodeId}"] div`
            );
            if (
              !hasConnections &&
              node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f01" &&
              node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f02" &&
              node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f03" &&
              node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f04" &&
              node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f05" &&
              node.type === "4e0073a0-2339-4acf-bbd7-6b265bed7f06" &&
              node.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f04" &&
              node.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f03"
            ) {
              nodeElement1.style.borderColor = "red";
            } else if (!isTargetForOthers) {
              nodeElement1.style.borderColor = "red";
            } else {
              nodeElement1.style.borderColor = "rgb(221, 221, 221)";
            }
          }
        }
      });
    }
  }, [dynamicMapping]);

  useLayoutEffect(() => {
    const handleResize = () => {};
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handlePreviewForm = (state) => {
    setShowPreviewForm(state);
  };
  const handleUpdateWorkflowComponentList = (updatedList) => {
    setWorkflowComponentList(updatedList);
  };
  return (
    <React.Fragment>
      <Header />
      <PreviewForm
        show={showPreviewForm}
        closeFunction={handlePreviewForm}
        nodes={nodes}
        edges={edges}
        dynamicMapping={dynamicMapping}
        ref={previewModalRef}
        nodeData={nodeData}
      />
      <div className="main main-app">
        <div className="nodes-flow" style={{ display: "flex" }}>
          <NodeSidebar onUpdate={handleUpdateWorkflowComponentList} />
          {loading.isLoading && loading.value === "getDispositionDetails" ? (
            <div className="spinner-loader-sidenav">
              <TailSpin color="#0d6efd" height={30} />
            </div>
          ) : (
            <>
              <div
                className="position-relative"
                style={{ flexGrow: 1 }}
                onDrop={onDrop}
                onDragOver={onDragOver}
              >
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  nodeTypes={nodeTypes}
                  style={{ width: "100%", height: "100%" }}
                  onSelectionChange={onSelectionChange}
                >
                  <MiniMap />
                  <Controls />
                  <Background />
                </ReactFlow>
                <div className="react-flow-footer">
                  <Button
                    className="me-3 w-15"
                    type="button"
                    variant="primary"
                    onClick={() => handlePreviewForm(true)}
                  >
                    Preview
                  </Button>

                  <Button
                    type="button"
                    variant="primary"
                    className="w-15"
                    onClick={handleSubmit}
                    disabled={
                      loading.isLoading &&
                      loading.value === "createDispositionForm"
                    }
                  >
                    {loading.isLoading &&
                    loading?.value === "createDispositionForm" ? (
                      <LoadingSpinner
                        color="#ffffff"
                        size={20}
                        type={"TailSpin"}
                      />
                    ) : (
                      <>
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Submit</span>
                      </>
                    )}
                  </Button>
                </div>
              </div>
              {selectedNode && selectedNode.id !== "node_0" && (
                <div
                  style={{
                    padding: "20px",
                    overflowY: "auto",
                    width: "400px",
                    border: "1px solid #ccc",
                    background: "#fff",
                  }}
                >
                  <NodeForm
                    selectedNodeData={selectedNode}
                    nodeDetailsArr={nodeData}
                    onFormSubmit={handleFormSubmit}
                    setDeleteSOPFilesArr={setDeleteSOPFilesArr}
                    deleteSOPFilesArr={deleteSOPFilesArr}
                  />

                  {/* <h4>Dynamic Mapping JSON</h4>
             <pre>{JSON.stringify(dynamicMapping, null, 2)}</pre> */}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default CreateDecisionTree;
