import React, { useState } from "react";
import { Button, Offcanvas, Form } from "react-bootstrap";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import Commonfield from "../../infrastructure/core/Commonfield";
import ToastifyService from "../_common/ToastifyService";
import LoadingSpinner from "../_common/LoadingSpinner";

export default function PayerClosedModal(props) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [remark, setRemark] = useState("");
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const handleSubmit = async () => {
    try {
      setLoading({
        isLoading: true,
        value: "payerClosed",
      });
      const PayerClosedModalList = await axiosPrivate.post(`Task/park-task`, {
        status: Commonfield.parkStatus.payerClosed,
        createdBy: auth?.id,
        visitId: props?.visitId,
        remark: remark,
      });
      props?.setTeamLeadQueries(PayerClosedModalList?.data?.result?.tlQueries);
      props?.setPendingTasks(PayerClosedModalList?.data?.result?.pendingTasks);
      props?.setParkedTasks(PayerClosedModalList?.data?.result?.parkedTasks);
      props?.setMyTask(PayerClosedModalList?.data?.result);
      props?.setShowTasksDetails({
        showTaskDetails: false,
        taskId: "",
      });
      ToastifyService.success("Visit has been parked for payer closed");
      props?.closeFunction({
        showBoolPayerClosed: false,
        taskId: "",
        visitId: "",
      });
    } catch (err) {
      setLoading({
        isLoading: false,
        value: "payerClosed",
      });
      console.log(err);
      ToastifyService.error("Oops! Something Went Wrong");
    } finally {
      setLoading({
        isLoading: false,
        value: "payerClosed",
      });
    }
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={() =>
          props?.closeFunction({
            showBoolPayerClosed: false,
            taskId: "",
            visitId: "",
          })
        }
        placement="end"
        className="w-30"
      >
        <Offcanvas.Header
          closeButton
          onClick={() =>
            props?.closeFunction({
              showBoolPayerClosed: false,
              taskId: "",
              visitId: "",
            })
          }
        >
          <Offcanvas.Title className="fs-16 text-dark">
            Payer Closed
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Form.Label>
            Remark <span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            placeholder="Enter Remark"
            onChange={(e) => setRemark(e.target.value)}
          />
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="button"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            disabled={loading.isLoading && loading?.value === "payerClosed"}
            onClick={handleSubmit}
          >
            {loading.isLoading && loading?.value === "payerClosed" ? (
              <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
            ) : (
              <>
                <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                <span className="align-middle">Submit</span>
              </>
            )}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
