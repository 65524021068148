import { useRef, useState, useEffect, React } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Col, Form, Row, Image } from "react-bootstrap";
import bg1 from "../../assets/img/login/bg.jpg";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import logo from "../../assets/img/logo/main-logo.png";
import LoadingSpinner from "../_common/LoadingSpinner";
// const LOGIN_URL = "http://40.76.109.65:704/api/Auth/login";
const BASE_URL = "https://argateway.cxengine.net/api/";
// const BASE_URL = "https://localhost:44387/api/";
//  const BASE_URL = "https://arworkflow.innovalglobal.com:802/api/";
export default function LoginComponent() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading({
        isLoading: true,
        value: "login",
      });
      const response = await axios.post(
        `${BASE_URL}Auth/login`,
        JSON.stringify({ username: user, password: pwd }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      sessionStorage.setItem("auth", JSON.stringify(response?.data));
      const accessToken = response?.data?.accessToken;
      const refreshToken = response?.data?.refreshToken;
      const roles = response?.data?.roles;
      const id = response?.data?.id;
      const isResetDefaultPass = response?.data?.isResetDefaultPass;
      const name = response?.data?.name;
      setAuth({
        user,
        pwd,
        roles,
        accessToken,
        refreshToken,
        id,
        isResetDefaultPass,
        name,
      });

      // navigate(from, { replace: true });
      if (response?.status === 200) {
        try {
          const andStatuscommonFieldResponse = await axios.get(
            `${BASE_URL}Setting/get-amdstatus-common-field-type`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          sessionStorage.setItem(
            "amdStatuscommonField",
            JSON.stringify(andStatuscommonFieldResponse?.data?.result)
          );

          if (isResetDefaultPass) {
            navigate("/account/reset-password");
          } else {
            if (response?.data?.roles[0] === "Employee") {
              try {
                setLoading({
                  isLoading: true,
                  value: "getWorkflowComponentList",
                });
                const workflowListData = await axios.get(
                  `${BASE_URL}Setting/get-workflow-component-List`,
                  {
                    headers: { Authorization: `Bearer ${accessToken}` },
                  }
                );
                sessionStorage.setItem(
                  "WorkflowComponentList",
                  JSON.stringify(workflowListData?.data)
                );
              } catch (err) {
                console.error(err);
                setLoading({
                  isLoading: false,
                  value: "getWorkflowComponentList",
                });
              } finally {
                setLoading({
                  isLoading: false,
                  value: "getWorkflowComponentList",
                });
                navigate("/MyTask/manage");
              }
            } else {
              navigate("/dashboard");
            }
          }
          setLoading({
            isLoading: false,
            value: "login",
          });
          const commonFieldResponse = await axios.get(
            `${BASE_URL}Setting/get-common-field-type`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          );
          sessionStorage.setItem(
            "commonField",
            JSON.stringify(commonFieldResponse?.data?.result)
          );
        } catch (error) {
          setLoading({
            isLoading: false,
            value: "login",
          });
          setUser("");
          setPwd("");
          if (response?.data?.roles[0] === "Employee") {
            navigate("/MyTask/manage");
          } else {
            navigate("/dashboard");
          }
          console.error("Error in API calls:", error);
        } finally {
          setUser("");
          setPwd("");
        }
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
      setLoading({
        isLoading: false,
        value: "login",
      });
    }
  };

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Card.Title>Login</Card.Title>
              <Card.Text className="text-dark">
                Welcome back! Please login to continue.
              </Card.Text>
            </Card.Header>
            <Card.Body>
              <Form>
                <div className="mb-4">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your Username"
                    name="username"
                    id="username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    required
                  />
                </div>
                <div className="mb-4">
                  <Form.Label className="d-flex justify-content-between">
                    Password
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    name="password"
                    placeholder="Enter your password"
                    type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                  />
                </div>
                <p
                  ref={errRef}
                  className={errMsg ? "errmsg" : "offscreen"}
                  aria-live="assertive"
                >
                  {errMsg}
                </p>
                <Button
                  type="button"
                  className="btn-sign"
                  disabled={
                    loading.isLoading &&
                    (loading?.value === "login" ||
                      loading?.value === "getWorkflowComponentList")
                  }
                  onClick={handleSubmit}
                >
                  {loading.isLoading &&
                  (loading?.value === "login" ||
                    loading?.value === "getWorkflowComponentList") ? (
                    <LoadingSpinner
                      color="#ffffff"
                      size={30}
                      type={"TailSpin"}
                    />
                  ) : (
                    "Let me in"
                  )}
                </Button>

                {/* <div className="text-end mt-1">
                  <Link to="/account/forgot-password" className="float-end">
                    Forgot password?
                  </Link>
                </div> */}
              </Form>
            </Card.Body>
            <Card.Footer>
              {/* <Link to="/account/signup">Create an Account</Link> */}
            </Card.Footer>
          </Card>
        </Col>
        <Col className="d-none d-lg-block position-relative">
          <Image src={bg1} className="h-100 w-100 opacity-25" alt="bg" />
          <div className="login-content-logo">
            <Image className="wt-200 mb-3" src={logo} />
          </div>

          <div className="login-content">
            <h2 className="text-white mb-2">Welcome to AR Claims Engine</h2>
            <h1 className="text-white">
              Streamline claims, accelerate payments, and maximize revenue
              effortlessly.
            </h1>
          </div>
        </Col>
      </Row>
    </div>
  );
}
