import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Modal, Form, Image } from "react-bootstrap";
import { axiosPrivate, COMMON_SERVICE } from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import { Link } from "react-router-dom";
import ToastifyService from "../../_common/ToastifyService";
import LoadingSpinner from "../../_common/LoadingSpinner";
import Commonfield from "../../../infrastructure/core/Commonfield";
import axios from "axios";

export default function DocumentUpload(props) {
  const { auth } = useAuth();
  const [documentList, setDocumentList] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [file, setFile] = useState(null);
  const [documentFormat, setDocumentFormat] = useState("");
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const caseDocId = useRef(Commonfield.defaultValue);
  const [pages, setPages] = useState("");
  const taskActionSts = JSON.parse(sessionStorage.getItem("commonField"))?.find(
    (wqty) => wqty.typeGroupCode === "DOCTYP"
  )?.fieldValues;
  const DOCFormat = JSON.parse(sessionStorage.getItem("commonField"))?.find(
    (wqty) => wqty.typeGroupCode === "DOCFOR"
  )?.fieldValues;

  useEffect(() => {
    setDocumentList(props?.taskDetails?.casedocumentuploads);
    caseDocId.current = Commonfield.defaultValue;
  }, [props?.taskDetails]);

  const handleDeleteFile = async (id, fileData) => {
    try {
      setLoading({
        isLoading: true,
        value: `Delete-${id}`,
      });
      const fileNames = [`${fileData.id}.${fileData.fileName.split(".").pop()}`];
      const location = `CaseDocs/${props?.taskDetails?.lims_casefile_id}`;
      if (!location) {
        throw new Error("Location is invalid or missing.");
      }
      const formData = new FormData();
      formData.append("Location", location); 
      formData.append("FileNames", JSON.stringify(fileNames)); 
      await axios.post(`${COMMON_SERVICE}File/Delete/ARWorkflow`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",  
        },
      });
      const response = await axiosPrivate.post(
        `Task/delete-case-doc/${id}/${props?.taskDetails?.id}`
      );
      setDocumentList(response?.data?.result);
      caseDocId.current = response?.data?.result?.generatedId;
      ToastifyService.success("Documents Deleted successfully");
    } catch (error) {
      console.error("Error while deleting the files:", error);
      ToastifyService.error("Failed to delete the documents");
    } finally {
      setLoading({
        isLoading: false,
        value: `Delete-${id}`,
      });
    }
  };
  const handleDownloadFile = async (fileData) => {
    try {
      const extension = fileData.fileName.split(".").pop();
      const data = new FormData();
      data.append(
        "Location",
        `CaseDocs/${props?.taskDetails?.lims_casefile_id}`
      );
      data.append("FileName", `${fileData.id}.${extension}`);
      const response = await axios.post(
        `${COMMON_SERVICE}File/Download/ARWorkflow`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileData.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
      ToastifyService.success("Document downloaded successfully");
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenFile = async (fileData) => {
    try {
      const extension = fileData.fileName.split(".").pop();
      const response = await axios.get(
        `${COMMON_SERVICE}File/Open/ARWorkflow`,
        {
          params: {
            Location: `CaseDocs/${props?.taskDetails?.lims_casefile_id}`,
            FileName: `${fileData.id}.${extension}`,
          },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const fileUrl = window.URL.createObjectURL(blob);
      window.open(fileUrl, "_blank");
      setTimeout(() => window.URL.revokeObjectURL(fileUrl), 60000);
    } catch (error) {
      console.error("Error opening file:", error);
      ToastifyService.error("Failed to open the document");
    }
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleUploadDoc = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("FileName", file.name);
    formData.append("File", file);
    formData.append("CaseId", props?.showUploadDocModal?.caseId);
    formData.append("VisitId", props?.showUploadDocModal?.visitId);
    formData.append("DocumentTypeId", documentType);
    formData.append("DocumentFormatId", documentFormat);
    formData.append("Pages", String(pages));
    formData.append("UploadedBy", auth.id);
    try {
      setLoading({
        isLoading: true,
        value: "UploadDoc",
      });

      const caseDocResponse = await axiosPrivate.post(
        "Task/upload-case-doc",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (!caseDocResponse?.data?.result?.generatedId) {
        throw new Error("Document upload failed to generate ID.");
      }
      const generatedId = caseDocResponse?.data?.result?.generatedId;
      const fileExtension = file.name.split(".").pop();
      const uploadFormData = new FormData();
      uploadFormData.append(
        "Location",
        `CaseDocs/${props?.taskDetails?.lims_casefile_id}`
      );
      uploadFormData.append("Files", file);
      uploadFormData.append("FileNames", `${generatedId}.${fileExtension}`);

      const uploadResponse = await axios.post(
        `${COMMON_SERVICE}File/Upload/ARWorkflow`,
        uploadFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (
        !uploadResponse?.data?.uploadedFiles ||
        uploadResponse?.data?.uploadedFiles === 0
      ) {
        throw new Error("File upload to ARWorkflow failed.");
      }
      setPages("");
      setDocumentList(caseDocResponse?.data?.result?.caseDocuments);
      closeModal();
      ToastifyService.success("Document uploaded and saved successfully");
    } catch (error) {
      console.error(error);
      ToastifyService.error("Oops! Something Went Wrong");
      setPages("");
    } finally {
      setLoading({
        isLoading: false,
        value: "UploadDoc",
      });
    }
  };

  const closeModal = () => {
    props?.setShowUploadDocModal(false);
    setDocumentType("");
    setDocumentFormat("");
  };
  return (
    <React.Fragment>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <h4 className="fs-16 main-title mb-0">Uploaded Documents</h4>
        </div>
      </div>

      <div className="table-responsive document-upload-table">
        <Table className="mb-0">
          <thead>
            <tr>
              <th>File Name</th>
              <th>Document Type</th>
              <th>Pages</th>
              <th>Visit #</th>
              <th>Uploaded On/By</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {documentList?.map((item, index) => (
              <tr key={index}>
                <td>
                  <Link
                    className="d-flex align-items-center"
                    onClick={() => handleOpenFile(item)}
                  >
                    <Image src={item.icon} className="wt-16" />
                    <h5 className="fs-14 ms-2">{item.fileName}</h5>
                  </Link>
                </td>
                <td>{item.documentTypeName}</td>
                <td>{item.pages}</td>
                <td>{item.visitId}</td>
                <td>
                  <h5 className="fs-14 mb-1">
                    {new Date(item.modifiedOn).toLocaleString()}
                  </h5>
                  <h6 className="fs-12">{item.modifiedByName}</h6>
                </td>
                <td className="w-0">
                  <div className="d-flex justify-content-end">
                    <Button
                      variant="outline-dark"
                      type="button"
                      className="btn-icon me-2"
                      onClick={() => handleDownloadFile(item)}
                    >
                      <i className="ri-download-line"></i>
                    </Button>
                    <Button
                      variant="danger"
                      type="button"
                      className="btn-icon"
                      disabled={
                        loading.isLoading &&
                        loading?.value === `Delete-${item.id}`
                      }
                      onClick={() => handleDeleteFile(item.id, item)}
                    >
                      {loading.isLoading &&
                      loading?.value === `Delete-${item.id}` ? (
                        <LoadingSpinner
                          color="#ffffff"
                          size={20}
                          type={"TailSpin"}
                        />
                      ) : (
                        <i className="ri-delete-bin-line"></i>
                      )}
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal
        centered
        show={props?.showUploadDocModal}
        onHide={() => closeModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-14">Upload Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <Form.Label>Document Format</Form.Label>
            <Form.Select
              value={documentFormat}
              onChange={(e) => {
                setDocumentFormat(e.target.value);
              }}
            >
              <option value="" disabled selected>
                Choose Document Format
              </option>
              {DOCFormat?.map((item, index) => (
                <option key={index} value={item.id}>
                  {item?.value}
                </option>
              ))}
            </Form.Select>
          </div>
          {documentFormat !== "" && (
            <>
              <div className="mb-3">
                <Form.Label>Document Type</Form.Label>
                <Form.Select onChange={(e) => setDocumentType(e.target.value)}>
                  <option value={Commonfield.defaultValue}>
                    {" "}
                    Select an option
                  </option>

                  {taskActionSts && taskActionSts.length > 0 ? (
                    taskActionSts.map((item, index) => (
                      <option key={index} value={item?.id}>
                        {item?.value}
                      </option>
                    ))
                  ) : (
                    <option selected> No options available</option>
                  )}
                </Form.Select>
              </div>
              <div className="mb-3">
                <Form.Label>Choose a File</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleFileChange}
                  accept={
                    documentFormat === "b163f695-4e3e-4aba-bdb5-946432b83a22"
                      ? "application/pdf"
                      : "image/jpeg"
                  }
                />
              </div>

              <div className="mb-3">
                <Form.Label>Pages</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Pages"
                  value={pages}
                  onChange={(e) => setPages(e.target.value)}
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleUploadDoc}
            disabled={loading.isLoading && loading?.value === "UploadDoc"}
          >
            {loading.isLoading && loading?.value === "UploadDoc" ? (
              <LoadingSpinner color="#ffffff" size={20} type={"TailSpin"} />
            ) : (
              "Upload"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
