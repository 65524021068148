import React, { useState } from "react";
import { Offcanvas, Form, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ExportExcel from "./excelExport";

export default function TestQueryTable(props) {
  const queryResponse = props?.queryResponse;
  const [searchText, setSearchText] = useState("");
  const [exportData, setExportData] = useState(null);

  const handleSearch = (event) => {
    setSearchText(event?.target?.value);
  };
  const filteredData = queryResponse?.filter((item) => {
    return (
      item?.visitID?.toString().includes(searchText.toLowerCase()) ||
      item?.referenceID?.toString().includes(searchText.toLowerCase()) ||
      item?.patientName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item?.chargeAmount?.toString().includes(searchText.toLowerCase()) ||
      item?.currentBalance?.toString().includes(searchText.toLowerCase()) ||
      item?.insuranceBalance?.toString().includes(searchText.toLowerCase()) ||
      new Date(item?.lastBillDate)
        .toLocaleString()
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
  });
  const columns = [
    {
      name: "Visit ID",
      selector: (row) => row.visitID,
      sortable: true,
      cell: (row) => <span>{row.visitID}</span>,
    },
    {
      name: "Ref. ID",
      selector: (row) => row?.referenceID,
      sortable: true,
      cell: (row) => <span>{row.referenceID}</span>,
    },
    {
      name: "Patient Name",
      selector: (row) => row.patientName,
      sortable: true,
      cell: (row) => <span>{row.patientName}</span>,
    },
    {
      name: "Current Balance",
      selector: (row) => row.currentBalance,
      sortable: true,
      cell: (row) => <span>{row.currentBalance}</span>,
    },
    {
      name: "Insurance Balance",
      selector: (row) => row.insuranceBalance,
      sortable: true,
      cell: (row) => <span>{row.insuranceBalance}</span>,
    },
    {
      name: "Last bill date",
      selector: (row) => new Date(row.lastBillDate).toLocaleString(),
      sortable: true,
      cell: (row) => <span>{new Date(row.lastBillDate).toLocaleString()}</span>,
    },
    {
      name: "Last Billed payer",
      selector: (row) => row.lastBilledPayer,
      sortable: true,
      cell: (row) => <span>{row.lastBilledPayer}</span>,
    },
    {
      name: "Billed payer Govt/Comm",
      selector: (row) => row.lastBilledPayerGovtComm,
      sortable: true,
      cell: (row) => <span>{row.lastBilledPayerGovtComm}</span>,
    },
    {
      name: "Last Billed payer FC",
      selector: (row) => row.lastBilledPayerFC,
      sortable: true,
      cell: (row) => <span>{row.lastBilledPayerFC}</span>,
    },
    {
      name: "Test Type",
      selector: (row) => row.testType,
      sortable: true,
      cell: (row) => <span>{row.testType}</span>,
    },
    {
      name: "Test Sub Type",
      selector: (row) => row.testSubType,
      sortable: true,
      cell: (row) => <span>{row.testSubType}</span>,
    },
    {
      name: "Denial Category",
      selector: (row) => row.denialCategory,
      sortable: true,
      cell: (row) => <span>{row.denialCategory}</span>,
    },
    {
      name: "Last Status",
      selector: (row) => row.lastStatus,
      sortable: true,
      cell: (row) => <span>{row.lastStatus}</span>,
    },
    {
      name: "Last Action",
      selector: (row) => row.lastAction,
      sortable: true,
      cell: (row) => <span>{row.lastAction}</span>,
    },
    {
      name: "Last Action Mode",
      selector: (row) => row.lastActionMode,
      sortable: true,
      cell: (row) => <span>{row.lastActionMode}</span>,
    },
    {
      name: "Last Action Task Que",
      selector: (row) => row.lastActionTaskQue,
      sortable: true,
      cell: (row) => <span>{row.lastActionTaskQue}</span>,
    },
    {
      name: "F-Up Date Bucket",
      selector: (row) => row.followUpDateBucket,
      sortable: true,
      cell: (row) => <span>{row.followUpDateBucket}</span>,
    },
    {
      name: "Work Queue Type",
      selector: (row) => row.workQueueType,
      sortable: true,
      cell: (row) => <span>{row.workQueueType}</span>,
    },
    {
      name: "DOS Bucket",
      selector: (row) => row.dosBucket,
      sortable: true,
      cell: (row) => <span>{row.dosBucket}</span>,
    },
  ];
  const handleExportToExcel = () => {
    setExportData(queryResponse);
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={() =>
          props?.setShowTestQueryTable({ showBool: false, queryResponse: [] })
        }
        placement="end"
        className="w-60"
      >
        <Offcanvas.Header
          closeButton
          onClick={() =>
            props?.setShowTestQueryTable({ showBool: false, queryResponse: [] })
          }
        >
          <Offcanvas.Title className="fs-16 text-dark">
            Test Query List
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="common-data-table">
          <div className="d-flex justify-content-end my-3">
            <Button
              disabled={
                queryResponse?.length === 0 ? true : false
              }
              variant="primary"
              className="d-flex align-items-center justify-content-center gap-1"
              onClick={handleExportToExcel}
            >
              <i className="ri-add-line fs-18 lh-1"></i>
              <span className="d-sm-inline">Export</span>
            </Button>
            <ExportExcel
              excelData={exportData}
              fileName={"TestQueryList"}
              setExportData={setExportData}
            />
            <Form.Control
              value={searchText}
              onChange={handleSearch}
              placeholder="Search..."
              className="w-20 ms-3"
            />
          </div>
          <DataTable columns={columns} data={filteredData} pagination />
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}
