import React, { useState } from "react";
import "../Settings/system-setting.scss";
import Header from "../../layouts/Header";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReasonCodes from "./ReasonCodes/reason-codes";
import CPTCode from "./CPTCode/CPT-code";
import Insurance from "./Insurance/insurance";
import FinancialClass from "./FinancialClass/financial-class";
import Saag from "./SAAG/saag";

export default function MasterCatalogue() {
    const [isSidebarShow, setSidebarShow] = useState(false);

    return (
        <React.Fragment>
            <Header />
            <Tab.Container defaultActiveKey="reason_code_tab">
                <div className={"main main-file-manager" + (isSidebarShow ? " show" : "")}>
                    <PerfectScrollbar className="file-sidebar page-in-tabs">
                        <Nav variant="pills">
                            <Nav.Item><Nav.Link eventKey="reason_code_tab"><i className="ri-arrow-up-circle-line"></i> Reason Codes</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="cpt_code_tab"><i className="ri-user-follow-fill"></i> CPT Code</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="insurance_tab"><i className="ri-user-fill"></i> Insurance</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="financial_class_tab"><i className="ri-user-fill"></i> Financial Class</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="saag_tab"><i className="ri-user-fill"></i> SAAG</Nav.Link></Nav.Item>
                        </Nav>
                    </PerfectScrollbar>

                    <PerfectScrollbar className="file-content p-3 p-lg-4">
                        <Link href="" className="menu-file-manager" onClick={() => setSidebarShow(!isSidebarShow)}>
                            <i className="ri-arrow-left-line"></i>
                        </Link>
                        <Tab.Content>
                            {/* REASON CODE TAB */}
                            <Tab.Pane eventKey="reason_code_tab"><ReasonCodes /></Tab.Pane>
                            {/* CPT CODE TAB */}
                            <Tab.Pane eventKey="cpt_code_tab"><CPTCode /></Tab.Pane>
                            {/* INDURANCE TAB */}
                            <Tab.Pane eventKey="insurance_tab"><Insurance /></Tab.Pane>
                            {/* FINANCIAL CLASS TAB */}
                            <Tab.Pane eventKey="financial_class_tab"><FinancialClass /></Tab.Pane>
                            {/* SAAG TAB */}
                            <Tab.Pane eventKey="saag_tab"><Saag /></Tab.Pane>
                        </Tab.Content>
                    </PerfectScrollbar>
                </div>
            </Tab.Container>
        </React.Fragment>
    )
}