// src/contexts/TaskContext.js
import React, { createContext, useState } from 'react';

export const TaskContext = createContext();

export const TaskProvider = ({ children }) => {
  const [pendingTasksLength, setPendingTasksLength] = useState(0);
  const [pendingQueriesLength, setPendingQueriesLength] = useState(0);

  return (
    <TaskContext.Provider value={{ pendingTasksLength,pendingQueriesLength, setPendingTasksLength,setPendingQueriesLength }}>
      {children}
    </TaskContext.Provider>
  );
};
