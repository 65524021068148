
const dahsboardMenu = [
  {
    "label": "Dashboard",
    "link": "/dashboard",
    "icon": "ri-calendar-check-line"
  },
]

const reportsMenu = [
  {
    "label": "Task Bucket Dashboard",
    "link": "/reports/task-dashboard",
    "icon": "ri-calendar-check-line"
  },

  {
    "label": "Daily Production Dashboard",
    "link": "/reports/daily-prod-dashboard",
    "icon": "ri-calendar-line"
  },

  {
    "label": "Weekly Production Dashboard",
    "link": "/reports/weekly-prod-dashboard",
    "icon": "ri-calendar-2-line"
  },

  {
    "label": "Non Workable Dashboard",
    "link": "/reports/workable-dashboard",
    "icon": "ri-timer-line"
  },

  // {
  //   "label": "User-wise production report",
  //   "link": "/dashboard/user-wise-report",
  //   "icon": "ri-user-settings-line"
  // },
];

const myTaskMenu1 = [
  {
    "label": "My Tasks",
    "link": "/MyTask/manage",
    "icon": "ri-task-line",
    "count": 0
  },
]

const TaskMenu1 = [
  {
    "label": "Pending Queries",
    "link": "/pendingQueries/manage",
    "icon": "ri-task-line",
    "count": 1
  },
]

const MasterCatalogue = [
  {
    "label": "Inventory",
    "link": "/inventory/manage",
    "icon": "ri-survey-line",
  }
];

// const TaskEngineMenu = [
//   {
//     "label": "Task Engine",
//     "link": "/taskEngine/manage",
//     "icon": "ri-task-line",
//   }
// ]


const ProductionMenu = [
  {
    "label": "Production",
    "link": "/placements/manage",
    "icon": "ri-folder-settings-line",
  }
]
const DecisionTreeMenu = [
  {
    "label": "Decision Tree Creator",
    "link": "/decision-tree-creator/manage",
    "icon": "ri-node-tree",
  }
]

const SystemSettings = [
  {
    "label": "System Settings",
    "link": "/system-settings",
    "icon": "ri-tools-fill"
  },
  
]



export { dahsboardMenu, reportsMenu, myTaskMenu1, TaskMenu1, MasterCatalogue,  ProductionMenu, SystemSettings ,DecisionTreeMenu};
// TaskEngineMenu