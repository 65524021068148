import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import Main from "./layouts/Main";
import NotFound from "./View/_common/NotFound";
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import "./assets/css/remixicon.css";
import "./scss/style.scss";
import MyTask2 from "./View/MyTask/v2/manage";
import { TaskProvider } from "./layouts/TaskContext";
import 'reactflow/dist/style.css';

const ROLES = {
  TeamLead: "Team Lead",
  User: "User",
  Admin: "Admin",
  Employee: "Employee",
};

// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <TaskProvider>
          <Routes>
            <Route path="/" element={<Main />}>
              {/* public routes */}
              {/* <Route path="login" element={<LoginComponent />} />
        <Route path="account/register" element={<SignupComponent />} />        
        <Route path="unauthorized" element={<Forbidden />} /> */}

              {/* we want to protect these routes */}

              <Route element={<RequireAuth allowedRoles={[ROLES.TeamLead]} />}>
                {protectedRoutes.map((route, index) => {
                  return (
                    <Route
                      path={route.path}
                      element={route.element}
                      key={index}
                    />
                  );
                })}
              </Route>
              <Route element={<RequireAuth allowedRoles={[ROLES.Employee]} />}>
                {/* {protectedRoutes.map((route, index) => { */}
                {/* return ( */}
                <Route path={"MyTask/manage"} element={<MyTask2 />} key={1} />
                {/* ) */}
                {/* })} */}
              </Route>
            </Route>
            <Route>
              {publicRoutes.map((route, index) => {
                return (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={index}
                  />
                );
              })}
            </Route>
            {/* catch all */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </TaskProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}
