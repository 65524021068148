import React, { useEffect, useState } from "react";
import "../Settings/system-setting.scss";
import Header from "../../layouts/Header";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Nav, Tab, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import CreateNewRule from "./create-new-rule";
import PublishedComponent from "./Published/Published";
import DraftComponent from "./Draft/draft";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function RuleEngine() {
  const axiosPrivate = useAxiosPrivate();
  const [isSidebarShow, setSidebarShow] = useState(false);
  const [taskEnginPublishList, setTaskEnginePublishList] = useState([]);
  const [taskEnginDraftList, setTaskEngineDraftList] = useState([]);
  const [showCreateRule, setShowCreateRule] = useState(false);
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const handleCreateRule = (state) => {
    setShowCreateRule(state);
  };

  useEffect(() => {
    getTaskEnginePublishList();
    getTaskEngineDraftList();
  }, []);

  const getTaskEnginePublishList = async () => {
    try {
      setLoading({ isLoading: true, value: "getTaskEnginePublishList" });
      const taskEnginePublishListData = await axiosPrivate.get(
        `TaskEngine/published-task-buckets`
      );
      setTaskEnginePublishList(taskEnginePublishListData?.data?.result);
    } catch (err) {
      setLoading({ isLoading: false, value: "getTaskEnginePublishList" });
      console.error(err);
    } finally {
      setLoading({ isLoading: false, value: "getTaskEnginePublishList" });
    }
  };

  const getTaskEngineDraftList = async () => {
    try {
      setLoading({ isLoading: true, value: "getTaskEngineDraftList" });
      const taskEngineDraftListData = await axiosPrivate.get(
        `TaskEngine/draft-task-buckets`
      );
      setTaskEngineDraftList(taskEngineDraftListData?.data?.result);
    } catch (err) {
      setLoading({ isLoading: false, value: "getTaskEngineDraftList" });
      console.error(err);
    } finally {
      setLoading({ isLoading: false, value: "getTaskEngineDraftList" });
    }
  };

  return (
    <React.Fragment>
      <Header />
      <CreateNewRule
        show={showCreateRule}
        closeFunction={handleCreateRule}
        setTaskEnginePublishList={setTaskEnginePublishList}
        setTaskEngineDraftList={setTaskEngineDraftList}
      />
      <Tab.Container defaultActiveKey="published_tab">
        <div
          className={"main main-file-manager" + (isSidebarShow ? " show" : "")}
        >
          <PerfectScrollbar className="file-sidebar page-in-tabs">
            <div className="dropdown d-grid mb-3">
              <Button
                variant="primary"
                className="d-flex align-items-center justify-content-center gap-1"
                onClick={() => handleCreateRule(true)}
              >
                <i className="ri-add-line fs-18 lh-1"></i>
                <span className="d-sm-inline">Create New Rule</span>
              </Button>
            </div>
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="published_tab">
                  <i className="ri-wifi-line"></i> Published
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="draft_tab">
                  <i className="ri-wifi-off-fill"></i> Draft
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="activity_log_tab">
                  <i className="ri-article-line"></i> Activity Log
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
          </PerfectScrollbar>

          <PerfectScrollbar className="file-content p-3 p-lg-4">
            <Link
              href=""
              className="menu-file-manager"
              onClick={() => setSidebarShow(!isSidebarShow)}
            >
              <i className="ri-arrow-left-line"></i>
            </Link>
            <Tab.Content>
              {/* REASON CODE TAB */}
              <Tab.Pane eventKey="published_tab">
                <PublishedComponent
                  taskEnginPublishList={taskEnginPublishList}
                  setTaskEnginePublishList={setTaskEnginePublishList}
                  setTaskEngineDraftList={setTaskEngineDraftList}
                  loading={loading}
                />
              </Tab.Pane>
              {/* CPT CODE TAB */}
              <Tab.Pane eventKey="draft_tab">
                <DraftComponent
                  taskEnginDraftList={taskEnginDraftList}
                  setTaskEnginePublishList={setTaskEnginePublishList}
                  setTaskEngineDraftList={setTaskEngineDraftList}
                  loading={loading}
                />
              </Tab.Pane>
              {/* INDURANCE TAB */}
              {/* <Tab.Pane eventKey="activity_log_tab">Activity Log</Tab.Pane> */}
            </Tab.Content>
          </PerfectScrollbar>
        </div>
      </Tab.Container>
    </React.Fragment>
  );
}
