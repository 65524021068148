import React from "react";
// UI Elements
import ApexCharts from "../View/Chart/ApexCharts";
import ChartJs from "../View/Chart/ChartJs";

// WORKing PAGE ROUTING PAGE.
import TLDashboard from "../View/Dashboard/tl-dashboard";
import TaskBucketDashboard from "../View/Reports/TaskDashboard/task-dashboard";
import DailyProductionDashboard from "../View/Reports/DailyDashboard/daily-prod-dashboard";
import WeeklyProductionDashboard from "../View/Reports/WeeklyDashboard/weekly-prod-dashboard";
import NonWorkableDashboard from "../View/Reports/WorkableDashboard/workable-dashboard";
import UserWiseReport from "../View/Reports/UserReport/user-report";
import PendingQueries from "../View/PendingQueries/pending-queries";
import Inventory from "../View/Inventory/inventory";
import Placements from "../View/Placements/manage";
import RuleEngine from "../View/RuleEngine/rule-engine";
import MasterCatalogue from "../View/MasterCatalogue/master-catalogue";
import SystemSettings from "../View/Settings/system-setting";
import CreateDecisionTree from "../View/DecisionTreeCreator/CreateDecisionTree";
import DecisionTreeManage from "../View/DecisionTreeCreator/ManageDecisionTree";

const protectedRoutes = [
 
  { path: "docs/chart/apex", element: <ApexCharts /> },
  { path: "docs/chart/chartjs", element: <ChartJs /> },


  // DASHBOARD PAGES
  { path: "/", element: <TLDashboard />, },
  { path: "/dashboard", element: <TLDashboard />, },

  { path: "reports/task-dashboard", element: <TaskBucketDashboard /> },
  { path: "reports/daily-prod-dashboard", element: <DailyProductionDashboard /> },
  { path: "reports/weekly-prod-dashboard", element: <WeeklyProductionDashboard /> },
  { path: "reports/workable-dashboard", element: <NonWorkableDashboard /> },
  { path: "reports/user-wise-report", element: <UserWiseReport /> },

  { path: "pendingQueries/manage", element: <PendingQueries /> },
  { path: "inventory/manage", element: <Inventory /> },
  { path: "placements/manage", element: <Placements /> },
  { path: "taskEngine/manage", element: <RuleEngine /> },
  { path: "decision-tree-creator/:id", element: <CreateDecisionTree /> },
  { path: "decision-tree-creator/manage", element: <DecisionTreeManage /> },

  { path: "master-catalogue", element: <MasterCatalogue /> },


  { path: "system-settings", element: <SystemSettings /> },
]

export default protectedRoutes;