import React from "react";
import Header from "../../../layouts/Header";

export default function UserWiseReport() {

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item">Home</li>
                            <li className="breadcrumb-item active" aria-current="page">Manage</li>
                        </ol>
                        <h4 className="main-title mb-0">User-Wise Production Report</h4>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}