import React, { useEffect, useRef, useState } from "react";
import "../MyTask/Details/details.scss";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Button, Card, Col, Offcanvas, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import PriorAuthorization from "../MyTask/Details/prior-authorization";
import InsuranceInformation from "../MyTask/Details/insurance-information";
import LabReferringProviderInfo from "../MyTask/Details/lab-referring-pro-info";
import DebialCorerespondence from "../MyTask/Details/debial-corerespondence";
import ClaimInformation from "../MyTask/Details/claim-information";
import DocumentUpload from "../MyTask/Details/document-upload";

export default function TasksDetails(props) {
  const axiosPrivate = useAxiosPrivate();
  const taskId = props?.taskId;
  const [taskDetails, setTaskDetails] = useState(null);
  useEffect(() => {
    if (taskId) {
      getTaskDetails(taskId);
    }
  }, [taskId]);
  const getTaskDetails = async () => {
    try {
      const taskDetail = await axiosPrivate.get(`Task/case-details/${taskId}`);
      const lowercaseTaskDetails = (taskDetails) => {
        const lowered = {};
        Object.keys(taskDetails).forEach((key) => {
          lowered[key.toLowerCase()] = taskDetails[key];
        });
        return lowered;
      };
      const updatedTaskDetails = lowercaseTaskDetails(taskDetail?.data?.result);
      setTaskDetails(updatedTaskDetails);
    } catch (err) {
      console.error(err);
    }
  };
  const resetForm = () => {
    props?.setShowTasksDetails({ showTaskDetails: false, taskId: "" });
    setTaskDetails(null);
  };
  return (
    <React.Fragment>
      <Offcanvas show={props.show} placement="end" className="w-80">
        <Offcanvas.Header>
          <Offcanvas.Title className="fs-16 text-dark">
            <span className="align-middle">Case Details</span>
          </Offcanvas.Title>

          <div className="d-flex justify-content-end">
            <Button variant="default" onClick={() => resetForm()}>
              <i className="ri-close-line fs-22"></i>
            </Button>
          </div>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Card className="card-one case-details-card">
            <div className="mb-4 position-sticky bg-white top-0 z-index4 p-2 pb-3 overflow-y">
              <h2 className="fs-18 fw-semibold text-dark">
                {taskDetails?.patient_first_name}{" "}
                {taskDetails?.patient_last_name}
              </h2>
              <div className="d-flex align-items-center gap-2 mb-3">
                <span className="fs-12 text-dark">
                  {taskDetails?.gender === null || taskDetails?.gender === ""
                    ? "-"
                    : taskDetails?.gender}
                </span>
                <span className="text-black-50">|</span>
                <span className="fs-12 text-dark">
                  <b>Age:</b>{" "}
                  {taskDetails?.age === null || taskDetails?.age === ""
                    ? "-"
                    : taskDetails?.age}
                </span>
              </div>
              <Card className="mb-3 card-one h-auto">
                <Card.Body>
                  <Row className="flex-wrap g-3">
                    <Col xl={2}>
                      <h5 className="fs-14 mb-1">Visit ID</h5>
                      <h4 className="fw-bold text-dark fs-14">
                        {taskDetails?.visitfid === null ||
                        taskDetails?.visitfid === ""
                          ? "-"
                          : taskDetails?.visitfid}
                      </h4>
                    </Col>
                    <Col xl={2}>
                      <h5 className="fs-14 mb-1">LIMS Casefile ID</h5>
                      <h4 className="fw-bold text-dark fs-14">
                        {taskDetails?.lims_casefile_id === null ||
                        taskDetails?.lims_casefile_id === ""
                          ? "-"
                          : taskDetails?.lims_casefile_id}
                      </h4>
                    </Col>
                    <Col xl={2}>
                      <h5 className="fs-14 mb-1">LIMS DOS Formal</h5>
                      <h4 className="fw-bold text-dark fs-14">
                        {taskDetails?.lims_date_of_service ===
                        "0001-01-01T00:00:00"
                          ? "-"
                          : new Date(
                              taskDetails?.lims_date_of_service
                            ).toLocaleString()}
                      </h4>
                    </Col>
                    <Col xl={2}>
                      <h5 className="fs-14 mb-1">Test Name</h5>
                      <h4 className="fw-bold text-dark fs-14">
                        {taskDetails?.test_name === null ||
                        taskDetails?.test_name === ""
                          ? "-"
                          : taskDetails?.test_name}
                      </h4>
                    </Col>
                    <Col xl={2}>
                      <h5 className="fs-14 mb-1">Panel</h5>
                      <h4 className="fw-bold text-dark fs-14">
                        {taskDetails?.panels === null ||
                        taskDetails?.panels === ""
                          ? "-"
                          : taskDetails?.panels}
                      </h4>
                    </Col>
                    <Col xl={2}>
                      <h5 className="fs-14 mb-1">Panel Type</h5>
                      <h4 className="fw-bold text-dark fs-14">
                        {taskDetails?.panel_type === null ||
                        taskDetails?.panel_type === ""
                          ? "-"
                          : `$ ${taskDetails?.panel_type}`}
                      </h4>
                    </Col>

                    <Col xl={2}>
                      <h5 className="fs-14 mb-1">All Procedure Codes</h5>
                      <h4 className="fw-bold text-dark fs-14">
                        {taskDetails?.allproccodes === null ||
                        taskDetails?.allproccodes === ""
                          ? "-"
                          : taskDetails?.allproccodes}{" "}
                      </h4>
                    </Col>

                    <Col xl={2}>
                      <h5 className="fs-14 mb-1">Modifier</h5>
                      <h4 className="fw-bold text-dark fs-14">
                        {taskDetails?.modifier === null ||
                        taskDetails?.modifier === ""
                          ? "-"
                          : taskDetails?.modifier}{" "}
                      </h4>
                    </Col>

                    <Col xl={2}>
                      <h5 className="fs-14 mb-1">BDE Risk Category</h5>
                      <h4 className="fw-bold text-dark fs-14">
                        {taskDetails?.bde_risk_category === null ||
                        taskDetails?.bde_risk_category === ""
                          ? "-"
                          : taskDetails?.bde_risk_category}{" "}
                      </h4>
                    </Col>

                    <Col xl={2}>
                      <h5 className="fs-14 mb-1">Empower Risk Combined</h5>
                      <h4 className="fw-bold text-dark fs-14">
                        {taskDetails?.empower_risk === null ||
                        taskDetails?.empower_risk === ""
                          ? "-"
                          : taskDetails?.empower_risk}{" "}
                      </h4>
                    </Col>

                    <Col xl={2}>
                      <h5 className="fs-14 mb-1">Cancer Type</h5>
                      <h4 className="fw-bold text-dark fs-14">
                        {taskDetails?.cancertype === null ||
                        taskDetails?.cancertype === ""
                          ? "-"
                          : taskDetails?.cancertype}{" "}
                      </h4>
                    </Col>

                    <Col xl={2}>
                      <h5 className="fs-14 mb-1">Cancer Stage</h5>
                      <h4 className="fw-bold text-dark fs-14">
                        {taskDetails?.stage_cancerstage === null ||
                        taskDetails?.stage_cancerstage === ""
                          ? "-"
                          : taskDetails?.stage_cancerstage}{" "}
                      </h4>
                    </Col>

                    <Col xl={2}>
                      <h5 className="fs-14 mb-1">Organt Type</h5>
                      <h4 className="fw-bold text-dark fs-14">
                        {taskDetails?.organ_type === null ||
                        taskDetails?.organ_type === ""
                          ? "-"
                          : taskDetails?.organ_type}{" "}
                      </h4>
                    </Col>
                    <Col xl={2}>
                      <h5 className="fs-14 mb-1">Positive Policy</h5>
                      <h4 className="fw-bold text-dark fs-14">
                        {taskDetails?.positive_policy === null ||
                        taskDetails?.positive_policy === ""
                          ? "-"
                          : taskDetails?.positive_policy}{" "}
                      </h4>
                    </Col>
                    <Col xl={2}>
                      <h5 className="fs-14 mb-1">AMD ICD Codes</h5>
                      <h4 className="fw-bold text-dark fs-14">
                        {taskDetails?.amd_icd_codes === null ||
                        taskDetails?.amd_icd_codes === ""
                          ? "-"
                          : taskDetails?.amd_icd_codes}{" "}
                      </h4>
                    </Col>
                    <Col xl={2}>
                      <h5 className="fs-14 mb-1">ZCode</h5>
                      <h4 className="fw-bold text-dark fs-14">
                        {taskDetails?.zcode === null ||
                        taskDetails?.zcode === ""
                          ? "-"
                          : taskDetails?.zcode}{" "}
                      </h4>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="card-one h-auto">
                <Card.Body>
                  {/* PRIOR AUTHORIZATION COMPONENT */}
                  <div>
                    <h3 className="fs-14 fw-semibold mb-4">
                      Prior Authorization
                    </h3>
                    <PriorAuthorization taskDetails={taskDetails} />
                  </div>

                  {/* INSURANCE INFORMATION COMPONENT */}
                  <div>
                    <h3 className="fs-14 fw-semibold mb-4">
                      Insurance Information
                    </h3>
                    <InsuranceInformation taskDetails={taskDetails} />
                  </div>

                  {/* LAB/REFERRING PROVIDER INFORMATION */}
                  <div>
                    <h3 className="fs-14 fw-semibold mb-4">
                      LAB/Referring Provider Information
                    </h3>
                    <LabReferringProviderInfo taskDetails={taskDetails} />
                  </div>

                  {/* DENIAL/CORERESPONDENCE */}
                  <div>
                    <h3 className="fs-14 fw-semibold mb-4">
                      Denial/Corerespondence
                    </h3>
                    <DebialCorerespondence taskDetails={taskDetails} />
                  </div>

                  {/* CLAIM INFORMATION COMPONENT */}
                  <div>
                    <h3 className="fs-14 fw-semibold mb-4">
                      Claim Information
                    </h3>
                    <ClaimInformation taskDetails={taskDetails} />
                  </div>

                  <hr />
                  <DocumentUpload taskDetails={taskDetails} />
                </Card.Body>
              </Card>
            </div>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}
