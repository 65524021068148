// import React from 'react';
// import './simmer.scss';

// const ShimmerLoader = (rows,columns) => {
//   return (
//     <div className="shimmer-loader">
//       {[...Array(rows)].map((_, rowIndex) => (
//         <div key={rowIndex} className="table-row-shimmer">
//           {[...Array(columns)].map((_, cellIndex) => (
//             <div key={cellIndex} className="table-cell-shimmer shimmer"></div>
//           ))}
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ShimmerLoader;


import React from "react";
import './simmer.scss'

const ShimmerLoader = ({ colsCount, colNames }) => {
    const renderTableHeader = () => {
        return colNames.map((name, index) => (
            <th
                key={index} scope="col" className="px-6 py-3 bg-gray-300 text-left text-xs font-medium text-gray-900 tracking-wider"
            >
                {name}
            </th>
        ));
    };

    const renderTableRows = () => {
        const rows = [];
        for (let i = 0; i < 5; i++) {
            rows.push(
                <tr key={i}>
                    <>
                        {colNames.map((_, index) => (
                            <td key={index} className="p-2">
                                <div className="shimmer p-3 w-100"></div>
                            </td>
                        ))}
                    </>
                </tr>
            );
        }
        return rows;
    };

    return (
        <table className="w-100">
            {/* <thead>
                <tr>{renderTableHeader()}</tr>
            </thead> */}
            <tbody>{renderTableRows()}</tbody>
        </table>
    );
};

export default ShimmerLoader;
