import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Offcanvas,
  Row,
} from "react-bootstrap";
import Select from "react-select";
import ToastifyService from "../../_common/ToastifyService";
import AMDStatusFormModal from "./AMDStatusFormModal";
import SOPFileViewModal from "./SOPFileViewModal";

const PreviewForm = forwardRef((props, ref) => {
  const [toggleStates, setToggleStates] = useState({});
  const [formData, setFormData] = useState({});
  const [dropdownSelections, setDropdownSelections] = useState({});
  const [checkboxes, setCheckboxes] = useState({});
  const [showAMDStatusFormModal, setShowAMDStatusFormModal] = useState(false);
  const [showSOPFileViewModal, setSOPFileViewModal] = useState(false);
  const [AMDFormData, setAMDFormData] = useState({});
  useEffect(() => {
    if (props.show) {
      const initialToggleStates = {};
      props?.nodes.forEach((node) => {
        initialToggleStates[node.id] = true;
      });
      setToggleStates(initialToggleStates);
    }
  }, [props?.nodes, props?.show]);
  const resetChildValues = (changedNodeId) => {
    const updatedFormData = { ...formData };
    const connections = props?.dynamicMapping[changedNodeId]?.connections || [];
    connections.forEach(({ target }) => {
      updatedFormData[target] = "";
      setToggleStates((prev) => ({
        ...prev,
        [target]: false,
      }));
      setDropdownSelections((prev) => ({
        ...prev,
        [target]: "",
      }));
      setCheckboxes((prev) => ({
        ...prev,
        [target]: "",
      }));
    });
    setFormData(updatedFormData);
  };
  const handleInputChange = (nodeId, value) => {
    const updatedFormData = { ...formData, [nodeId]: value };
    const childConnections = props?.dynamicMapping[nodeId]?.connections || [];
    childConnections.forEach(({ target }) => {
      updatedFormData[target] = "";
    });
    setFormData(updatedFormData);
  };
  const handleDropdownChange = (nodeId, value) => {
    setDropdownSelections((prev) => ({
      ...prev,
      [nodeId]: value,
    }));
    resetChildValues(nodeId);
  };
  const handleToggleChange = (nodeId, isChecked) => {
    setToggleStates((prev) => ({
      ...prev,
      [nodeId]: isChecked,
    }));
    resetChildValues(nodeId);
  };
  const handleCheckboxChange = (event, label, nodeId) => {
    const { checked } = event.target;
    setCheckboxes((prev) => ({
      ...prev,
      [nodeId]: {
        ...prev[nodeId],
        [label]: checked,
      },
    }));
  };
  const renderNode = (node) => {
    const isToggled = toggleStates[node.id] || false;
    const firstOption = node?.data?.componentValues[0];
    const nodeInData = props.nodeData.find((n) => n.nodeId === node.id);
    const showAddButton = nodeInData && nodeInData.addStatus;
    const addButton = (
      <Button
        variant="outline-primary"
        className="btn-sm"
        onClick={() => {
          setAMDFormData({ ...nodeInData, node });
          setShowAMDStatusFormModal(true);
        }}
      >
        Manage
      </Button>
    );
    const showInformation = nodeInData && nodeInData.isSOPFile;
    const infoIcon = (
      <Button
        variant="outline-primary"
        className="btn-sm mx-3"
        onClick={() => {
          setAMDFormData({ ...nodeInData, node });
          setSOPFileViewModal(true);
        }}
      >
        <i
          className="ri-information-line"
          style={{ cursor: "pointer", height: "70px" }}
        ></i>{" "}
        <span>Open SOP</span>
      </Button>
    );
    switch (node?.data?.componentTypeId) {
      case "4e0073a0-2338-4acf-bbd7-6b265bed7f05":
        return (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Row className="align-items-center mb-2">
                <Col md={10}>
                  <Form.Label className="mb-0">
                    {node?.data?.label}
                    {showInformation && infoIcon}
                  </Form.Label>
                </Col>
                <Col md={2}>{showAddButton && addButton}</Col>
              </Row>
            </Col>
            <Col>
              <ButtonGroup>
                {node?.data?.componentValues.map((option) => (
                  <Button
                    key={`${option.id}-${node.id}`}
                    variant={
                      isToggled && option.name === firstOption?.name
                        ? "primary"
                        : "outline-primary"
                    }
                    onClick={() => {
                      handleToggleChange(
                        node.id,
                        option.name === firstOption?.name
                      );
                    }}
                  >
                    <input
                      type="radio"
                      name={`toggle-${node.id}`}
                      className="btn-check"
                      autoComplete="off"
                      checked={option.name === firstOption?.name && isToggled}
                      readOnly
                    />
                    {option.name}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
          </Row>
        );
      case "4e0073a0-2338-4acf-bbd7-6b265bed7f02":
        return (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Row className="align-items-center mb-2">
                <Col md={10}>
                  <Form.Label className="mb-0">
                    {node?.data?.label} {showInformation && infoIcon}
                  </Form.Label>
                </Col>
                <Col md={2}>{showAddButton && addButton}</Col>
              </Row>
            </Col>
            <Col>
              <Select
                onChange={(selected) => handleDropdownChange(node.id, selected)}
                isSearchable={true}
                options={node?.data?.componentValues.map((option) => ({
                  value: option.id,
                  label: option.name,
                }))}
                name={`dropdown-${node.id}`}
                value={dropdownSelections[node.id] || null}
              />
            </Col>
          </Row>
        );
      case "00000000-0000-0000-0000-000000000001":
        return (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Row className="align-items-center mb-2">
                <Col md={10}>
                  <Form.Label className="mb-0">
                    Status {showInformation && infoIcon}
                  </Form.Label>
                </Col>
                <Col md={2}>{showAddButton && addButton}</Col>
              </Row>
            </Col>
            <Col>
              <Select
                isSearchable={true}
                options={node?.data?.componentValues.map((option) => ({
                  value: option.id,
                  label: option.name,
                }))}
                isDisabled={true}
                name={`dropdown-${node.id}`}
                value={node?.data?.componentValues
                  .map((option) => ({
                    value: option.id,
                    label: option.name,
                  }))
                  .find(
                    (selectedValue) => selectedValue.value === firstOption?.id
                  )}
              />
            </Col>
          </Row>
        );
      case "4e0073a0-2338-4acf-bbd7-6b265bed7f03":
        return (
          <Row className="mb-4 align-items-center">
            <Col md={12}>
              <Row className="align-items-center mb-2">
                <Col md={10}>
                  <Form.Label className="mb-0">
                    {node?.data?.label} {showInformation && infoIcon}
                  </Form.Label>
                </Col>
                <Col md={2}>{showAddButton && addButton}</Col>
              </Row>
            </Col>
            <Col>
              <Select
                options={node?.data?.componentValues.map((option) => ({
                  value: option.id,
                  label: option.name,
                }))}
                isSearchable={true}
                isMulti={true}
                name={`multiselect-dropdown-${node.id}`}
                value={dropdownSelections[node.id] || []}
                onChange={(selected) => handleDropdownChange(node.id, selected)}
              />
            </Col>
          </Row>
        );
      case "4e0073a0-2338-4acf-bbd7-6b265bed7f04":
        return (
          <Row>
            <Form>
              <Col md={12}>
                <Row className="align-items-center mb-2">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
              </Col>
              <Col>
                {node?.data?.componentValues?.map((type) => (
                  <div className="mb-" key={`${type.id}-${node.id}`}>
                    <Form.Check
                      name={`multiselect-checkbox-${node.id}`}
                      label={type.name}
                      checked={checkboxes[node.id]?.[type.name] || false}
                      onChange={(e) =>
                        handleCheckboxChange(e, type.name, node.id)
                      }
                      type="checkbox"
                    />
                  </div>
                ))}
              </Col>
            </Form>
          </Row>
        );
      case "4e0073a0-2338-4acf-bbd7-6b265bed7f01":
        switch (node?.data?.datatypeId) {
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f01":
            return (
              <Form.Group key={`${node?.data?.id}-${node.id}`} className="mb-3">
                <Row className="align-items-center mb-2">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="text"
                  value={formData[node.id] || ""}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                  placeholder={`Enter ${node?.data?.label}`}
                />
              </Form.Group>
            );
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f02":
            return (
              <Form.Group key={`${node?.data?.id}-${node.id}`} className="mb-3">
                <Row className="align-items-center mb-2">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="number"
                  value={formData[node.id] || 0}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                  placeholder={`Enter ${node?.data?.label}`}
                />
              </Form.Group>
            );
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f03":
            return (
              <Form.Group key={`${node?.data?.id}-${node.id}`} className="mb-3">
                <Row className="align-items-center mb-2">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="date"
                  value={formData[node.id] || ""}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                />
              </Form.Group>
            );
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f04":
            return (
              <Form.Group key={`${node?.data?.id}-${node.id}`} className="mb-3">
                <Row className="align-items-center mb-2">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="url"
                  value={formData[node.id] || ""}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                  placeholder={`Enter ${node?.data?.label}`}
                />
              </Form.Group>
            );
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f05":
            return (
              <Form.Group key={`${node?.data?.id}-${node.id}`} className="mb-3">
                <Row className="align-items-center mb-2">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="email"
                  value={formData[node.id] || ""}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                  placeholder={`Enter ${node?.data?.label}`}
                />
              </Form.Group>
            );
          case "4e0073a0-2339-4acf-bbd7-6b265bed7f06":
            return (
              <Form.Group key={`${node?.data?.id}-${node.id}`} className="mb-3">
                <Row className="align-items-center mb-2">
                  <Col md={10}>
                    <Form.Label className="mb-0">
                      {node?.data?.label} {showInformation && infoIcon}
                    </Form.Label>
                  </Col>
                  <Col md={2}>{showAddButton && addButton}</Col>
                </Row>
                <Form.Control
                  type="tel"
                  value={formData[node.id] || ""}
                  onChange={(e) => handleInputChange(node.id, e.target.value)}
                  placeholder={`Enter ${node?.data?.label}`}
                />
              </Form.Group>
            );
          default:
            return null;
        }
      default:
        return null;
    }
  };
  const generateForm = () => {
    const renderedFields = [];
    const renderNodeWithChildren = (node) => {
      renderedFields.push(renderNode(node));
      const connectedEdges = props?.edges?.filter(
        (edge) => edge.source === node.id
      );
      connectedEdges.forEach((edge) => {
        let shouldRenderChild = false;
        if (node.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f02") {
          if (dropdownSelections[node.id]?.label === edge.label) {
            shouldRenderChild = true;
          }
        } else if (node.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f05") {
          if (toggleStates[node.id]) {
            if (edge.sourceHandle === "checked") {
              shouldRenderChild = true;
            }
          } else {
            if (edge.sourceHandle === "unchecked") {
              shouldRenderChild = true;
            }
          }
        } else if (node.type === "4e0073a0-2338-4acf-bbd7-6b265bed7f04") {
          if (checkboxes[node.id]?.[edge.label]) {
            shouldRenderChild = true;
          }
        } else if (node.type === "00000000-0000-0000-0000-000000000001") {
          if (node.id === "node_0") {
            shouldRenderChild = true;
          }
        }
        if (shouldRenderChild) {
          const targetNode = props?.nodes.find((n) => n.id === edge.target);
          if (targetNode) {
            renderNodeWithChildren(targetNode);
          }
        }
      });
    };
    props?.nodes.forEach((node) => {
      const isOrphanedNode = props?.edges?.every(
        (edge) => edge.target !== node.id && edge.source !== node.id
      );
      const isParentNode = props?.edges?.every(
        (edge) => edge.target !== node.id
      );

      if (isParentNode || isOrphanedNode) {
        renderNodeWithChildren(node);
      }
    });
    return renderedFields;
  };
  useImperativeHandle(ref, () => ({
    generateForm,
  }));
  const handleSuccess = () => {
    ToastifyService.success("Saved!");
  };
  return (
    <React.Fragment>
      <AMDStatusFormModal
        show={showAMDStatusFormModal}
        closeFunction={setShowAMDStatusFormModal}
        AMDFormData={AMDFormData}
      />
      <SOPFileViewModal
        show={showSOPFileViewModal}
        closeFunction={setSOPFileViewModal}
        AMDFormData={AMDFormData}
      />
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-35"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Generated Form Preview
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>{generateForm()}</Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
          <Button
            variant="primary"
            type="submit"
            onClick={handleSuccess}
            // {handleFormSubmit}
          >
            Submit
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
});
export default PreviewForm;
