import React from "react";
import { Button, Card, Col, Form, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import bg1 from "../../assets/img/login/bg.jpg";
import logo from "../../assets/img/logo/main-logo.png";

export default function SignupComponent() {
    const handleSubmit = () => {
        window.location.href = "/account/login";
    }
  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Card.Title>Sign Up</Card.Title>
              <Card.Text>It's free to signup and only takes a minute.</Card.Text>
            </Card.Header>
            <Card.Body>
              <div className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="text" placeholder="Enter your email address" />
              </div>
              <div className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Enter your password" />
              </div>
              <div className="mb-3">
                <Form.Label>Full name</Form.Label>
                <Form.Control type="text" placeholder="Enter your full name" />
              </div>
              <div className="mb-4">
                <small>By clicking <strong>Create Account</strong> below, you agree to our terms of service and privacy statement.</small>
              </div>
              <Button type="button" variant="primary" className="btn-sign" onClick={handleSubmit}>Create Account</Button>
            </Card.Body>
            <Card.Footer>
              Already have an account? <Link to="/account/login">Sign In</Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col className="d-none d-lg-block position-relative">
        {/* <div className="login-msg">
            <div className="header-logo mb-3">
              <Image className="wt-200" src={whiteLogo} alt="logo" />
            </div>
          </div> */}
          <Image src={bg1} className="h-100 w-100 opacity-50" alt="bg" />
          <div className="login-content">
              <div className="login-content-logo">
                <Image className="wt-200 mb-3" src={logo} />
              </div>
              <h2 className="text-white fs-24 mb-2">Welcome to AR Claims Engine</h2>
              <h1 className="text-white fs-55">Streamline claims, accelerate payments, and maximize revenue effortlessly.</h1>
            </div>
        </Col>
      </Row>
    </div>
  );
}