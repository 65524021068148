import React, { useEffect, useState } from "react";
import "../Settings/system-setting.scss";
import Header from "../../layouts/Header";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Nav, Tab, Card, Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import UploadLog from "./UploadLog/upload-log";
import PendingAssignment from "./PendingAssignment/pending-assignment";
import ToastifyService from "../_common/ToastifyService";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import LoadingSpinner from "../_common/LoadingSpinner";
import AssignedAssignment from "./PendingAssignment/assigned-cases";
import InventoryTemplate from "../../assets/excel/InventoryTemplate.xlsx";
import axios from "axios";
import { COMMON_SERVICE } from "../../api/axios";
export default function Inventory() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });
  const [UploadeLogList, setUploadeLogList] = useState([]);
  const [PendingInventoryList, setPendingInventory] = useState([]);
  const [assignedInventory, setAssignedInventory] = useState([]);
  const [loading1, setLoading1] = useState({
    isLoading: false,
    value: "",
  });
  const [loading2, setLoading2] = useState({
    isLoading: false,
    value: "",
  });
  const [loading3, setLoading3] = useState({
    isLoading: false,
    value: "",
  });

  const [isSidebarShow, setSidebarShow] = useState(false);
  useEffect(() => {
    getUploadedLog();
    getPendingInventory();
    getAssignedInventory();
  }, []);

  const getUploadedLog = async () => {
    try {
      const getUploadedLogyData = await axiosPrivate.get(
        `Inventory/get-inventory-logs`
      );
      setLoading1({ isLoading: true, value: "getUploadedLog" });
      setUploadeLogList(getUploadedLogyData?.data?.result);
    } catch (err) {
      console.error(err);
      setLoading1({ isLoading: false, value: "getUploadedLog" });
    } finally {
      setLoading1({ isLoading: false, value: "getUploadedLog" });
    }
  };
  const getPendingInventory = async () => {
    try {
      setLoading2({ isLoading: true, value: "getPendingInventory" });
      const getPendingInventoryData = await axiosPrivate.get(
        `Inventory/get-pending-inventories`
      );
      setPendingInventory(getPendingInventoryData?.data?.result);
    } catch (err) {
      console.error(err);
      setLoading2({ isLoading: false, value: "getPendingInventory" });
    } finally {
      setLoading2({ isLoading: false, value: "getPendingInventory" });
    }
  };
  const getAssignedInventory = async () => {
    try {
      setLoading3({ isLoading: true, value: "getAssignedInventory" });
      const getAssignedInventoryData = await axiosPrivate.get(
        `Inventory/get-assigned-inventories`
      );
      setAssignedInventory(getAssignedInventoryData?.data?.result);
    } catch (err) {
      console.error(err);
      setLoading3({ isLoading: false, value: "getAssignedInventory" });
    } finally {
      setLoading3({ isLoading: false, value: "getAssignedInventory" });
    }
  };

  const handleFileUpload = async (event) => {
    setLoading({
      isLoading: true,
      value: "uploadInventory",
    });
    setLoading2({ isLoading: true, value: "getPendingInventory" });
    setLoading2({ isLoading: true, value: "getUploadedLog" });
    setLoading2({ isLoading: true, value: "getAssignedInventory" });
    const file = event.target.files;
    // const fileNamesArray = Array.from(file).map((file) => file.name);

    // const formData = new FormData();
    // formData.append("Location", "Inventories");
    // // Append files to FormData
    // Array.from(file).forEach((file, index) => {
    //   formData.append("files", file);
    //   formData.append("fileNames[]", fileNamesArray[index]); // Ensure fileNames are appended correctly
    // });
    // for (let pair of formData.entries()) {
    //   console.log(`${pair[0]}: ${pair[1]}`);
    // }
    // try {
    //   await axios.post(`${COMMON_SERVICE}File/Upload/ARWorkflow`, formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   });
    if (
      file[0] &&
      (file[0].type === "text/csv" ||
        file[0].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file[0].type === "application/vnd.ms-excel")
    ) {
      const formData = new FormData();
      formData.append("file", file[0]);
      formData.append("CreatedBy", auth?.id);
      try {
        const uploadInventoryRes = await axiosPrivate.post(
          `Inventory/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setPendingInventory(uploadInventoryRes?.data?.pendingInventory);
        setUploadeLogList(
          uploadInventoryRes?.data?.inventoryUploadLogViewModel
        );
        setAssignedInventory(uploadInventoryRes?.data?.assignedInventory);
        ToastifyService.success(
          `Inventory uploaded successfully (${uploadInventoryRes?.data?.response}), categories created, and cases assigned to user successfully.`
        );
      } catch (error) {
        // console.error(error);
        ToastifyService.error("Oops! Something Went Wrong");
        setLoading2({ isLoading: false, value: "getPendingInventory" });
        setLoading2({ isLoading: false, value: "getUploadedLog" });
        setLoading2({ isLoading: false, value: "getAssignedInventory" });
        setLoading({
          isLoading: false,
          value: "uploadInventory",
        });
      } finally {
        setLoading2({ isLoading: false, value: "getPendingInventory" });
        setLoading2({ isLoading: false, value: "getUploadedLog" });
        setLoading2({ isLoading: false, value: "getAssignedInventory" });
        setLoading({
          isLoading: false,
          value: "uploadInventory",
        });
      }
      // } catch (error) {
      //   ToastifyService.error("Oops! Something Went Wrong");
      //   setLoading2({ isLoading: false, value: "getPendingInventory" });
      //   setLoading2({ isLoading: false, value: "getUploadedLog" });
      //   setLoading2({ isLoading: false, value: "getAssignedInventory" });
      //   setLoading({
      //     isLoading: false,
      //     value: "uploadInventory",
      //   });
      // } finally {
      //   setLoading2({ isLoading: false, value: "getPendingInventory" });
      //   setLoading2({ isLoading: false, value: "getUploadedLog" });
      //   setLoading2({ isLoading: false, value: "getAssignedInventory" });
      //   setLoading({
      //     isLoading: false,
      //     value: "uploadInventory",
      //   });
    }
  };

  return (
    <React.Fragment>
      <Header />

      <Tab.Container defaultActiveKey="upload_log_tab">
        <div
          className={"main main-file-manager" + (isSidebarShow ? " show" : "")}
        >
          <PerfectScrollbar className="file-sidebar page-in-tabs p-0">
            <Card className="card-one">
              <Card.Body>
                <div className="mb-3">
                  <a
                    href={InventoryTemplate}
                    download="Template_WorkQueue.xlsx"
                  >
                    <Button variant="outline-primary" className="w-100">
                      {" "}
                      <i className="ri-download-line fs-16 lh-1 align-middle"></i>{" "}
                      <span className="align-middle">Download Template</span>
                    </Button>
                  </a>
                </div>
                <div className="dropdown d-grid mb-3">
                  <label
                    htmlFor="upload-input"
                    className="btn btn-primary d-flex align-items-center gap-1 justify-content-center"
                    disabled={
                      loading.isLoading && loading?.value === "uploadInventory"
                    }
                  >
                    {loading.isLoading &&
                    loading?.value === "uploadInventory" ? (
                      <LoadingSpinner
                        color="#ffffff"
                        size={30}
                        type={"TailSpin"}
                      />
                    ) : (
                      <>
                        <i className="ri-file-add-line align-middle fs-16"></i>
                        <span className="align-middle ms-2">
                          Upload Inventory
                        </span>
                        <input
                          id="upload-input"
                          type="file"
                          accept=".xlsx, .xls, .csv"
                          style={{ display: "none" }}
                          onChange={(e) => handleFileUpload(e)}
                        />
                      </>
                    )}
                  </label>
                </div>

                <Nav variant="pills">
                  {/* <Nav.Item>
                    <Nav.Link eventKey="dashboard_tab">
                      <i className="ri-pie-chart-2-line"></i> Dashboard
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="upload_log_tab">
                      <i className="ri-chat-upload-line"></i> Upload Log
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="position-relative"
                      eventKey="pending_assignment_tab"
                    >
                      <i className="ri-timer-line"></i> Unassigned Cases{" "}
                      <Badge
                        bg="primary"
                        className="badge-pill position-absolute right-10"
                      >
                        {PendingInventoryList?.length}
                      </Badge>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="position-relative"
                      eventKey="assigned_cases_tab"
                    >
                      <i className="ri-user-follow-line"></i> Assigned Cases{" "}
                      <Badge
                        bg="primary"
                        className="badge-pill position-absolute right-10"
                      >
                        {assignedInventory?.length}
                      </Badge>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Body>
            </Card>
          </PerfectScrollbar>

          <PerfectScrollbar className="file-content p-3 p-lg-4">
            <Link
              href=""
              className="menu-file-manager"
              onClick={() => setSidebarShow(!isSidebarShow)}
            >
              <i className="ri-arrow-left-line"></i>
            </Link>
            <Tab.Content>
              {/* INVENTORY DASHBOARD TAB */}
              {/* <Tab.Pane eventKey="dashboard_tab">
                <InventoryDashboard />
              </Tab.Pane> */}
              {/* UPLOAD LOG TAB */}
              <Tab.Pane eventKey="upload_log_tab">
                <UploadLog UploadeLogList={UploadeLogList} loading={loading1} />
              </Tab.Pane>
              {/* PENDING ASSIGNMENT TAB */}
              <Tab.Pane eventKey="pending_assignment_tab">
                <PendingAssignment
                  PendingInventoryList={PendingInventoryList}
                  loading={loading2}
                  setAssignedInventory={setAssignedInventory}
                  setPendingInventory={setPendingInventory}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="assigned_cases_tab">
                <AssignedAssignment
                  PendingInventoryList={assignedInventory}
                  loading={loading3}
                  setAssignedInventory={setAssignedInventory}
                  setPendingInventory={setPendingInventory}
                />
              </Tab.Pane>
            </Tab.Content>
          </PerfectScrollbar>
        </div>
      </Tab.Container>
    </React.Fragment>
  );
}
